@import '../../../../config';

.signup {
    display: flex;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    min-width: 100vw;
    justify-content: center;

    @include mobileView {
        flex-direction: column;
    }

    &__wrap-left {
        flex: 1;
        background-color: #f5f1ee;
        display: flex;
        // justify-content: center;
        align-items: center;
    }

    &__logo {
        position: absolute;
        left: 0 !important;
        top: 15px;
            margin-left: 30px;

        @include mobileView {
            position: relative;
            right: 0 !important;
            top: 4px;
            margin-left: 18px;

            img {
                height: 50px;
                width: 100px !important;
            }
        }
    }

    &__wrapper {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
        border-radius: 10px;
        display: flex;
        align-self: center;
        height: 80%;
        width: 70vw;
    }

    &__wraps {
        // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
        height: 90%;
        width: 35vw;
        padding: 30px 60px;
        overflow: auto;

        @include mobileView {
            width: 90vw;
            padding: 30px 30px;
        }

        &__br-left {
            border-radius: 10px 0px 0px 10px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28),
                -20px 0px 20px 0px #f5f1ee inset;
            background-image: url('../../../assets/new/patientApp/signup-bg.svg');
            background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

            h1 {
                font-family: Victor Serif Trial;
                font-size: 42px;
                font-weight: 800;
                // line-height: 60px;
            }
        }

        &__br-right {
            border-radius: 0px 10px 10px 0px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28),
                -20px 0px 20px 0px white inset;
        }
    }

    &__left {
        flex: 1;
        background-color: #f5f1ee;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0px;
        flex-direction: column;

        @include mobileView {
            display: none;
        }


    }

    &__right {
        flex: 1;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;

        @include mobileView {
            width: 100%;
            padding: 20px;
        }

        h2 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            color: #888;
            margin-bottom: 15px;
            }
                                                                }
        &__form {
            display: flex;
            flex-direction: column;

        &__group {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;

            label {
                    font-size: 14px;
                    margin-bottom: 5px;
                }

                input {
                    padding: 10px;
                    font-size: 14px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }

                small {
                    font-size: 12px;
                    color: #888;
                    margin-top: 5px;
                
                
        &__submit {
            background-color: #4d4d4d;
            color: white;
            padding: 12px;
            border-radius: 5px;
            font-weight: bold;
            cursor: pointer;
            margin-bottom: 20px;
            border: none;
            text-align: center;
            &:hover {
                    background-color: #7d7c7c;
        }

        &__login {
            margin-top: 20px;
            font-size: 14px;

            a {
                color: #007bff;
                text-decoration: none;
                font-size: 16px;
                
                }
                
                &__google {
                    display: flex;
                    align-items: center;
                    justify-content: center;

    &__button {
            margin-top: 15px;
            margin-bottom: 10px;
        width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

        p {
                text-align: center;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
}
                                }
                                }
}
}
}