.add-reps-t {
    &__text-area {
        height: 42px;
        overflow: hidden;
        resize: none;
        cursor: pointer;

        &:hover {
            border: 1px solid rgba(80, 118, 255, 0.396);
        }

        &--selected {
            height: 120px;
            border: solid 1px rgb(80, 118, 255) !important;
            cursor: initial;
            overflow: auto;
        }
    }

    &__no-results {
        border: solid 1px rgb(221, 221, 221);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 100%;
    }
} 