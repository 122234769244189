.lr-dn {
    border: solid 1px #EFEFEF;
    border-radius: 10px;
    margin-top: 35px;
    width: 100%;
    padding: 15px 13px;

    &__ai {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 768px) {
            // justify-content: end;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 0px;
        }
        
        @media only screen and (min-width: 1025px) and (max-width: 1500px) {
            justify-content: end;
            width: 100%;
        }

        &__button {
            height: 36px !important;
            min-height: 36px !important;
            max-height: 36px !important;
            width: 36px !important;
            max-width: 36px !important;
            min-width: 36px !important;
            padding-right: 2px;
            padding-top: 3px;
            
            &--loading {
                border-radius: 0 10px 10px 0 !important;
            }
        }

        &__check {
            min-width: 25px;
        }

        &__check-wrapper {
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            border: solid 1px #979797;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding: 5px;
            padding-bottom: 7px;
            margin-left: 10px;
            cursor: pointer;
        }

        &__text {
            padding-top: 2px;
            margin-left: 6px;
        }
    }

    &__header {
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        justify-content: space-between;
        
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: start;
        }
        
        @media only screen and (min-width: 1025px) and (max-width: 1500px) {
            flex-direction: column-reverse;
            align-items: start;
        }

        &__text {
            display: flex;
            align-items: center;
        }

        &__loading-text {
            border: solid 1px rgba(16, 16, 16, 0.4);
            margin-right: -5px;
            z-index: 1;
            height: 36px;
            max-height: 36px;
            min-height: 36px;
            border-radius: 10px 0 0 10px;
            padding: 10px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #979797;
            height: 28px;
            background: white;
            border-right: none;
            font-size: 12px;
            line-height: 12px;
        }

        &__unsaved {
            border: solid 1px #ff9e43;
            border-radius: 10px;
            padding: 2px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ff9e43;
            margin-left: 15px;

            &--placeholder {
                color: transparent;
                border: transparent;
            }
        }
    }

    &__icon {
        height: 18px;
        width: 22px;
        margin-right: 5px;
    }

    &__buttons {
        display: flex;
        margin-top: 20px;
        margin-bottom: 13px;

        button {
            height: 38px !important;
            max-height: 38px !important;
            min-height: 38px !important;
            font-size: 17px !important;
        }
    }

    &__input {
        width: 100% !important;
        height: 100px !important;
    }
}

.rich-text .DraftEditor-editorContainer {
    font-size: 14px !important;
}

.public-DraftEditor-content .public-DraftStyleDefault-block {
    margin: 2px;
}

.rich-text--viewer .DraftEditor-editorContainer {
    height: 100% !important;
}