.biomarker-overview__summary {
    margin-bottom: 20px;
}

.biomarker-overview__summary h3 {
    margin-bottom: 10px;
    color: #333;
}

.summary-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-grow: 1;
}

.summary-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #f6f7f8;
    cursor: pointer;
    transition: all 0.3s ease;
}

.summary-item:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.summary-item.active {
    outline: 2px solid #007bff;
}

.summary-flag {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 5px;
    font-weight: bold;
}

.summary-count {
    font-weight: bold;
    margin-right: 5px;
}

.summary-meaning {
    font-size: 0.9em;
    color: #666;
    flex: 1;
}

.flag-meaning {
    font-size: 0.8em;
    color: #666;
    margin-top: 4px;
}

/* Add hover effect and transition */
.fixed-table tr {
    transition: background-color 0.3s ease;
}

.fixed-table tr:hover td:not(.category-cell) {
    filter: brightness(95%);
}

/* Add a legend for flag colors */
.flag-legend {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e1e2;
    border-radius: 4px;
}

.flag-legend-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 5px;
}

.flag-color-box {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border: 1px solid #ccc;
}

.flag-description {
    font-size: 0.9em;
}

.fixed-table .category-cell {
    background-color: #f6f7f8 !important;
}

.category-row {
    background-color: #f9f9f9;
    padding: 15px 10px !important;
}

.category-row h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
    color: #333;
    text-transform: uppercase;
}

.category-header {
    display: flex;
    align-items: center;
    gap: 15px;
}

.category-header h3 {
    margin: 0;
    white-space: nowrap;
}

.category-summary {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.category-summary .summary-item {
    font-size: 0.9em;
    padding: 2px 5px;
    margin: 0;
}

.category-summary .summary-flag {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 0.8em;
}

.category-summary .summary-count {
    font-size: 0.9em;
}

.category-summary .summary-meaning {
    display: none;
}

/* Add some space before each category (except the first one) */
.category-row:not(:first-of-type) {
    margin-top: 20px;
}

.summary-total {
    background-color: white;
    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-left: 15px;
    text-align: center;
}

.summary-total-count {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
}

.summary-total-label {
    font-size: 0.9em;
    color: #666;
}

.summary-item:last-child {
    border: 1px solid #ddd;
    font-weight: bold;
}

/* Add these new styles at the end of your existing CSS file */

.fixed-table {
    table-layout: fixed;
    width: 100%;
}

.fixed-table th,
.fixed-table td {
    overflow: hidden;
    text-overflow: ellipsis;
}

.col-test-code { width: 10%; }
.col-test { width: 25%; }
.col-result { width: 15%; }
.col-units { width: 10%; }
.col-reference { width: 25%; }
.col-flag { width: 15%; }

/* For the category row that spans all columns */
.category-row td {
    white-space: normal;
}

/* Ensure the table container allows horizontal scrolling if needed */
.table-container {
    overflow-x: auto;
}

