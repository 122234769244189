@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.tw-pdt {
  display: flex;
  flex-direction: column;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  font-size: 18px;
  line-height: 30px;
  z-index: 0;
  width: 21cm;
  height: 29.7cm;
  box-shadow: 0px 0px 2px 0px #9e9e9e;
  border-radius: 10px !important;
  background: white;
  font-family: 'Open Sans', sans-serif;
  padding: 8mm 15mm;
  font-size: 14px;

  &__ch {
    font-family: 'Noto Sans SC', sans-serif !important;
    font-family: 'Noto Sans TC', sans-serif !important;
  }
  &__ch-s {
    font-family: 'Noto Sans SC', sans-serif !important;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__data {
    flex: 1;
    width: 50%;
    max-width: 50%;
    min-width: 50%;
    position: relative;
    display: flex;
    flex-flow: row wrap;

    &--full {
      width: 100%;
      max-width: initial;
      flex: initial;
    }

    &__label {
      font-size: 14px;
    }

    &__data {
      padding-top: 2px;
      margin-left: 6px;
      font-size: 14px;
      color: #3672cb;
    }
  }

  &__data-box {
    flex: 1;
    width: 50%;
    max-width: 50%;
    min-width: 50%;
    position: relative;
    display: flex;
    flex-flow: column wrap;

    &__label {
      font-size: 14px;
      height: 24px;
    }

    &__data {
      font-size: 14px;
      color: #3672cb;
    }
  }

  &__header {
    margin-bottom: 11px;
    margin-top: 30px;
    font-size: 21px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
  }

  &__header-bottom {
    margin-top: 0px;
    margin-bottom: 17px;
    line-height: 18px;
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  &__info-text {
    font-size: 14px;
    line-height: 23px;
  }

  &__test-info {
    border: solid 2px black;
    width: 100%;
    margin-top: 5px;

    &__highlight {
      background: #e7e6e6;
      width: 100%;
      border-bottom: solid 2px black;
      border-top: solid 2px black;
      text-align: center;
      font-size: 15px;
      padding: 2px 0;
    }

    &__result {
      text-align: center;
      padding: 12px 0;
      font-size: 22px;
      font-weight: bold;

      &--red {
        color: red;
      }

      &--green {
        color: #3672cb;
        font-weight: 400;
      }
    }

    &__legend {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  &__stamp {
    width: 170px;
    height: 85px;
    border-radius: 5px;
    margin-left: 15px;
    object-fit: contain;
  }

  &__logo-wrapper {
    display: block;
    display: flex;
    justify-content: center;

    &__logo {
      max-height: 85px;
      border-radius: 5px;
      height: auto;
      object-fit: contain;
    }
  }

  &__signature-wrapper {
    margin-top: 30px;
    border-bottom: solid 1px black;
    width: 260px;

    &__sig {
      object-fit: contain;
      object-position: right;
      height: 65px;
      width: 200px;
      display: block;
      margin-bottom: 10px;
    }
  }

  &__watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__qr-code {
    text-align: center;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 10px;
    right: 10px;

    &__img {
      width: 120px;
      height: 120px;
    }
  }

  &__footer-line {
    line-height: 23px;
    margin-bottom: 7px;
  }
}
