@import '../../../config';

.header-bar {
  border-bottom: solid 1px rgb(238, 238, 238) !important;
  box-shadow: 80px 2px 5px 0px rgba(201, 201, 201, 0.2);
  background: #fefefe;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 767px) {
    .header-bar .QdaBA{
      margin-left: 20px;
      margin-top: 27px;
      display: block;
     }
  }

  &__welcome {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  @include mobileView {
    margin-top: 8px;
    background: none;
    box-shadow: none;
    border-bottom: none !important;
  }

  &__icon {
    height: 55px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 19px;

    @include mobileView {
      height: 37px;
      width: 37px;
      margin-left: 15px;
      // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);  
      // background: #FFFFFF;
    }

    &__burger {
      height: 24px;
      width: 24px;
      object-fit: contain;
    }

    .icon {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .ui.dropdown .menu {
    border-radius: 5px !important;
    padding: 6px !important;
  }

  .search {
    height: 43px !important;
    min-height: 43px !important;
  }

  &__notif,
  .ui.floating.dropdown > .menu {
    border-radius: 5px !important;

    &__item {
      width: 250px !important;
      white-space: normal !important;
      border-radius: 5px !important;
    }
  }

  .ui.search.dropdown.active>input.search, .ui.search.dropdown.visible>input.search {
    height: 43px !important;
    min-height: 43px !important;
  }

  &__select.select-dropdown.ui.selection.dropdown {
      height: 43px !important;
      min-height: 43px !important;
      border-radius: 10px !important;
      border-color: #5076ff42 !important;
      padding-top: 1px !important;

      .icon.dropdown {
        padding-top: 20px;
        padding: 0;
        top: 12px !important;
        right: 15px !important;
      }
  }

  &__notif-circle {
    background: #ff9b71;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 22px;
    top: 0px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    transform: rotate(-127deg);

    &__text {
      transform: rotate(127deg);
      padding-top: 2px;
    }
  }

  .icon {
    color: #1b1b1b !important;
  }
}

.profile-dropdown {
  margin-right: 20px;
  flex-direction: column;
  display: flex;
  z-index: 3;
  overflow: hidden;
  box-shadow: none;
  position: initial;
  background: initial;
  animation: fadein 0.2s;
  border-radius: 40px;
  border: 1px solid rgb(221, 221, 221);
  background: rgba(221, 221, 221, 0.1);

  @include mobileView {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  }

  &__abrv {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 !important;
    padding-right: 10px;
    padding-left: 4px;
    background: #FFFFFF !important;
    height: 40px !important;

    &--no-bg {
      margin: 10px !important;
      background: none;
      box-shadow: none;
    }
  }

  &__abrv-letter {
    width: 32px;
    height: 32px;
    border: solid 1px grey;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__profile-photo {
    height: 32px;
    width: 32px;

    img {
      height: 32px;
      width: 32px;
      border-radius: 32px;
      object-fit: cover;
    }
  }

  &--toggled {
    box-shadow: 0px 0px 4px 0px #8e8d8d8a;
    position: absolute;
    background: white;
    animation: fadein 0.2s;
    border: solid 1px #5076ff !important;
    border-radius: 10px !important;
    right: -5px;
    top: 3px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (orientation:'landscape'){
  #username{
    display: block;
  }
  .header-bar img{
    display: block;
   }
  
  .header-bar .bars{
    display: none !important;
   }
}

@media only screen and (max-width: 767px) {
   .bars :hover{
      cursor: pointer;
   }
  #username{
    display: none !important;
   }
   #name-avatar{
  }

  .header-bar__select{
    width: 190px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
  .header-bar img{
    display: none !important;
   }
}
