.ui.modal > .header {
  text-align: center;
  border-bottom: none !important;
}

.ui.fullscreen.modal.modal-full { 
  width: 95% !important;
  position: relative !important;
}

.modals.dimmer .ui.scrolling.modal, .ui.modal>.content, .ui.modal>.content, .ui.modal {
  border-radius: 10px !important;
}

.ui.modal>:last-child {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ui.modal>.icon:first-child+*, .ui.modal>:first-child:not(.icon) {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
