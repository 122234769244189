.dose-section {
  margin-top: 20px;
  border-radius: 10px;
  border: solid 1px #cdcdcd;

  &__header {
    position: relative;
    width: calc(100% + 2px);
    background-color: #f5f5f5;
    border: solid 1px #cdcdcd;
    border-radius: 10px;
    font-size: 21px;
    line-height: 21px;
    padding: 15px;
    margin-top: -1px;
    margin-left: -1px;
    display: flex;
    justify-content: space-between;

    &__selector {
      margin-left: 10px;
      border: solid 1px grey !important;
      background-color: white;
      border-radius: 5px;
      padding: 5px;
      position: absolute !important;
      top: 9px;
      left: 63px;
    }

    &__button {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  &__content {
    padding: 10px 15px;

    &__first-dose {
      padding: 15px;
      background-color: #fbfbfb;
      border: solid 1px #5076ff;
      border-radius: 10px;
    }
  }

  &__add-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}
