.dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 5px;
  border: 1px solid hsl(0, 0%, 67%);
  border-radius: 15px;

  &:hover {
    cursor: pointer;
  }
}

.category-tag {
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 3px;
    line-height: 11px;
    align-items: center;
  }
  &__icon {
    padding-top: 2px;
  }
  &__text {
    display: flex;
  }
}
