.patients {
    display: flex;
    flex-direction: column;

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__actions {
            display: flex;
        }

        &__test-order {
            border: solid 1px #5076ff;
            background: #5076ff37;
            border-radius: 10px;
            padding: 20px 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 600px) {
            margin-bottom: 10px;
        }
    }
    
    &__table-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;

        @media only screen and (max-width: 600px) {
            width: 100%;
            overflow: auto;
        }
    }

    &__search {
        display: flex;
        justify-content: flex-end;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    &__selector {
        margin-right: 15px;
        .select-dropdown, .ui.selection.dropdown {
            height: 40px !important;
            max-height: 40px !important;
            min-height: auto;
        }

        .ui.dropdown>.dropdown.icon {
            padding: 0 !important;
            margin: auto !important;
        }
    }

    &__dropdown {
        width: 140px;
        background-color: #5076ff !important;
        color: white !important;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px !important;
        height: 40px !important;
        margin: 0 !important;
        border-radius: 10px !important;
        margin-right: 15px !important;
    }

    &__modal {
        &__content {
          &__header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            margin-top: 5px;
          }
    
          &__dropzone {
            height: 150px;
            margin-bottom: 15px;
          }
        }
    }
&__pagination {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 80px;
    justify-content: space-between;

    &__section {
        display: flex;
        align-items: center;
        position: relative;
        // margin-right: 220px;

        &__angles {
            // margin-right: 15px;
            display: flex;
            border-radius: 10px;
            user-select: none;
        }

        &__angle {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 35px;
            border-radius: 10px;
            cursor: pointer;
            box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.3);

            &:first-of-type {
                margin-right: 8px;
            }

            &:hover {
                box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.5411764705882353);
            }

            &__disabled {
                cursor: not-allowed !important;
                pointer-events: none !important;

                svg {
                    path {
                        stroke: #cdc6c6 !important;
                    }
                }
            }
        }
    }
}
}
