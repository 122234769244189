@import '../../../config';

.order-patient-overview {
  &__mobile-overview {
    display: flex;
    align-items: center;
  }

  &__detail {
    &__data {
      &.r-id {
        display: flex;
        border: 1px solid #5076ff;
        border-radius: 6px;
        margin-top: 5px;

        &-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 6px;
          border-radius: 4px 0 0 4px;
          background: #5076ff;
        }
        &-text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
  &__patient-photo {
    background: #f1f1f1;
    border-radius: 100px;
    height: 70px;
    width: 70px;

    @include mobileView {
      height: 70px;
      width: 70px;
    }

    &--error {
      border: dashed 1px #ff5252;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 100px;
    }
  }

  &__details-wrapper {
    background: white;
    width: 100%;
    border-radius: 10px !important;
    border: solid 1px rgba(239, 239, 239, 1);
    // box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 25px 40px;
    min-height: 77px;
    position: relative;

    @include mobileView {
      padding: 15px;
    }

    &__loader-wrapper {
      min-width: 100%;
      min-height: 100%;
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__loader {
        position: relative !important;
        top: initial !important;
        left: initial !important;
      }
    }

    &__row {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(8, 1fr);
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @include mobileView {
        grid-template-columns: repeat(2, 1fr);
      }

      @include smallDesktopView {
        grid-template-columns: repeat(4, 1fr);
      }

      @include desktopView {
        grid-template-columns: repeat(4, 1fr);
      }

      @include largeDesktopView {
        display: flex;
        justify-content: flex-start;
        gap: 50px;
      }
    }
  }
}
