//
// FONTS AND TYPOGRAPHY
// This is where you set up your various fonts.
// --------------------------------------------------

@mixin default-font {
  font-family: 'IBM plex sans', sans-serif;
}

$font-size-large: 28px;
$font-size-medium: 20px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-xsmall: 12px;

// Font weights
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

@mixin xsmall-text {
  font-size: $font-size-xsmall;
  font-weight: $medium;
  letter-spacing: 0.5px;
}

@mixin small-text {
  font-size: $font-size-small;
  font-weight: $medium;
  letter-spacing: 0.6px;
}

@mixin default-text {
  font-size: $font-size-base;
  font-weight: $regular;
  letter-spacing: 0.8px;
  line-height: 1.3;
}

@mixin paragraph {
  @include default-text;
  margin: 0 0 10px 0;
}

@mixin heading-small {
  font-size: $font-size-base;
  font-weight: $regular;
}

@mixin heading-medium {
  font-size: $font-size-medium;
  font-weight: $regular;
}

@mixin heading-large {
  font-size: $font-size-large;
  font-weight: $regular;
  margin: 20px 0;
}

a {
  color: inherit;
  transition: color 0.3s ease;

  &:active,
  &:hover {
    outline: 0;
  }
}

b {
  font-weight: $semibold;
}

.label {
  @include xsmall-text;

  text-transform: uppercase;
}

small {
  @include xsmall-text;
}
