.placeholder {
    width: 100%;
    align-content: center;
    align-items: center;
    height: 200px;

    &__wrapper {
        margin: auto;
        margin-top: 50px;
        border: solid 1px rgb(195, 195, 195);
        border-radius: 10px;
        width: 550px;
        padding: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}