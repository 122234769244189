@import '../../../config';

.biomarker-overview {
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 20px;

        &__left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: auto;

          overflow: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
  
          &::-webkit-scrollbar {
            display: none;
          }
        }
    }

    &__fixed-collapse {
      width: 150px;
      background: rgba(207, 207, 207, 0.26);
      padding: 10px;
      border-radius: 20px;
      position: fixed;
      bottom: 10px;
      right: 30px;
      z-index: 9;
      backdrop-filter: blur(1px);

      @include mobileView {
        width: 200px;
        right: initial;
        left: 20px;
      }

      button {
        height: 35px !important;
        min-height: 35px !important;
      }
    }

    &__legend {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-bottom: 20px;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &--hide-title {
          margin: auto;
        }
    
        &__ab {
          font-weight: 500;
        }
    
        &__sec {
          display: flex;
          align-items: center;
          margin-right: 15px;
    
          &--green {
            color: rgba(34, 211, 143, 1);
          }
          &--yellow {
            color: #ffd443;
          }
          &--red {
            color: rgba(255, 102, 102, 1);
          }
        }
    
        &__icon {
          height: 23px;
          width: 23px;
          margin-right: 4px;
        }
    }

    &__report-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        width: 100%;
    
        @include mobileView {
        }
    
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            &__buttons {
              overflow: auto;
              -ms-overflow-style: none;
              scrollbar-width: none;
              align-items: end;
        
              &::-webkit-scrollbar {
                display: none;
              }
        
              @include mobileView {
                width: 100%;
              }
        
              .button {
                color: rgba(80, 118, 255, 1) !important;
                background: rgba(226, 229, 233, 0.5) !important;
                height: 35px;
                width: 120px !important;
                max-width: 120px !important;
                max-width: 150px;
                box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.2);
                border: solid 1px rgba(212, 217, 222, 1) !important;
                font-size: 13px !important;
                font-weight: 400;
        
                @include mobileView {
                  width: 100%;
                  max-width: 50%;
                }
                &:first-of-type {
                  border-radius: 10px 0 0 10px !important;
                  border-left: solid 1px rgba(212, 217, 222, 1) !important;
                }
                &:last-of-type {
                  border-radius: 0 10px 10px 0 !important;
                  width: 150px !important;
                  max-width: 130px !important;
    
                  &:first-of-type {
                    border-radius: 10px !important;
                  }
                }
        
                &,
                .download {
                  width: 30px;
                }
              }
        
              .active.button {
                background: #5076ff !important;
                color: white !important;
                border: solid 1px #5076ff !important;
        
                // border: solid 1px #b4b4b4 !important;
              }
            }
        
            &__buttons-collapse{
                display: flex;
                flex-direction: column;
                justify-content: end;
                gap: 8px;
    
                @include mobileView {
                  width: 100%;
                }
    
                button {
                  height: 30px !important;
                  min-height: 30px !important;
                }
            }
        }
    }
}