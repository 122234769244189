.rh-pm {
    &__content {
        display: flex !important;
        flex-direction: column !important;
    }

    &__icon {
        margin: auto;
        margin-bottom: 5px;
    }

    &__pat-wrapper {
        width: 100%;
        border: solid 1px rgba(239, 239, 239, 1);
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 15px;

        &__row {
            display: flex;
            align-items: center;
        }
    }
}