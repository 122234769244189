@import '../../../config';

.patient-details {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: auto;

  &__patient-photo-wrapper {
    margin-bottom: 5px;
    &__pic-name {
      display: flex;
      align-items: center;
    }
  }

  &__checkbox {
    margin: auto;
  }

  &__risk-ass-wrapper {
    display: flex;
    gap: 50px;
    margin-bottom: 10px;
    overflow-x: auto;
    background: white;
    width: 100%;
    border-radius: 10px !important;
    box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
    display: flex;
    padding: 25px;
    position: relative;
    min-height: 100px;
    margin-top: 15px;

    &__model {
      // min-width: 400px;
    }
  }

  &__list-item {
    border: solid 1px #eaeaea;
    border-radius: 10px;
    padding: 7px;
    margin-bottom: 4px;
    background: #f1f1f198;
    display: inline-block;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &--none {
      cursor: default;
      border: solid 1px #eaeaeac0;
      background: #f1f1f134;
    }
  }

  &__details-wrapper {
    background: white;
    width: 100%;
    border-radius: 10px !important;
    box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
    display: flex;
    // flex-wrap: wrap;
    padding: 15px 20px;
    position: relative;
    min-height: 100px;
    flex-direction: column;
    justify-content: center;

    &__buttons {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-top: -4px;
    }

    &__details-row {
      display: grid;
      gap: 17px;
      grid-template-columns: repeat(4, 1fr);

      @include mobileView {
        display: flex;
        flex-direction: column;
      }
    }

    &__edit {
      position: absolute !important;
      top: 10px;
      right: 10px;
    }

    &__loader-wrapper {
      min-width: 100%;
      min-height: 100%;
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__loader {
        position: relative !important;
        top: initial !important;
        left: initial !important;
      }
    }

    &__row {
      margin-bottom: 5px;

      position: relative;
      display: grid;
      gap: 17px;
      grid-template-columns: repeat(8, 1fr);
      flex-wrap: wrap;
      width: 100%;

      &--line {
        border-top: solid 1px #efefef;
        margin-top: 12px;
        padding-top: 14px;
      }

      @include mobileView {
        grid-template-columns: repeat(2, 1fr);
      }

      @include smallDesktopView {
        grid-template-columns: repeat(4, 1fr);
      }

      @include desktopView {
        grid-template-columns: repeat(4, 1fr);
      }

      @include largeDesktopView {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;

    &__date-tag {
      position: absolute;
      top: -16px;
      left: 2px;
    }

    &__section {
      display: flex;

      & > div:first-of-type {
        margin-right: 17px;

        @media only screen and (max-width: 600px) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 600px) {
        flex-direction: column;

        &:first-of-type {
          margin-bottom: 15px;
        }

        & > div {
          margin-top: 15px;
        }
      }
    }
  }

  &__blood-type {
    background: white;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #eaeaea;
    // width: fit-content;
  }
  &__smoker {
    background: white;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
  &__vitals {
    background: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__section {
      border: solid 1px #efefef;
      border-radius: 10px;
      padding: 20px;
      height: 110px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      margin: 5px;

      &:last-of-type {
        margin-right: 0;
      }

      &__value {
        display: flex;
        align-items: baseline;
      }

      &__input {
        width: 50px !important;

        input {
          padding-right: 0;
        }
      }
      .input-error-text {
        width: 180% !important;
      }
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    margin-right: 10px;

    @include mobileView {
      margin-top: 30px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__button {
      height: 22px;
      min-height: 22px;
      max-height: 22px;
      border-radius: 8px !important;

      div {
        font-size: 14px !important;
      }
    }

    &__add-wrapper {
      margin-bottom: 6px;
      padding-bottom: 6px;
      border-bottom: solid 1px rgb(234, 234, 234);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  &__patient-photo {
    border: solid 1px #cbcbcb;
    background: #f1f1f1;
    border-radius: 100px;
    height: 80px;
    width: 80px;
    margin-right: 10px;

    &--error {
      border: dashed 1px #ff5252;
    }

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 100px;
    }
  }

  &__vitals-graphs {
    display: flex;
    background: #fbfbfbb3;
    width: 100%;
    border-radius: 10px !important;
    box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
    margin-bottom: 15px;
    padding: 15px 20px;
    position: relative;
    min-height: 100px;
    margin-top: 20px;
    overflow: auto;

    @include mobileView {
      flex-direction: column;
      padding: 10px;
    }
  }

  &__vital-graph {
    flex: 1;
    margin-right: 15px;
    max-width: 500px;

    @include mobileView {
      margin-right: 0;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__health-questionnaire {
    &__form {
      margin-top: 20px;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    align-items: baseline;
    justify-content: space-between;

    &__btns {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &__section {
    width: 100%;
    margin-bottom: 30px;
  }
&__clinic-tabs {
  width: 100% !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  // background-color: white !important;
  scrollbar-width: thin;
  // border-bottom: none !important;

  .ui.tabular.menu .active.item {
    background-color: transparent !important;
    border-bottom: none !important;
  }

  .ui.tabular.menu .item {
    background-color: white !important;
    border-bottom: 1px solid #d4d4d5 !important;
  }
}
}

.details-table {
  margin-top: 15px;
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
}

.patient-details__search__section {
  margin-left: 0px;
}

// @media only screen and (max-width:600px){
//     .patient-details{
//         margin-left: 12px;
//         width: 92vw;
//     }
//     .details-table{
//         width: 93vw;
//         overflow-x: scroll;
//         margin-left: -3px;
//     }

//     .patient-details__search{
//         margin-bottom: 7%;
//     }
//     .search-bar {
//         width: 93vw !important;
//     }

//     .search-bar .input, .djgErZ .search-bar .prompt {
//         width: 95%;
//     }
//     .patient-details__search__section{
//         margin-left: 7px;
//     }
// }

// @media only screen and (min-width:768px) and (max-width:1025px){
//     .patient-details{
//         margin-left: 28px;
//         width: 93vw;
//     }
//     .patient-details__search{
//         margin-bottom: 5%;
//     }
//     #patient-details-search{
//         width: 272px;
//     }
//       .details-table{
//         width: 101%;

//     }
//     .djgErZ .search-bar .input, .djgErZ .search-bar .prompt {
//         width: 71%;
//     }
// }

// @media only screen and (min-width:992px){

//     .patient-details__detail{
//         max-width: unset;
//         width: unset;
//     }
// }
