@import '../../../config';

.risk-assessment-donut-graph {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;

  &__risk-factor-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 3px;
    padding: 10px 20px;
    // border-radius: 50px;
    // background: linear-gradient(145deg, #efefefb6, #ffffff);
    // box-shadow: 15px 15px 40px #e3e3e3,
    //     -15px -15px 40px #ffffff;
  }

  &__bars {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-radius: 50%;
  }

  &__bars-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    // border-radius: 50px;
    // background: linear-gradient(145deg, #efefefb6, #ffffff);
    // box-shadow: 20px 20px 60px #e3e3e3,
    //     -20px -20px 60px #ffffff;
  }

  &__bar {
    width: 20px; /* Adjust the width of the rectangle */
    height: 70px; /* Adjust the height of the rectangle */
    position: absolute;
    clip-path: polygon(0 0, 100% 0, 73% 100%, 27% 100%);
    border-radius: 100% 100% 100% 100% / 28% 28% 82% 82%;
  }

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;

    &__label {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      height: 15px;
    }

    &__value {
      display: flex;
      font-weight: bolder;

      &__sup {
        position: absolute;
      }

      &__text {
        font-size: 18px;
      }
    }
  }
}
