@import 'config';

.result-line-graph {
  display: flex;
  gap: 40px;

  @include mobileView {
    flex-direction: column;
    gap: 10px;
  }


  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;

    &__header {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      margin-top: -20px;

      @include mobileView {
        margin-top: 0;
        margin-bottom: 10px;
      }

      &__trend{
        display: flex;
      }
      
      &__icon{
        display: flex;
        align-items: center;
        padding: 0 8px;
      }
    }

    &__data {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__item {
        display: flex;
        align-items: center;
        gap: 7px;
        margin-top: 4px;

        &__icon {
          display: flex;
          align-items: center;
          height: 20px;
          width: 20px;
          &--green {
            color: rgba(34, 211, 143, 1);
          }
          &--yellow {
            color: #ffd443;
          }
          &--red {
            color: rgba(255, 102, 102, 1);
          }
        }

        &__score{
          display: flex;
          align-items: baseline;
        }
      }
    }
  }

  &__canvas-wrapper {
    width: 400px;

    @include mobileView {
      width: 100%;
    }
  }
}
