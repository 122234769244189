@import '../../../config';

.patient-form {
    overflow: auto;
    position: relative;
    max-width: 1400px;
    margin: auto;

    @include mobileView {
        padding: 0;
        margin-top: 10px;
        padding-bottom: 40px;
    }

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
    
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    &__patient-photo-wrapper {
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        button, input {
            height: 35px !important;
            max-height: 35px !important;
            min-height: 35px !important;
        }

        &__pic-name {
            display: flex;
            align-items: center;
        }
    }

    &__info {
        &--mobile {
            position: absolute;
            top: 30px;
            right: 20px;
        }
    }

    &__patient-photo {
        border: solid 1px #d9d9d9;
        background: #f1f1f1;
        border-radius: 100px;
        height: 110px;
        min-height: 110px;
        width: 110px;
        min-width: 110px;
        margin-right: 20px;
    
        &--error {
          border: dashed 1px #ff5252;
        }
    
        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
          border-radius: 100px;
        }
    }

    &__id-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 25px;
    }

    &__form {
        border-radius: 10px;
        padding: 20px 45px;
        padding-top: 0;
        padding-bottom: 35px;

        @include mobileView {
            padding: 0;
        }

        &__grid-wrapper {
            padding: 10px 0;
            position: relative;

            &:first-of-type {
                padding-top: 0;
            }

            &--optional {
                border: solid 1px #0101ff70;
                border-radius: 10px;
                // margin: -10px;
                margin-top: 35px;
                margin-bottom: 10px;
                padding: 10px;
            }

            &__header {
                padding: 0px 10px;
                position: absolute;
                background: rgb(247, 248, 249);
                border-radius: 10px;
                top: -16px;
                display: flex;
            }
        }

        &:first-of-type {
            margin-top: 10px;
        }

        &__grid {
            border-radius: 10px;
            border: solid 1px rgba(239, 239, 239, 1);
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            margin-top: 5px;
            background: white;
            border-radius: 10px;
            padding: 15px;

            @include mobileView {
                grid-template-columns: repeat(1, 1fr);
            }
        }


        &__wrapper {
            border: solid 1px rgba(228, 228, 228, 0.7);
            background: white;
            border-radius: 10px;
            padding: 15px 20px;
            border-radius: 10px;
            margin-top: 5px;
            display: flex;

            @include mobileView {
                align-items: center;
                justify-content: center;
            }
        }

        &__input {
            align-self: end;
            &--new-line {
                grid-column: 1;
            }
        }
    } 
    &__button {
        @include mobileView {
            width: 100% !important;
        }
    }
}

.patient-share-modal {
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    
    &__qrCode-wrapper {
        width: 172px;
    }

    &__email-wrapper {
        width: 350px;
        border-left: solid 1px #dddddd;
        padding-left: 50px;
        margin-left: 50px;
    }

    &__qrCode {
        width: 172px;
        height: 172px;
        border-radius: 10px;
        border: solid 1px black;
        padding: 10px;
    }

    &__multi-email-input {
        font-family: 'IBM plex sans', sans-serif !important;
        align-self: center !important;
        font-size: 14px !important;
        border: solid 1px #bdbdbd !important;
        border-radius: 10px !important;
        display: inline-flex !important;
        width: 100%;
    
        &.react-multi-email {
          &.focused {
            border-color: #5076ff !important;
          }
        }
    
        input {
          font-weight: 300;
          font-size: 18px !important;
        }
      }
    
      &__multi-email-input-wrapper {
        margin: 10px 0;
        display: inline-block;
      }
}


.public-patient-form {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(255, 249, 240, 0.7);
    overflow: auto;

    &__registered {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 30px;
    }

    &__branding {
        display: flex;
        margin-bottom: 10px;
        margin-top: 10px;
        align-items: center;
        border-bottom: solid 1px rgb(227, 227, 227);
        padding-bottom: 20px;

        &__logo {
            height: 60px;
            width: 60px;
            object-fit: contain;
            border-radius: 10px;
        }
    }

    &__content {
        border-radius: 10px;
        max-width: 1700px;
        margin: auto;
        scrollbar-width: none; // Firefox
        margin-top: 5%;

        @include mobileView {
            margin-top: 10px;
            margin-bottom: 60px;
            padding: 15px;
        }
    }
}