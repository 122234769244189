@import '../../../../../config';

.viewEmergencyContact {
    &__wrapper {
        background: url('../../../../assets/rhc/emergencyContact/emergency-id.svg');
        background-repeat: 'no-repeat';
        background-size: 'cover';
        height: '270px';
        width: '100%';
    }
        &__header {
            margin-left: '20px';
        }

    &__form {
        margin-bottom: 24px;
        border-radius: 10px;
        width: 100%;
        max-width: 500px;

        &__grid-wrapper {
            margin-top: 25px;
            background: white !important;
            border-radius: 10px;
            padding: 10px 15px;

            &:first-of-type {
                margin-top: 0px;
            }
        }

        &__grid {
            border-bottom: solid 1px rgba(239, 239, 239, 1);
            display: grid;
            gap: 4px;
            grid-template-columns: repeat(1, 1fr);
            background: white;
            padding: 9px 0;

            &:last-of-type {
                margin-top: 0px;
                border-bottom: none
            }
        }


        &__wrapper {
            border-bottom: solid 1px rgba(228, 228, 228, 0.7);
            background: white;
            padding: 15px;
            margin-top: 10px;
            display: flex;

            @include mobileView {
                align-items: center;
            }
        }

        &__input {
            &--new-line {
                grid-column: 1;
            }
        }
    }
}