.rec-ph {
//   border: solid 1px rgba(239, 239, 239, 1);
  border-radius: 10px;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  max-width: 400px;

  &__icon {
    width: 100%;
    object-fit: contain;
  }
  &__row {
    margin: 10px 0;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;

    &__icon {
      margin-right: 10px;
    }
    &__text {
      text-align: center !important;
        margin-top: 15px !important;
    }
  }
  &__button-wrapper {
    width: 100%;
    margin-top: 30px;
  }
  &__button {
    border-radius: 60px !important;
  }
    img {
      object-fit: cover;
      height: 320px;
      width: 100%;
      object-position: center;
    }
}
