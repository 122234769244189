@import '../../../config';

.global__fixed-button {
  // margin-top: 10px;
  // padding-top: 10px;
  background: #F6F7F8;
  // border-top: solid 1px rgb(225, 225, 225);

  @include mobileView {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 18px;
      padding-top: 0;
      padding-bottom: 25px;
      z-index: 10;
  }

  &--no-list {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
  }

  &__back {
      background: none !important;
  }
}