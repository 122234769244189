@import '../../../../config';

.i-pat-dash {
    border: solid 1px rgb(211, 211, 211);
    border-radius: 20px;
    padding: 20px;
    background: #8080800a;
    margin-top: 50px !important;
}

.patient-details {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: auto;
    margin-bottom: 50px;

    &__patient-photo-wrapper {
        margin-bottom: 5px;
        &__pic-name {
            display: flex;
            align-items: center;
        }
    }

    &__checkbox {
        margin: auto;
    }

    &__risk-ass-wrapper {
        display: flex;
        gap: 50px; 
        overflow-x: auto;    
        background: white;
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
        display: flex;
        padding: 25px;
        position: relative;
        min-height: 100px;
        margin-top: 15px;
    }

    &__list-item {
        border: solid 1px #eaeaea;
        border-radius: 10px;
        padding: 7px;
        margin-bottom: 4px;
        background: #f1f1f198;
        display: inline-block;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &--none {
            cursor: default;
            border: solid 1px #eaeaeac0;
            background: #f1f1f134;
        }
    }
    

    &__details-wrapper {
        background: white;
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
        display: flex;
        // flex-wrap: wrap;
        padding: 15px 20px;
        position: relative;
        min-height: 100px;
        flex-direction: column;
        justify-content: center;

        &__buttons {
            width: 100%;
            display: flex;
            justify-content: end;
            margin-top: -4px;
        }

        &__details-row {
            display: grid;
            gap: 17px;
            grid-template-columns: repeat(4, 1fr);

            @include mobileView {
                display: flex;
                flex-direction: column;
            }
        }

        &__edit {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &__loader-wrapper {
            min-width: 100%;
            min-height: 100%;
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            &__loader {
                position: relative !important;
                top: initial !important;
                left: initial !important;
            }
        }

        &__row {
            margin-bottom: 5px;

            position: relative;
            display: grid;
            gap: 17px;
            grid-template-columns: repeat(8, 1fr);
            flex-wrap: wrap;
            width: 100%;

            &--line {
                border-top: solid 1px #EFEFEF;
                margin-top: 12px;
                padding-top: 14px;
            }
    
            @include mobileView {
                grid-template-columns: repeat(2, 1fr);
            }
            
            @include smallDesktopView {
                grid-template-columns: repeat(4, 1fr);
            }
            
            @include desktopView {
                grid-template-columns: repeat(4, 1fr);
            }
    
            @include largeDesktopView {
                grid-template-columns: repeat(4, 1fr);
            }

        }
    }

    &__search {
        display: flex;
        justify-content: space-between;

        &__date-tag {
            position: absolute;
            top: -16px;
            left: 2px;
        }

        &__section {
            display: flex;

            & > div:first-of-type {
                margin-right: 17px;

                @media only screen and (max-width: 600px) {
                    margin-right: 0;
                }
            }

            @media only screen and (max-width: 600px) {
                flex-direction: column;

                &:first-of-type {
                    margin-bottom: 15px;
                }

                & > div {
                    margin-top: 15px;
                }
            }
        }
    }

    &__vitals {
        background: white;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__section {
            border: solid 1px #EFEFEF;
            border-radius: 10px;
            padding: 20px;
            height: 110px;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            margin: 5px;

            &:last-of-type {
                margin-right: 0;
            }

            &__value {
                display: flex;
                align-items: baseline;
            }

            &__input {
                width: 50px !important;

                input {
                    padding-right: 0;
                }
            }
        }
    }

    &__detail {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        @include mobileView {
            margin-top: 30px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        &__button {
            height: 22px;
            min-height: 22px;
            max-height: 22px;
            border-radius: 8px !important;

            div {
                font-size: 14px !important;
            }
        }

        &__add-wrapper {
            margin-bottom: 6px;
            padding-bottom: 6px;
            border-bottom: solid 1px rgb(234, 234, 234);
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }
        
    &__patient-photo {
        border: solid 1px #cbcbcb;
        background: #f1f1f1;
        border-radius: 100px;
        height: 80px;
        width: 80px;
        margin-right: 10px;

        &--error {
            border: dashed 1px #ff5252;
        }
    
        img {
        object-fit: contain;
            height: 100%;
            width: 100%;
            border-radius: 100px;
        }
    }

    &__vitals-graphs {
        display: flex;
        background: #fbfbfbb3;
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
        margin-bottom: 15px;
        padding: 15px 20px;
        position: relative;
        min-height: 100px;
        margin-top: 20px;
        overflow: auto;

        @include mobileView {
            flex-direction: column;
            padding: 10px;
        }
        
    }

    &__vital-graph {
        flex: 1;
        margin-right: 15px;
        max-width: 500px;


        @include mobileView {
            margin-right: 0;
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        
        &:last-of-type {
            margin-right: 0;
        }
    }

    &__header {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        align-items: baseline;
        justify-content: space-between;
    }
    
    &__section {
        width: 100%;
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0 !important;
        }
    }
        &__clinic-tabs {
            width: 100% !important;
            overflow-x: auto !important;
            overflow-y: hidden !important;
            // background-color: white !important;
            scrollbar-width: thin;
            // border-bottom: none !important;
    
            .ui.tabular.menu .active.item {
                background-color: transparent !important;
                border-bottom: none !important;
            }
    
            .ui.tabular.menu .item {
                background-color: white !important;
                border-bottom: 1px solid #d4d4d5 !important;
            }
        }
}

.details-table {
    margin-top: 15px;
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
}

.i-patient-dashboard__search__section{
    margin-left: 0px;
}
