.send-results {
    &__content {
      max-width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &__header {
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px 0px #c4c4c4 !important;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    &__table {
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    }

    &__button {
      margin: auto;
      margin-top: 20px;
    }
}