@import '../../../../config';

.rhd {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;
        
        &--left {
            display: flex;
            align-items: center;
        }

        &__text {
            margin-left: 20px;

            &__h {
                margin-bottom: 5px !important;
            }

            &__status {
                padding: 3px 12px;
                border-radius: 10px;
                background: #808080;
                display: inline-block;
        
                &--completed {
                    background: rgba(34, 211, 143, 1);
                }
                &--in-progress {
                    background: rgba(255, 175, 62, 1);
                }
                &--cancelled {
                    background: rgba(255, 102, 102, 1);
                }
            }
        }
    }

    &__search {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    &__custom-dropdown {
        position: relative;
        &__portal {
            display: none;
            position: absolute;
            left: 0;
            top: 50px;
            background: white;
            border-radius: 10px;
            padding: 15px;
            border: solid 1px #2b2b2b;
            box-shadow: 2px 2px 12px 0px #cdcdcd;
            z-index: 9;

            &--open {
                display: initial;
            }
        }
    }

    &__table-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    &__details-wrapper {
        background: white;
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
        margin-bottom: 25px;
        display: flex;
        // flex-wrap: wrap;
        padding: 15px 20px;
        position: relative;
        min-height: 100px;
        flex-direction: column;
        justify-content: center;
        background: white;

        &__loader-wrapper {
            min-width: 100%;
            min-height: 100%;
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            &__loader {
                position: relative !important;
                top: initial !important;
                left: initial !important;
            }
        }

        &__row {
            margin-bottom: 5px;

            position: relative;
            display: grid;
            gap: 17px;
            grid-template-columns: repeat(8, 1fr);
            flex-wrap: wrap;
            width: 100%;

            &--line {
                border-top: solid 1px #EFEFEF;
                margin-top: 12px;
                padding-top: 14px;
            }
    
            @include mobileView {
                grid-template-columns: repeat(2, 1fr);
            }
            
            @include smallDesktopView {
                grid-template-columns: repeat(4, 1fr);
            }
            
            @include desktopView {
                grid-template-columns: repeat(4, 1fr);
            }
    
            @include largeDesktopView {
                grid-template-columns: repeat(5, 1fr);
            }

        }
    }

    &__assign-button {
        border-radius: 10px !important;
        width: 100%;
        min-width: 150px;
        max-width: 250px;
        margin-right: 15px !important;
        background: none !important;
        border: solid 1px #d1d1d1 !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
    }

    &__dot {
        height: 8px;
        width: 8px;
        border-radius: 10px;
        margin-right: 8px;
        background: #808080;

        &--final, &--completed {
            background: rgba(34, 211, 143, 1);
        }
        &--pending-review {
            background: rgb(255, 229, 62);
        }
        &--pending-results, &--pending, &--pending-vitals, &--pending-review {
            background: rgba(255, 175, 62, 1);
        }
        &--cancelled {
            background: rgba(255, 102, 102, 1);
        }
    }
}