/*
* Margin and padding helper classes
*
* Available sides:
* [empty]	= all
* x			= horizontal (left and right)
* y			= vertical (top and bottom)
* t			= top
* r			= right
* b			= bottom
* l			= left
*
* Available sizes:
* 1 - $gutter/4		= 5px
* 2 - $gutter/2		= 10px
* 3 - $gutter		= 20px
* 4 - $gutter*2		= 40px
* 5 - $gutter*3		= 60px
* 6 - $gutter*4		= 80px
*
* Usage:
* Margin: .m{side}-{size}
* Padding: .p{side}-{size}
*
* Examples:
* .m-5 adds 60px of margin to all sides
* .pb-2 adds 10px of padding to the bottom
* .mx-0 makes the horizontal margin 0
*
*/

$gutter: 10px;

/*
 * Margins
 */

// Margin 0
.m-0 {
  margin: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}

// Margin 1
.m-1 {
  margin: $gutter/4;
}
.mx-1 {
  margin-left: $gutter/4;
  margin-right: $gutter/4;
}
.my-1 {
  margin-top: $gutter/4;
  margin-bottom: $gutter/4;
}
.mt-1 {
  margin-top: $gutter/4;
}
.mr-1 {
  margin-right: $gutter/4;
}
.mb-1 {
  margin-bottom: $gutter/4;
}
.ml-1 {
  margin-left: $gutter/4;
}

// Margin 2
.m-2 {
  margin: $gutter/2;
}
.mx-2 {
  margin-left: $gutter/2;
  margin-right: $gutter/2;
}
.my-2 {
  margin-top: $gutter/2;
  margin-bottom: $gutter/2;
}
.mt-2 {
  margin-top: $gutter/2;
}
.mr-2 {
  margin-right: $gutter/2 !important;
}
.mb-2 {
  margin-bottom: $gutter/2 !important;
}
.ml-2 {
  margin-left: $gutter/2;
}

// Margin 3
.m-3 {
  margin: $gutter;
}
.mx-3 {
  margin-left: $gutter;
  margin-right: $gutter;
}
.my-3 {
  margin-top: $gutter;
  margin-bottom: $gutter;
}
.mt-3 {
  margin-top: $gutter;
}
.mr-3 {
  margin-right: $gutter !important;
}
.mb-3 {
  margin-bottom: $gutter;
}
.ml-3 {
  margin-left: $gutter;
}

// Margin 4
.m-4 {
  margin: $gutter * 2;
}
.mx-4 {
  margin-left: $gutter * 2;
  margin-right: $gutter * 2;
}
.my-4 {
  margin-top: $gutter * 2;
  margin-bottom: $gutter * 2;
}
.mt-4 {
  margin-top: $gutter * 2;
}
.mr-4 {
  margin-right: $gutter * 2 !important;
}
.mb-4 {
  margin-bottom: $gutter * 2;
}
.ml-4 {
  margin-left: $gutter * 2;
}

// Margin 5
.m-5 {
  margin: $gutter * 3;
}
.mx-5 {
  margin-left: $gutter * 3;
  margin-right: $gutter * 3;
}
.my-5 {
  margin-top: $gutter * 3;
  margin-bottom: $gutter * 3;
}
.mt-5 {
  margin-top: $gutter * 3;
}
.mr-5 {
  margin-right: $gutter * 3;
}
.mb-5 {
  margin-bottom: $gutter * 3;
}
.ml-5 {
  margin-left: $gutter * 3;
}

// Margin 6
.m-6 {
  margin: $gutter * 4;
}
.mx-6 {
  margin-left: $gutter * 4;
  margin-right: $gutter * 4;
}
.my-6 {
  margin-top: $gutter * 4;
  margin-bottom: $gutter * 4;
}
.mt-6 {
  margin-top: $gutter * 4;
}
.mr-6 {
  margin-right: $gutter * 4;
}
.mb-6 {
  margin-bottom: $gutter * 4;
}
.ml-6 {
  margin-left: $gutter * 4;
}

/*
 * Paddings
 */

// Padding 0
.p-0 {
  padding: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}

// Padding 1
.p-1 {
  padding: $gutter/4;
}
.px-1 {
  padding-left: $gutter/4;
  padding-right: $gutter/4;
}
.py-1 {
  padding-top: $gutter/4;
  padding-bottom: $gutter/4;
}
.pt-1 {
  padding-top: $gutter/4;
}
.pr-1 {
  padding-right: $gutter/4;
}
.pb-1 {
  padding-bottom: $gutter/4;
}
.pl-1 {
  padding-left: $gutter/4;
}

// Padding 2
.p-2 {
  padding: $gutter/2;
}
.px-2 {
  padding-left: $gutter/2;
  padding-right: $gutter/2;
}
.py-2 {
  padding-top: $gutter/2;
  padding-bottom: $gutter/2;
}
.pt-2 {
  padding-top: $gutter/2;
}
.pr-2 {
  padding-right: $gutter/2;
}
.pb-2 {
  padding-bottom: $gutter/2;
}
.pl-2 {
  padding-left: $gutter/2;
}

// Padding 3
.p-3 {
  padding: $gutter;
}
.px-3 {
  padding-left: $gutter;
  padding-right: $gutter;
}
.py-3 {
  padding-top: $gutter;
  padding-bottom: $gutter;
}
.pt-3 {
  padding-top: $gutter;
}
.pr-3 {
  padding-right: $gutter;
}
.pb-3 {
  padding-bottom: $gutter;
}
.pl-3 {
  padding-left: $gutter;
}

// Padding 4
.p-4 {
  padding: $gutter * 2;
}
.px-4 {
  padding-left: $gutter * 2;
  padding-right: $gutter * 2;
}
.py-4 {
  padding-top: $gutter * 2;
  padding-bottom: $gutter * 2;
}
.pt-4 {
  padding-top: $gutter * 2;
}
.pr-4 {
  padding-right: $gutter * 2;
}
.pb-4 {
  padding-bottom: $gutter * 2;
}
.pl-4 {
  padding-left: $gutter * 2;
}

// Padding 5
.p-5 {
  padding: $gutter * 3;
}
.px-5 {
  padding-left: $gutter * 3;
  padding-right: $gutter * 3;
}
.py-5 {
  padding-top: $gutter * 3;
  padding-bottom: $gutter * 3;
}
.pt-5 {
  padding-top: $gutter * 3;
}
.pr-5 {
  padding-right: $gutter * 3;
}
.pb-5 {
  padding-bottom: $gutter * 3;
}
.pl-5 {
  padding-left: $gutter * 3;
}

// Padding 6
.p-6 {
  padding: $gutter * 4;
}
.px-6 {
  padding-left: $gutter * 4;
  padding-right: $gutter * 4;
}
.py-6 {
  padding-top: $gutter * 4;
  padding-bottom: $gutter * 4;
}
.pt-6 {
  padding-top: $gutter * 4;
}
.pr-6 {
  padding-right: $gutter * 4;
}
.pb-6 {
  padding-bottom: $gutter * 4;
}
.pl-6 {
  padding-left: $gutter * 4;
}
