.side-bar-menu {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 0 15px;
  padding: 12px;
  padding-top: 32px;

  &__bg {
    backdrop-filter: blur(10px);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #f6f6f66e;
    z-index: 1;
    border-radius: 0 15px;
    border: solid 1px rgb(230, 230, 230);
  }

  &__content {
    width: 100%;
    z-index: 2;
    border-radius: 0 15px;


    &__item {
      width: 100%;
      padding-left: 30px;
      height: 55px;
      display: flex;
      align-items: center;
      font-size: 16px !important;
      font-family: 'IBM plex sans', sans-serif;
      cursor: pointer;
      margin-right: 15px;
      margin-top: 13px;
      background: white;
      color: black;
      font-weight: 500;
      box-shadow: 0px 0px 2px 0px #5076ff;
      border-radius: 10px;

      &:first-of-type {
        margin-top: 0;
      }

      &--selected {
        border-right: solid 6px #67d264;
        border-radius: 10px 0 0 10px;
        height: 100%;
        height: 80px;
        right: 0;
      }

      &__icon-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        justify-content: center;
        display: flex;
        padding-top: 10px;
        margin-right: 20px;
      }

      &__icon {
        margin-right: 18px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background: #5076ff;
        color: white;
        box-decoration-break: clone;
        // border-left: solid 3px #4698e7;
      }
    }
  }
}
