@import 'config';

.risk-model {
  padding: 25px;
  background: white;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
  max-width: 550px;

  @include mobileView {
    padding: 20px 10px;
  }

  &__header {
    display: flex;

    &__icon {
      margin-right: 10px;
      height: 58px;
      width: 58px;
      object-fit: contain;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }
  }

  &__square-wrapper {
    display: flex;
    justify-content: center;
  }

  &__square-value {
    display: inline-block;
    border-radius: 10px;
    border: solid 1px #efefef;
    padding: 10px 20px;
    color: white !important;
    box-shadow: 5px 5px 5px #efefef82,
    -5px -5px 5px #ffffff;

    &:first-of-type {
      margin-right: 10px;
    }

    &__icon {
      font-size: 1.8em !important;
    }

    &__pct {
      display: flex;
      align-items: flex-start;
    }

    &--grey {
      background: #9fabbd;
    }

    &--green {
      background: #00c48c;
    }

    &--greenish {
      background: #0e8301;
    }

    &--yellow {
      background: #ffd443;
    }

    &--orange {
      background: #ffaf3e;
    }

    &--red {
      background: #ff6666;
    }

    &--border-grey {
      border: 1px solid #9fabbd;
    }

    &--border-green {
      border: 1px solid #00c48c;
    }

    &--border-greenish {
      border: 1px solid #0e8301;
    }

    &--border-yellow {
      border: 1px solid #ffd443;
    }

    &--border-orange {
      border: 1px solid #ffaf3e;
    }

    &--border-red {
      border: 1px solid #ff6666;
    }
  }

  &__graph {
    width: 100%;
    margin-right: 15px;
    border-radius: 10px;
    padding: 10px;
    background: linear-gradient(145deg, #efefef82, #ffffff);
    box-shadow: 5px 5px 5px #efefef82,
        -5px -5px 5px #ffffff;
  }

  &__graph-wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  &__legend {
    display: flex;
    margin-bottom: 10px;

    &__info {
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }
  &__text {
    margin-top: 6px;
  }

  &__fact-exp {
    border-top: solid 1px rgba(239, 239, 239, 1);
    padding-top: 20px;
    margin-top: 30px;
  }
  
  &__risk-donut-graph {
    margin-top: 15px;
    padding: 5px 0;
    margin-bottom: 35px;
    border-radius: 50px;
    background: linear-gradient(145deg, #efefef82, #ffffff);
    box-shadow: 15px 15px 20px #efefef82,
        -15px -15px 20px #ffffff;
  }

  &__risk-donut {
    margin-top: 20px;
    padding-top: 25px;
    border-top: solid 1px rgba(239, 239, 239, 1);

    &__header {
      display: flex;
    }

    &__icon {
      height: 20px;
      width: 20px;
      margin-right: 3px;
    }

    &__graph {
      display: flex;
      justify-content: center;
    }
  }

  &__risk-facts {
    margin-top: 15px;

    &__header {
      display: flex;
      align-items: center;
      border-bottom: solid 1px #efefef;
      padding-bottom: 10px;
      line-height: 17px !important;
    }

    &__icon {
      height: 20px;
      width: 20px;
      margin-right: 7px;
    }
  }
}
