@import '../../../config';

.hvl {
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.2);
    border: solid 1px rgba(201, 201, 201, 0.3);
    padding: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    background: white;

    &__loader {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
    }

    &__text-wrapper {
        display: flex;
        max-width: 100%;
        padding-bottom: 7px;
        margin-bottom: 7px;
        margin-top: 5px;
        align-items: center;
        border-bottom: solid 1px #EFEFEF;

        &__value {
            margin-left: 5px;
            color: #5076ff;

            &--blue {
                color: #5076ff !important;
            }

            &--green {
                color: #00C48C !important;
            }

            &--greenish {
                color: #0e8301 !important;
            }
          
            &--greenish2 {
                color: #12b001 !important;
            }

            &--yellow {
                color: #FFD443 !important;
            }

            &--orange {
                color: rgba(255, 175, 62, 1) !important;
            }

            &--red {
                color: #FF6666 !important;
            }
            &--red2 {
                color: #ff3a3a !important;
            }
            &--grey {
                color: #9FABBD !important;
            }
        }
    }

    &__line-wrapper {
        margin-top: 35px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 5px;

        @include mobileView {
            margin-left: 0;
        }
    }

    &__sub-values {
        border-top: solid 1px rgba(201, 201, 201, 1);
        display: flex;
        overflow: auto;
        margin-top: 10px;
        padding-top: 10px;
    }

    &__sub-value {
        margin-right: 30px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__value {
        position: absolute;
        left: 0;
        bottom: -1px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50px;
        max-width: 50px;
        min-width: 50px;

        &__square {
            padding: 2px;
            line-height: 10px;
            display: inline-block;
            border-radius: 4px;
            margin-bottom: 5px;
            font-weight: bold;
            background: white;
            color: #32363cd7;
            border: solid 2px #5076ff;


            &--blue {
                border: solid 2px #5076ff;
            }

            &--green {
                border: solid 2px #00C48C;
            }

            &--greenish {
                border: solid 2px #0e8301;
            }
          
            &--greenish2 {
                border: solid 2px #12b001;
            }

            &--yellow {
                border: solid 2px #FFD443;
            }

            &--orange {
                border: solid 2px rgba(255, 175, 62, 1);
            }

            &--red {
                border: solid 2px #FF6666;
            }
            &--red2 {
                border: solid 2px #ff3a3a;
            }
            &--grey {
                border: solid 2px #9FABBD;
            }
        }

        &__line {
            position: relative;
            transform: rotate(90deg);
            width: 15px;
            border: solid 1px #5076ff;

            &--blue {
                border: solid 1px #5076ff;
            }

            &--green {
                border: solid 1px #00C48C;
            }

            &--greenish {
                border: solid 1px #0e8301;
            }
          
            &--greenish2 {
                border: solid 1px #12b001;
            }

            &--yellow {
                border: solid 1px #FFD443;
            }

            &--orange {
                border: solid 1px rgba(255, 175, 62, 1);
            }

            &--red {
                border: solid 1px #FF6666;
            }
            &--red2 {
                border: solid 1px #ff3a3a;
            }
            &--grey {
                border: solid 1px #9FABBD;
            }
        }

        &__dot {
            position: relative;
            height: 10px;
            width: 10px;
            border-radius: 10px;
            background: white;
            z-index: 8;
            margin-bottom: -1px;
            border: solid 1px #5076ff;

            &--blue {
                border: solid 1px #5076ff;
            }

            &--green {
                border: solid 1px #00C48C;
            }

            &--greenish {
                border: solid 1px #0e8301;
            }
          
            &--greenish2 {
                border: solid 1px #12b001;
            }

            &--yellow {
                border: solid 1px #FFD443;
            }

            &--orange {
                border: solid 1px rgba(255, 175, 62, 1);
            }

            &--red {
                border: solid 1px #FF6666;
            }
            &--red2 {
                border: solid 1px #ff3a3a;
            }
            &--grey {
                border: solid 1px #9FABBD;
            }
        }
    }

    &__line {
        position: relative;
        width: 100%;
        height: 6px;
        display: flex;
        margin-bottom: 25px;

        &__sections-wrapper {
            display: flex;
            width: 100%;
        }
        
        &__sec-wrapper {
            display: flex;
            width: 100%;
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: flex-end;
                        
            &:first-of-type {
                border-radius: 10px 0 0 10px;
            }

            &:last-of-type {
                border-radius: 0 10px 10px 0;
            }
        }

        &__section {
            background: #5076ff;
            width: 100%;
            height: 100%;
            text-align: right;

            &__text {
                position: absolute;
                bottom: -24px;
                right: -22px;
                width: 40px;
                text-align: center;
                color: #32363cd7;
                font-weight: 100;
                font-size: 13px;
            }

            &__name {
                margin-top: 10px;
                font-size: 8px;
                line-height: 10px;
                color: #808080;

                &--top {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 3px;
                }
            }

            &__sub-text {
                margin-top: 0px;
                font-size: 8px;
                line-height: 10px;
                color: #808080;

                &--top {
                    margin-top: 10px;
                }
            }

            &--blue {
                background: #5076ff;
            }

            &--green {
                background: #00C48C;
            }

            &--greenish {
                background: #0e8301;
            }
          
            &--greenish2 {
                background: #12b001;
            }

            &--yellow {
                background: #FFD443;
            }

            &--orange {
                background: rgba(255, 175, 62, 1);
            }

            &--red {
                background: #FF6666;
            }
            &--red2 {
                background: #ff3a3a;
            }
            &--grey {
                background: #9FABBD;
            }
        }
    }
}