@import '../../../../../config';

.m-obs {
    &__table-wrapper {
        width: 100%;
        overflow: auto;

        .ui.search {
            min-height: 35px !important;
            height: 35px !important;
        }

        .icon {
            height: 35px !important;
            padding-top: 9px !important;
        }
    }

    &__th {
      height: initial !important;
    }

    &__icon {
      width: 21px;
      height: 21px;
      margin-right: 5px;
      margin-top: 1px;
    }

    &__select-text {
      div.text {
        font-size: 15px !important;
        font-weight: bold !important;
      }

      span.text {
        font-weight: 300 !important;
      }

      input.search {
        min-height: 35px !important;
      }
    }

    &__row {
      height: 50px;
      min-height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 15px 20px;
      border: solid 1px #F6F7F8;
      background: white;
  
      &:last-of-type {
        margin-bottom: 0;
      }
  
      @include mobileView {
        min-height: initial !important;
        height: initial !important;
      }
  
      td {
        // border: none !important;
        border-color: rgba(34, 36, 38, 0.05) !important;
      }
  
      &__value-status {
        display: flex;
        flex-direction: row;
        gap: 20px;
  
        @include mobileView {
          flex-direction: column;
        }
      }
  
      &__value {
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-left: 20px;

        &__text {
          display: flex;
          align-items: center;
        }
      }

      &__date {
        margin-top: 25px;
        margin-bottom: -5px;
      }
  
      &__status {
        display: flex;
        align-items: center;
        flex-basis: 150px;
        justify-content: center;
      }
  
      &__range {
        width: 400px;
  
        @include mobileView {
          width: 100%;
        }
      }
  
      &__line {
        padding-bottom: 0px;
        margin-top: 30px;
        margin-bottom: -5px;
      }

      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--high {
        border: solid 1px rgba(255, 103, 103, 1) !important;
      }

      &--low {
        border: solid 1px #FFD443 !important;
      }

      &--positive {
        border: solid 1px rgba(255, 103, 103, 1) !important;
      }
      
      &--reactive {
        border: solid 1px rgba(255, 103, 103, 1) !important;
      }
    }

    &__cat-row-wrapper {
      margin-bottom: 10px;

      &--expanded {
        padding-bottom: 32px;
        border-bottom: solid 1px rgb(227, 227, 227);
        margin-bottom: 32px;

      }
    }
  
    &__cat-row {
      background: white;
      cursor: pointer;
      min-height: 75px !important;
      height: 75px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #EFEFEF;
      
      @include mobileView {
        min-height: initial !important;
        height: initial !important;
      }

      td {
        border-top: 0.5px solid rgba(34, 36, 38, 0.2) !important;
        border-bottom: 0.5px solid rgba(34, 36, 38, 0.2) !important;
      }
    }
}

.abnormal-counts {
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 3px;
    line-height: 11px;
    align-items: center;
  }
  &__icon {
    padding-top: 2px;
  }
  &__text {
    display: flex;
  }
}
