@import 'config';

.lab-report-pdf {
  height: 90vh;

  @include mobileView {
    height: 700px;
  }

  &__loader-segment {
    width: 100%;
    height: 100%;
  }

  &__generate-pdf {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__toolbar{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    max-width: 892px;

    &__download {
      button {
        margin-right: 0 !important;
      }
    }
  }
}
