@import '../../../config';

.hvr {
    border-radius: 10px;
    background: rgba(247, 247, 247, 0.7);
    border: solid 1px rgba(201, 201, 201, 0.3);
    padding: 15px 17px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

    &--High {
        background: rgba(255, 102, 102, 0.08);
        border: none;
    }

    &--Low {
        background: rgba(255, 235, 102, 0.08);
        border: none;
    }

    &--Positive {
        background: rgba(255, 102, 102, 0.08);
        border: none;
    }
    
    &--Reactive {
        background: rgba(255, 102, 102, 0.08);
        border: none;
    }

    &--Abnormal {
        background: rgba(255, 102, 102, 0.08);
        border: none;
    }

    &--light {
        background: white;
        box-shadow: none;
    }

    &__loader {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: 100%;
        border-bottom: solid 1px #e2e2e2;
        padding-bottom: 13px;

        &--no-line {
            min-width: initial;
            max-width: initial;  
            width: 100%;  
        }

        &__name {
            color: #1b1d20e4;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
        }

        &__icon {
            height: 18px;
            width: 18px;
        }
        
        &__status {
            color: #32363cd7;
            font-size: 16px;

            &--blue {
                color: #5076ff;
            }

            &--green {
                color: #00C48C;
            }

            &--greenish {
                color: #0e8301;
            }

            &--yellow {
                color: darken(#FFD443, 8%);
            }
            
            &--orange {
                color: darken(rgba(255, 175, 62, 1), 10%);
            }

            &--red {
                color: darken(#FF6666, 35%);
            }
            &--red2 {
                color: darken(#ff3a3a, 35%);
            }
        }
        
        &__value {
            color: #1b1d20ce;
            font-size: 17px;
            font-weight: bold;
            line-height: 19px;

            // &--green {
            //     color: #00C48C;
            // }

            // &--greenish {
            //     color: #0e8301;
            // }

            // &--yellow {
            //     color: darken(#FFD443, 8%);
            // }
            
            // &--orange {
            //     color: darken(rgba(255, 175, 62, 1), 10%);
            // }

            // &--red {
            //     color: darken(#FF6666, 35%);
            // }
        }
        
        &__unit-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 10px;

            &__icon {
                display: flex;
                align-items: center;
            }
        }
    
        &__unit {
            color: #32363cd7;
            font-size: 13px;
            font-weight: bold;
            white-space: nowrap;
        }
    }

    &__line-wrapper {
        margin-top: 25px;
        width: 100%;
        display: flex;
        align-items: center;

        &--no-line {
            margin-top: 15px;
            .hvr__text-wrapper__unit-wrapper {
                flex-direction: row;
            }

            .hvr__text-wrapper__icon {
                margin-right: 5px;
            }
        }

        @include mobileView {
            margin-left: 0;
        }
    }

    &__value {
        position: absolute;
        left: 0;
        bottom: -4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50px;
        max-width: 50px;
        min-width: 50px;

        &__square {
            font-size: 13px;
            padding: 0px 3px;
            display: inline-block;
            border-radius: 4px;
            margin-bottom: 5px;
            font-weight: bold;
            background: white;
            color: #32363cd7;

            &--blue {
                border: solid 2px #5076ff;
            }

            &--green {
                border: solid 2px #00C48C;
            }

            &--greenish {
                border: solid 2px #0e8301;
            }
            &--greenish2 {
                border: solid 2px #12b001;
            }

            &--yellow {
                border: solid 2px #FFD443;
            }
            
            &--orange {
                border: solid 2px rgba(255, 175, 62, 1);
            }

            &--red {
                border: solid 2px #FF6666;
            }

            &--red2 {
                border: solid 2px #ff3a3a;
            }
        }

        &__line {
            position: relative;
            transform: rotate(90deg);
            width: 15px;


            &--blue {
                border: solid 1px #5076ff;
            }

            &--green {
                border: solid 1px #00C48C;
            }

            &--greenish {
                border: solid 1px #0e8301;
            }
          
            &--greenish2 {
                border: solid 1px #12b001;
            }

            &--yellow {
                border: solid 1px #FFD443;
            }

            &--orange {
                border: solid 1px rgba(255, 175, 62, 1);
            }

            &--red {
                border: solid 1px #FF6666;
            }
            &--red2 {
                border: solid 1px #ff3a3a;
            }
        }

        &__dot {
            position: relative;
            height: 12px;
            width: 12px;
            border: solid 1px rgb(71, 71, 71);
            box-shadow: 0px 0px 3px 0px #8383838e;
            border-radius: 10px;
            z-index: 9;

            &--blue {
                background: #5076ff;
            }

            &--green {
                background: #00C48C;
            }
        
            &--greenish {
                background: #00c414;
            }
            &--greenish2 {
                background: #12b001;
            }

            &--yellow {
                background: #FFD443;
            }
           
            &--orange {
                background: #ffb41f;
            }

            &--red {
                background: #FF6666;
            }

            &--red2 {
                background: #ff3a3a;
            }
        }
    }

    &__line {
        position: relative;
        width: 100%;
        height: 8px;
        display: flex;

        &__section-wrapper {
            display: flex;
            width: 100%;
        }

        &__section {
            position: relative;
            flex: 1;
            
            &:first-of-type {
                border-radius: 10px 0 0 10px;
            }

            &:last-of-type {
                border-radius: 0 10px 10px 0;
            }

            &__text {
                position: absolute;
                bottom: -32px;
                right: -22px;
                width: 40px;
                text-align: center;
                color: #32363cd7;
                font-weight: 100;
                font-size: 12px;
            }

            &__name {
                text-align: center;
                margin-top: 4px;
                font-size: 11px;
                color: #1b1d20e4;
            }

            &--blue {
                background: #99afff;
                border: solid 2px #5076ff;
            }

            &--green {
                background: #00C48C;
                border: solid 2px #00C48C;
            }
        
            &--greenish {
                background: #00c414;
                border: solid 2px #00c414;
            }
            &--greenish2 {
                background: #12b001;
                border: solid 2px #12b001;
            }
            
            &--orange {
                background: #ffb41f;
                border: solid 2px #ffb41f;
            }

            &--yellow {
                background: #FFD443;
                border: solid 2px #FFD443;
            }

            &--red {
                background: #FF6666;
                border: solid 2px #FF6666;
            }

            &--red2 {
                background: #ff3a3a;
                border: solid 2px #ff3a3a;
            }
        }
    }
}