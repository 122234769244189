@import '../../../config';

$highlight-color: #f2f8fc;

$side-bar-width: 0px;
$side-bar-open-width: 75px;
$side-bar-item-height: 55px;
$side-bar-item-width: 68px;

$mobile-side-bar-width: 0px;
$mobile-side-bar-open-width: 220px;
$mobile-side-bar-item-height: 56px;
$mobile-side-bar-item-width: 210px;

.s-side-bar {
  .ui.vertical.menu {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
  }
}

.side-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  box-shadow: 2px 3px 3px 0px rgba(201, 201, 201, 0.2);

  @include mobileView {
    box-shadow: 2px 0px 3px 0px rgba(201, 201, 201, 0.2);
    border-radius: 10px;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 55px);
    flex: 1;
    width: $side-bar-width;
    overflow: auto;
    background: #fefefe;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    @include mobileView {
      background-color: #f6f6f6f9;
    }

    &--open {
      width: $side-bar-open-width;
      transition: width 130ms ease;

      @include mobileView {
        width: $mobile-side-bar-open-width;
      }
    }

    &--closed {
      width: $side-bar-width;
      transition: width 130ms ease;
    }

    &--highlight {
      background: $highlight-color;
    }
  }

  &__content {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 10px;
  }

  &__item {
    height: $side-bar-item-height;
    width: $side-bar-item-width;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    position: relative;
    border-left: solid 3px transparent;
    margin: auto;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;

    @include mobileView {
      width: $mobile-side-bar-item-width;
      justify-content: left;
      padding-left: 20px;
    }

    &:active {
      // box-shadow: 0px 7px 20px -10px #5076FF;
      opacity: 0.99;
    }

    &--selected {
      background: #5076ff;
      color: white;
      // box-shadow: 0px 10px 20px -10px #5076FF;
    }

    &--selected-nested {
      background: #6F6F6F26;
      // box-shadow: 0px 10px 20px -10px #5076FF;
    }

    &__title {
      font-size: 8px !important;
      line-height: 9px !important;

      @include mobileView {
        margin-left: 15px;
        font-size: 14px !important;
        line-height: 14px !important;
      }
    }

    &__icon {
      position: absolute;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      transition: all 0.3s ease;
      padding-top: 2px;
      width: $side-bar-width;
      background: #181f2c;
      z-index: 9;

      &:hover {
        background: $highlight-color;
      }
    }

    &__icon-wrapper {
      margin-left: -2px;

      &__icon {
        min-width: 19px;
        max-width: 19px;
        height: 19px;
        width: 19px;
        margin: auto;
        margin-bottom: 6px;

        @include mobileView {
          margin-bottom: 0;
        }
      }

      @include mobileView {
        display: flex;
        align-items: center;
      }

      &__icon {
        width: 22;
        min-width: 22;
        max-width: 22;
        height: 22;
        margin: auto;
        margin-bottom: 5px;
      }
    }

    &--bottom {
      position: absolute;
      bottom: 0;
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      &:hover {
        background: initial;
        border-left: solid 3px transparent;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      font-size: 16px;
      height: 100%;
      width: calc(#{$side-bar-open-width} - #{$side-bar-width});

      &--open {
        opacity: 1;
        transition: opacity 320ms ease-in;
      }
      &--closed {
        opacity: 0;
      }
    }

    &__wrapper {
      height: $side-bar-item-height;
    }
  }

  &__sub-menu {
    // border-radius: 10px 10px 0px 0px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 75px;
    width: 300px;
    z-index: 1;
  }

  &__after {
    top: -7px;
    position: absolute;
    z-index: 4;
    width: 100%;
    background: #fefefe;
    height: 20px;
    
  }
}

.pushable {
  width: 0;
  z-index: 50;
  position: absolute;
  top: 54px;
  overflow: initial !important;

  @include mobileView {
    top: 65px;
  }
}

.ui.visible.left.overlay.sidebar {
  width: 75px !important;
  border-top-style: none; 
  border-left-style: none; 
  border-bottom-style: none; 
  border-right: solid 1px rgb(238, 238, 238);
  box-shadow: none !important;
  overflow-y: initial !important;
}
