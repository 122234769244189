.va {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  justify-content: space-between;

  &__test-item {
    flex-basis: 100%;
    border-radius: 16px;
    padding: 20px;
    border: 1px solid #efefef;
    background: #fff;
  }

  &__snellen-test {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__field {
        display: flex;
        align-items: center;
        gap: 5px;

        &__label {
          flex-basis: 50px;
          flex-grow: 1;
          max-width: 70px;
        }

        &__bordered-value {
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #c9c9c9;

          &.poor {
            border: 1px solid #ffaf3e;
          }
          &.good,
          .fair {
            border: 1px solid #1aa365;
          }
        }
      }
    }
  }
  &__refraction-test {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__row {
        display: flex;
        gap: 20px;
      }
      &__field {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-direction: row;
        gap: 5px;

        &__bordered-value {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #c9c9c9;
        }
        &__bordered-text-area-value {
          min-height: 100px;
          width: 100%;
          border: 1px solid #c9c9c9;
          padding: 12px;
          border-radius: 8px;
        }
      }
    }
  }
  &__color-blindness {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__body {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;

      &__field {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        gap: 5px;

        &__bordered-value {
          padding: 10px 12px;
          border-radius: 8px;
          border: 1px solid #c9c9c9;

          &.yes {
            border: 1px solid #ffaf3e;
          }
          &.no {
            border: 1px solid #1aa365;
          }
        }
        &__bordered-text-area-value {
          min-height: 100px;
          width: 100%;
          border: 1px solid #c9c9c9;
          padding: 12px;
          border-radius: 8px;
        }
      }
    }
  }
  &__eye-pressure {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__body {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;

      &__field {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 5px;
        border-radius: 10px;
        border: 1px solid #c9c9c9;

        &.healthy {
          border: 1px solid #1aa365;
        }
        &.unhealthy {
          border: 1px solid #ffaf3e;
        }
      }
    }
  }
}
