.s-bio-sec {
    width: 100%;
    border: solid 1px rgb(236, 236, 236);
    padding: 15px;
    padding-bottom: 10px;
    border-radius: 10px;
    margin-bottom: 10px;

    &__display {
      font-size: 1.1rem;
      line-height: 0.95rem;
      font-weight: 600;
    }

    &__unit {
      font-size: 11px;
      font-weight: 400;
      margin-left: 5px;
    }
    
    &__result {
      font-size: 0.58rem;
      line-height: 0.95rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-top: 10px;

      &__icon {
        height: 17px;
        width: 17px;
        margin-right: 4px;
      }
    }

    &__info {
        /* border: solid 0.06rem #efefef; */
        border-radius: 0.6rem;
        display: flex;
        break-inside: avoid;
        margin-top: 0.7rem;

        &--High {
            background: #fff3f3;
          }
          &--Low {
            background: #fffef3;
          }
          &__header {
            font-weight: 500;
            font-size: 0.8rem;
            margin-bottom: 0.1rem;
          }
          &__sub-header {
            font-weight: 500;
            font-size: 0.75rem;
            margin-bottom: 0.1rem;
            margin-top: 0.5rem;
          }
          &__text {
            font-weight: 400;
          }
          &__icon {
            height: 1.1rem;
            width: 1.2rem;
            object-fit: contain;
            margin-right: 0.55rem;
            object-fit: contain;
          }
      }
}