.bio-ex-p {
    // border: solid 1px blue;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    padding: 40px 15px;
    padding-top: 20px;
    margin-top: 15px;
    background: linear-gradient(145deg, #ececec, #ffffff);
    box-shadow:  10px 10px 25px #e6e6e6,
                 -10px -10px 25px #ffffff;

    &__img {
        height: 120px;
    }
}