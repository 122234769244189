@import '../../../../config';

.react-health-promo {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1200px;
  margin: auto;
  border: solid 1px #d2d2d2;
  background: white;
  border-radius: 15px;
  padding: 10px 30px;
  padding-bottom: 40px;

  &__head {
    width: 80%;
    margin-bottom: 2rem;
  }
  &__body {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    margin-bottom: 20px;

    @include mobileView {
      flex-direction: column;
    }

    &__column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-basis: 100%;
      position: relative;
    }

    &__card {
      //default
      background-image: linear-gradient(25deg, #d3e7ff, #f2f8fe 92%);
      border: 1px solid #dcebf9;
      border-radius: 1.25rem;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      flex-basis: 100%;
      position: relative;
      overflow: hidden;

      //customizations
      &.retrace {
        flex-direction: column-reverse;
      }

      &__content {
        //default
        padding: 2rem;
      }

      &__images-container {
        //default
        position: relative;
        display: flex;
        justify-content: flex-end;
        height: 100%;

        //customizations
        &.retrace {
          justify-content: flex-start;
        }
      }
      &__image {
        //default
        max-width: 100%;
        &.primary {
          z-index: 2;
        }
        &.secondary {
          z-index: 1;
          mix-blend-mode: multiply;
          position: absolute;
        }

        //customizations
        &.detect {
          &.primary {
            z-index: 2;
            margin-bottom: 20px;
          }
        }
        &.accurate {
          &.primary {
            z-index: 2;
            margin-bottom: 40px;
            max-width: 90%;
          }
          &.secondary {
            z-index: 1;
            position: absolute;
            mix-blend-mode: unset;
            bottom: 0;
            left: 40px;
            max-width: 70%;
          }
        }
        &.retrace {
          &.primary {
            z-index: 2;
            position: absolute;
            top: 50px;
            right: 12px;
            max-width: 87%;
          }
          &.secondary {
            z-index: 1;
            margin-left: 20px;
            position: relative;
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    gap: 3rem;
    @include mobileView {
      justify-content: flex-start;
    }
    &__item {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      @include mobileView {
        justify-content: flex-start;
      }
    }
  }
}
