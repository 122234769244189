@import '../../../../../config';

.emergencyContact {
    &__form {
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 20px 45px;
        padding-top: 0;
        padding-bottom: 35px;
        margin: auto;
        margin-top: 30px;

        @include mobileView {
            padding: 0;
        }

        &__grid-wrapper {
            margin-top: 25px;

            &:first-of-type {
                margin-top: 0px;
            }
        }

        &__grid {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(4, 1fr);
            margin-top: 10px;
            background: none;
            border-radius: 10px;
            padding-bottom: 20px;

            .ui.selection.dropdown {
                background: none !important;
            }

            .PhoneInputInput {
                background: none !important;
            }

            @include mobileView {
                grid-template-columns: repeat(1, 1fr);
            }
        }


        &__wrapper {
            border: solid 1px rgba(228, 228, 228, 0.7);
            background: none;
            border-radius: 10px;
            padding: 15px;
            border-radius: 10px;
            margin-top: 10px;
            display: flex;

            @include mobileView {
                align-items: center;
            }
        }

        &__input {
            &--new-line {
                grid-column: 1;
            }
                    
        }
    }

    &__button {
        &__back {
            background: none !important;
        }
    }


}