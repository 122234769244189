.public-certificate-view {
  margin: auto;
  max-width: 800px;
  min-width: 800px;
  justify-content: center;
  position: relative;
  padding-top: 15px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 860px) {
    transform: scale(0.8);
    max-width: initial;
    min-width: initial;
    width: 100%;
    padding-top: 0;
    margin-bottom: -8.5cm !important;
    margin-top: -8.5cm !important;
  }

  @media screen and (max-width: 600px) {
    transform: scale(0.6);
    margin-bottom: -8.5cm !important;
    margin-top: -8.5cm !important;
  }

  @media screen and (max-width: 450px) {
    transform: scale(0.5);
    margin-bottom: -8.5cm !important;
    margin-top: -8cm !important;
  }

  @media screen and (max-width: 400px) {
    transform: scale(0.46);
    margin-bottom: -8.5cm !important;
    margin-top: -7.5cm !important;
  }

  &__certificate {
    position: relative;
    overflow: auto;
    margin-top: 30px;
    margin-bottom: 15px;

    @media screen and (max-width: 800px) {
      overflow: initial;
    }
  }

  &__loader-wrapper {
    margin-top: 345px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      margin: auto;
    }
  }
}
