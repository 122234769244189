.rh-bcm {
    &__content {
        &__header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            margin-top: 5px;
        }

        &__dropzone {
            height: 90px;
            margin-bottom: 15px;

            div {
                width: initial !important;
            }
        }
    }

    &__data-wrapper {
        margin-top: 20px;
        margin-bottom: 25px;
        position: relative;

        &__clear {
            position: absolute;
            right: 0;
            top: 0px;
        }
    }

    &__c-data-wrapper {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px rgb(241, 241, 241);
    }
}