@import 'config';

.issue-certificate {
  margin: auto;
  max-width: 1200px;
  min-width: 800px;
  position: relative;
  width: 100%;
  margin-bottom: 50px;

  @include mobileView {
    padding: 0;
    max-width: 100%;
    min-width: 100%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
  }

  .recent-docs {
    margin: 15px 0 20px 0;
    border: solid 1px rgb(221, 221, 221);
    border-radius: 10px;
    padding: 10px;

    &__title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__list {
      display: flex;
      overflow: auto;

    /* defining scrollbar style */
    &::-webkit-scrollbar {
      height: 0.3rem;
    }
    &::-webkit-scrollbar-track {
        border-radius: 1rem;
        box-shadow: inset 0 0 6px white;
        -webkit-box-shadow: inset 0 0 6px white;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        box-shadow: inset 0 0 0px #e3e2e2;
        -webkit-box-shadow: inset 0 0 6px #e3e2e2;
    }

      &__button {
        min-width: fit-content !important;
        border-radius: 8px !important;
        margin-right: 15px;
      }
      &__button > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      }
    }
  }

  &__templates {
    position: absolute;
    top: 2px;
    right: 0;

    &__content {
      width: 100% !important;
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: 200px;
      position: relative;
      justify-content: center;

      &__placeholder {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        margin-top: 100px;
      }
    }
  }
}
