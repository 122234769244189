.yes-or-no {
  display: flex;
  border-radius: 12px;
  border: 1px solid #f1f2f4;
  padding: 1px;
  background: #f6f7f8;
  width: 100%;

  &__yesno {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    border: 1px solid #1c1c1c00;
    border-radius: 7px;

    &.active {
      border: 1px solid #3357d9;
      background: #fff;
    }
  }
}
