.data-wrapper {
  &__label {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  &__text-area {
    margin-top: 30px;
  }

  &__data {
    display: flex;
    flex-direction: row;

    &__multi {
      border: solid 1px #dddddd;
      border-radius: 3px;
      padding: 10px;
      margin-right: 10px;
    }
  }
}
