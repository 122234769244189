.mobile__pdf__container{
    height: 100vh;
}

.mobile__pdf__container footer{
    left: 27%;
    width: 65%;
}
.mobile__pdf__container #viewerContainer .pdfViewer {
    overflow: unset;
}
.mobile__pdf__container__header{
    display: none;
}

canvas {
    width: 100% !important;
    height: auto !important;
  }

@media (orientation:'portrait'){
    .mobile__pdf__container footer{
        left: 0;
        width: 100%;
    }
}

@media only screen and (max-width:600px){
    .page   {
        width: 360px !important;
        height: 448px !important;
       
    }

    .canvasWrapper  {
        // width: 360px !important;
        height: 448px !important;
       
    }
}