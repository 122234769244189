@import 'config.scss';

.imageViewer {
    width: 100%;
    padding: 15px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 89vh;

    @include mobileView {
        width: 98vw;
            height: 88vh;
            padding: 15px 2px 15px 11px;
    }

    &__wrapper {
        width: 90%;
        overflow: hidden;
        position: relative;
        align-self: center;
        height: 100%;
        overflow: auto;

        @include mobileView {
            width: 100%;
        }
    }

    &__main {
        width: 100%;
        overflow-y: scroll;
        align-self: center;
        height: 100%;

        @include mobileView {
            transform-origin: 0 0;
            height: 90vh;
        }
    }

    &__img {
        width: 100%;
        margin-top: 10px;
    }

    &__tools {
        align-self: flex-end;
        @include mobileView {
                padding-right: 9px;
            }
    }
}