@import 'config';

.email-accordion {
  margin-bottom: 10px;

  .accordion {
    border-radius: 0 !important;
    width: 21cm !important;
    margin: auto;
    box-shadow: 0px 0px 2px 0px #9e9e9e !important;
  }

  .title {
    color: black !important;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    tr {
      display: flex;
      align-items: center;
    }
  }
  &__events-wrapper {
    &__more {
      color: #4183c4 !important;
    }

    &__status {
      margin-left: 20px;
    }

    .accordion {
      border-radius: 10px !important;
    }

    display: flex;
    @include mobileView {
      width: 98vw !important;
    }

    .content {
      @include mobileView {
        width: 98vw !important;
        overflow: auto;
      }
    }
    .table {
      @include mobileView {
        width: max-content !important;
      }
    }
  }
}
