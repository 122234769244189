.p-enc-n {
    border-radius: 24px;
    border: 1px solid #EFEFEF;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    margin: 0 -5px;
    margin-top: -10px;
    margin-bottom: 25px;
    position: relative;

    &__content {
        padding: 4px 8px;
        display: flex;

        &__text-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 8px;
            padding-top: 10px;
            padding-bottom: 8px;
        }
        
        &__text {
            width: 200px;
            div {
                line-height: 17px !important;
            }
        }
    }

    &__bg {
        width: 100%;
        height: 100%;
    }

    &__header {
        margin-bottom: 3px;
    }

    &__button {
        height: 28px !important;
        margin-top: 5px;
        padding: 0 20px;
        width: fit-content !important;
    }

    &__img {
        height: 110px;
    }

    &__arrow {
        width: 13px;
        height: 13px;
        object-fit: contain;
        margin-left: 5px;
    }
}