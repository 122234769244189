.email-modal {
  width: 655px !important;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }

  &__content {
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      align-items: center;
      padding-bottom: 50px;
      padding-top: 10px;
    }

    &__info {
      box-shadow: 0px 0px 1px 2px #e7e7e7;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 25px;
      text-align: center;
    }

    &__row {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media only screen and (max-width: 768px) {
        margin-top: 0px;
        justify-content: center;

        & > div {
          margin-top: 12px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 7px;

    & > button:first-of-type {
      margin-right: 15px;
      @media only screen and (max-width: 768px) {
        margin-right: 5px;
      }
    }
  }
}
