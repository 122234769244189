.encouragement {
    // display: inline-flex;
    padding: 0 30px;

    &__svg {
        width: 100%;
        object-fit: contain;
        // height: 218px;
    }

    .rec-ph__button-wrapper {
        padding: 0 !important;
    }
}