.rac {
    &__graph {
        &__line {
            margin-top: 20px;
            padding-bottom: 0;
        }
    }

    &__status {
        margin-left: 3px;
        font-weight: bold;
 
        &--low, &--normal {
            color: rgba(34, 211, 131, 1);
        }
        &--borderline {
            color: #FFD443;
        }
        &--intermediate {
            color: rgba(255, 175, 62, 1);
        }
        &--high {
            color: rgba(255, 103, 103, 1);
        }

    }
    
    &__tag-wrapper {
        display: flex;
        margin-bottom: 3px;
        white-space: nowrap;
    }

    &__tag {
        padding: 2px 4px;
        padding-right: 7px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;

        &--low, &--normal {
            background: rgba(34, 211, 131, 1);
        }
        &--borderline {
            background: #FFD443;
        }
        &--intermediate {
            background: rgba(255, 175, 62, 1);
        }
        &--high {
            background: rgba(255, 103, 103, 1);
        }

        &__icon {
            height: 20px;
            width: 20px;
        }
    }

    &__icon {
        margin-right: 10px;
        height: 70px;
        width: 70px;
        object-fit: contain;
    }

    &__button {
        margin-left: 15px;
        display: flex;
        align-items: center;
    }

    &__dropdown-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 23px;
        padding: 5px;
        border: 1px solid hsl(0, 0%, 67%);
        border-radius: 11px;
        cursor: pointer;
    }

    &__modal {
        width: initial !important;
    }
}