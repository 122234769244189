@import '../../../../../../config';

.prsd {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  margin: auto;
  margin-bottom: 40px;

  &__patient-photo-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    &__pic-name {
      display: flex;
      align-items: center;
    }
  }

  &__checkbox {
    margin: auto;
  }

  &__risk-ass-wrapper {
    margin-top: 10px;
    gap: 50px;
    overflow-x: auto;
    background: white;
    width: 100%;
    border-radius: 10px !important;
    // box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
    margin-bottom: 15px;
    display: flex;
    // padding: 25px;
    position: relative;
    min-height: 100px;
  }

  &__list-item {
    border: solid 1px #eaeaea;
    border-radius: 10px;
    padding: 7px;
    margin-bottom: 4px;
    background: #f1f1f198;
    display: inline-block;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &--none {
      cursor: default;
      border: solid 1px #eaeaeac0;
      background: #f1f1f134;
    }
  }

  &__review-session-btn {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__top-details-wrapper {
    display: flex;
    gap: 20px;
    padding: 0 0 10px 0;

    @include mobileView {
      flex-wrap: wrap;
      flex-grow: 1;
    }

    &__left {
      flex-basis: 40%;
      border: 1px solid #efefef;
      background: white;
      border-radius: 10px;
      padding: 16px 20px;
      width: 100%;
    }
    &__right {
      flex-basis: 60%;

      & > .lr-dn {
        margin-top: 0;
        background: white;
      }
    }
  }
  &__details-wrapper {
    width: 100%;
    border-radius: 10px !important;
    // box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
    margin-bottom: 15px;
    display: flex;
    // flex-wrap: wrap;
    // padding: 15px 20px;
    position: relative;
    min-height: 100px;
    flex-direction: column;
    justify-content: center;

    &__buttons {
      width: 100%;
      display: flex;
      // justify-content: end;
      margin-top: -4px;
      margin-bottom: 15px;
    }

    &__details-row {
      display: grid;
      gap: 17px;
      grid-template-columns: repeat(4, 1fr);

      @include mobileView {
        display: flex;
        flex-direction: column;
      }
    }

    &__edit {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__loader-wrapper {
      min-width: 100%;
      min-height: 100%;
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__loader {
        position: relative !important;
        top: initial !important;
        left: initial !important;
      }
    }

    &__row {
      margin-bottom: 5px;

      position: relative;
      display: grid;
      gap: 17px;
      grid-template-columns: repeat(2, 1fr);
      flex-wrap: wrap;
      width: 100%;

      &--line {
        margin-top: 12px;
        padding-top: 14px;
      }
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;

    &__date-tag {
      position: absolute;
      top: -16px;
      left: 2px;
    }

    &__section {
      display: flex;

      & > div:first-of-type {
        margin-right: 17px;

        @media only screen and (max-width: 600px) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 600px) {
        flex-direction: column;

        &:first-of-type {
          margin-bottom: 15px;
        }

        & > div {
          margin-top: 15px;
        }
      }
    }
  }

  &__vitals {
    background: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__section {
      border: solid 1px #efefef;
      border-radius: 10px;
      padding: 20px;
      height: 110px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      margin: 5px;

      &:last-of-type {
        margin-right: 0;
      }

      &__value {
        display: flex;
        align-items: baseline;
      }

      &__input {
        width: 50px !important;

        input {
          padding-right: 0;
        }
      }
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    margin-right: 10px;

    @include mobileView {
      margin-top: 30px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__button {
      height: 22px;
      min-height: 22px;
      max-height: 22px;
      border-radius: 8px !important;

      div {
        font-size: 14px !important;
      }
    }

    &__add-wrapper {
      margin-bottom: 6px;
      padding-bottom: 6px;
      border-bottom: solid 1px rgb(234, 234, 234);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  &__patient-photo {
    border: solid 1px #cbcbcb;
    background: #f1f1f1;
    border-radius: 100px;
    height: 80px;
    width: 80px;
    margin-right: 10px;

    &--error {
      border: dashed 1px #ff5252;
    }

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 100px;
    }
  }

  &__vitals-graphs {
    display: flex;
    background: #fbfbfbb3;
    width: 100%;
    border-radius: 10px !important;
    box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
    min-height: 100px;
    margin-top: 20px;
    overflow: auto;

    @include mobileView {
      flex-direction: column;
      padding: 10px;
    }
  }

  &__vital-graph {
    flex: 1;
    margin-right: 15px;
    max-width: 500px;

    @include mobileView {
      margin-right: 0;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__section {
    width: 100%;
    margin-bottom: 35px;

    &__sectional-header {
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 15px;
      margin: 25px -8px;
      margin-top: 15px;
      background: #efefef63;
      // box-shadow: 5px 5px 10px #efefef,
      //     -5px -5px 10px #ffffff;
      border-radius: 5px;
      border: solid 1px rgba(128, 128, 128, 0.25);
    }
  }

  &__status {
    &__section {
      display: flex;
      flex-grow: 1;
      gap: 20px;

      &__details {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;

        &__date {
          display: flex;
          flex-basis: 270px;
          gap: 10px;
        }

        &__time {
          flex-basis: 100px;
        }
        
        &__progress {
          text-transform: capitalize;
          padding: 5px 10px;
          border-radius: 8px;
          border: solid 1px #111;
          display: flex;
          flex-basis: 150px;
          align-items: center;
        }
      }

      &__email-btns {
        display: flex;
        gap: 20px;
      }

      &--activity {
        flex-basis: 100%;
        border-radius: 10px;
        border: solid 1px #efefef;
        padding: 15px;
        background: #f5f6f7;
      }
      &--send-email {
        flex-basis: 100%;
        padding: 15px;
        border-radius: 10px;
        border: solid 1px #efefef;
        background: url('../../../../../assets/rhc/emptyStates/ReACThealth-emptyState.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 222px;
        background-position-y: -70px;
      }
    }
  }
}

.details-table {
  margin-top: 15px;
}

.patient-details__search__section {
  margin-left: 0px;
}
