@import 'config';

.certificate-view {
  width: 21cm;
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 5px;
  flex-direction: column;
  font-family: "IBM plex sans",sans-serif !important;

  &__wrapper {
    display: flex;
    flex-direction: column;

    &--top {
      margin-top: 35px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__nota-status {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #bbbbbb8a;
    border: solid 1px #f35353;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;

    &--green {
      border: solid 1px #5076ff;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    height: 50px;
    width: 21cm;
    background-color: #00b995;
    box-shadow: 0px 0px 5px 0px #bbbbbb8a;

    &--red {
      background-color: #e02c2c;
    }
  }

  &__status-info {
    margin-bottom: 10px;
    box-shadow: 0px 0px 2px 0px #9e9e9e;
    border-radius: 10px;
    background: white;
    display: flex;
    justify-content: space-around;

    &__dot {
      height: 8px;
      width: 8px;
      border-radius: 10px;
      margin-right: 9px;
      background: #5bc592;

      &--red {
        background: red;
      }
    }

    &__section {
      padding: 11px 20px;
      display: flex;
      flex-direction: column;
      min-width: 150px;

      &__header {
        margin-bottom: 6px;
      }

      &__text {
        display: flex;
        align-items: center;
      }
    }
  }

  &__print-button {
    &__dropdown {
      border-left: solid 1px !important;
      background-color: #5076ff !important;
      height: 40px;
      display: flex !important;
      align-items: center;
      border-radius: 10px !important;

      .icon {
        right: 0px;
        top: -1px;
        padding-top: 2px;
      }
    }
  }

  &__modal-content {
    display: flex !important;
    justify-content: center;

    &__button {
      margin-right: 20px;
    }
  }

  &__image-cert {
    width: 100%;

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &__log {
      border-radius: 5px;
      padding: 5px;
      padding-top: 15px;
      width: 270px;
      background-color: white;
      box-shadow: 0px 0px 3px 0px #d2d2d2;
      margin-left: 20px;
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: auto;

      .certificate-view__loader-wrapper {
        margin-top: 150px !important;
        height: 200px !important;
      }

      &__entry {
        width: 100%;
        padding: 15px 10px;
        border-bottom: solid 1px #dedede;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  &__print-modal {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    padding-top: 0 !important;

    &__info {
      padding: 15px;
      padding-bottom: 0;
      border: solid 1px #5076ff;
      border-radius: 5px;
      width: 450px;
      margin: auto;
      margin-bottom: 30px;
      font-size: 16px;
    }

    &__switch {
      display: flex;
    }

    &__label {
      margin-left: 15px;
      font-size: 16px;
    }

    &__header {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__content {
      width: 450px;
      margin: auto;
      margin-bottom: 20px;
    }

    &__buttons {
      margin: auto;
      margin-top: 20px;
    }
  }

  &__cert {
    // padding-top: 65px;
    position: relative;
    width: 100%;
    // padding-top: 129.41%; /* 16:9 Aspect Ratio */
    border-radius: 10px;

    &__pdf {
      height: 770px;
      width: 500px;
      margin-top: 20px;
    }

    &__cert-image {
      box-shadow: 0px 0px 5px 0px #bbbbbb8a;
      margin-top: 75px;
      margin-right: 33px;
      position: relative;
      overflow: hidden;
      user-select: none;
      cursor: crosshair;
      min-width: 500px;
      max-width: 500px;
      margin: auto;
      margin-top: 30px;

      img {
        width: 600px;
        object-fit: contain;
        width: 100%;
        display: block;
        cursor: none;
      }

      & > div {
        & > img {
          width: 800px !important;
        }
      }
    }
  }

  &__loader-wrapper {
    margin-top: 250px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      margin: auto;
    }
  }

  &__error-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__error {
      height: 400px;
    }
  }
}

.digital-cert {
  position: relative;
}
