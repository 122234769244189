$maxMobile: 768px;
$minSmallDesktop: 769px;
$minDesktop: 1024px;
$minLargeDesktop: 1701px;

$color-primary: white;
$color-secondary: #fff9f6;
$color-green: #1fa97f;
$color-gray: #f8fafc;

$padding: 10px;
$border-radius: 7px;
$button-color: $color-green;
$button-color-hover: #3dcea2;
$text-color: white;
$button-height: 2.6em;
$color: #1fa97f;
$background-color-white: $color-primary;
$background-color-cream: $color-secondary;
$background-color-gray: $color-gray;
$box-shadow: 0px 0px 5px 0px #bbbbbb8a;
$border-color: $color-green;

@mixin mobileView {
  @media only screen and (max-width:#{$maxMobile}) {
    @content;
  }
}

@mixin smallDesktopView {
  @media only screen and (min-width:#{$minSmallDesktop}) {
    @content;
  }
}

@mixin desktopView {
  @media only screen and (min-width:#{$minDesktop}) {
    @content;
  }
}

@mixin largeDesktopView {
  @media only screen and (min-width:#{$minLargeDesktop}) {
    @content;
  }
}
