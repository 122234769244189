.activate-user {
  width: 100%;
  height: 100%;
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  a {
    color: #4183c4 !important;
  }

  &__title-wrapper {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__body-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;

    & > div {
      margin-bottom: 20px;
    }

    &__checkbox {
      display: flex;
      width: 100%;
    }
  }
}
