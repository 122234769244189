.plato-issue {
  height: 100vh;
  background: rgba(255, 249, 237, 0.7);
  overflow: auto;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &__image {
      height: 35px;
      width: 35px;
      margin-right: 10px;
      border-radius: 10px;
      object-fit: contain;
    }
  }

  &__content {
    margin: auto;
    max-width: 1200px;
    min-width: 800px;
    position: relative;
    width: 100%;
    padding-top: 40px;
    margin-bottom: 80px;
  }

  &__error {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    &__images {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 60px;
    }

    &__image {
      height: 100px;
      border-radius: 10px;
      object-fit: contain;
      background: white;

      &:first-of-type {
        margin-right: 15px;
        padding: 15px;
      }
    }
  }

  &__inputs-wrapper {
    width: 100%;
    border-radius: 10px;
    min-height: 100px;
    padding-top: 10px;
  }
}