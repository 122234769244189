@import '../../../../config';

.p-signup-w {
    background: #f1f3f5a7;
    padding: 15px;
    padding-top: 25px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto;

    &__content {
        max-width: 1200px;
        margin: auto;
    }

    @include mobileView {
        padding-bottom: 60px;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 0px 45px;
        padding-top: 20px;
        
        @include mobileView {
            padding: 0px;
        }
    }

    &__logo {
        width: 120px;
        object-fit: contain;
        margin-bottom: 15px;
        background: white;
        border-radius: 10px;
        padding: 10px;
        border: solid 1px rgba(228, 228, 228, 0.7);
    }
}

.psw-bread-c {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0px 45px;
    @include mobileView {
        padding: 0px;
    }

    &__sections {
        width: 100%;
        display: flex;
        gap: 5px;
    }
    &__section {
        height: 7px;
        border-radius: 10px;
        width: 33%;
        background: rgba(201, 201, 201, 1);

        &--active {
            background: rgba(76, 76, 255, 1);
        }
    }
    &__count {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        margin-bottom: 10px;
    }
}