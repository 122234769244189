@import 'config';

.certificate-creation {
  &__info {
    border: solid 1px #5076ff;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 20px 0px rgba(201, 201, 201, 0.4);
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;

    &__text {
      display: flex;
      align-items: center;

      & > svg {
        margin-right: 15px;
        height: 30px;
        width: 30px;
      }
    }
  }

  &__type-select {
    width: 100%;
    .select-document {
      @include mobileView {
        width: 98vw !important;
      }
    }
  }

  &__modal-content {
    &__upload {
      width: 300px;
      height: 50px;
      margin: auto;
    }

    &__file {
      border: solid 1px grey;
      border-radius: 10px;
      width: 350px;
      margin: auto;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      overflow: hidden;
    }

    &__text {
      text-align: center;
      margin-bottom: 15px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      button:first-of-type {
        margin-right: 15px;
      }
    }
  }

  &__content {
    margin: auto;
    max-width: 1200px;
    min-width: 800px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include mobileView {
      min-width: 98vw;
    }

    &__accordion {
      width: 100% !important;
      margin-top: 20px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 7px 0px #d8d8d88a !important;
      border-radius: 10px !important;

      .title {
        padding: 15px 20px !important;
      }
    }
  }

  &__template-name {
    margin-top: 15px;

    input {
      background: white;
    }
  }

  &__scroller {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    padding-right: 10px;

    & > div {
      &:first-of-type {
        width: calc(100% - 110px);
        padding-right: 10px;
      }
    }

    &__image {
      height: 140px;
      border-radius: 8px;
      object-fit: contain;
      box-sizing: border-box;
      border: solid 2px transparent;
      margin: 0 5px;
      padding: 5px;
      max-width: 160px;

      &--selected {
        border: solid 2px #5076ff;
      }

      &:hover {
        box-shadow: 0px 0px 2px 0px #73bbf4;
      }
    }
    &__water-image {
      height: 350px;
      width: 250px;
      border-radius: 8px;
      object-fit: contain;
      box-sizing: border-box;
      border: solid 2px #f5f5f5;
      padding: 5px;
      margin: 0 5px;

      &--selected {
        border: solid 2px #5076ff;
      }

      &:hover {
        box-shadow: 0px 0px 2px 0px #73bbf4;
      }
    }

    &__stamp {
      height: 130px;
      width: 130px;
      border-radius: 8px;
      object-fit: contain;
      box-sizing: border-box;
      border: solid 2px #f5f5f5;
      padding: 5px;
      margin: 0 5px;

      &--selected {
        border: solid 2px #5076ff;
      }

      &:hover {
        box-shadow: 0px 0px 2px 0px #73bbf4;
      }
    }
  }

  &__watermark-accordion {
    height: 388px;
  }
  &__stamp-accordion {
    height: 200px;
  }
}

.cert-template {
  background: white;
  box-shadow: 0px 0px 7px 0px #d8d8d88a;
  position: relative;
  border-radius: 10px;
  padding: 15px 20px;

  &__middle-page {
    margin: 0;
  }

  &__info {
    &__inputs-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 150px !important;
    }

    &__input {
      position: relative;
      margin: 10px 0;
      padding-right: 15px;
      flex: 1;
      width: 33%;
      max-width: 33%;
      min-width: 33%;

      &--row {
        display: flex;
        align-items: flex-end;
      }

      &--wide {
        width: initial;
        max-width: initial;
        min-width: initial;

        div,
        input {
          width: 100% !important;
        }
      }
    }

    &__header {
      font-family: 'IBM plex sans', sans-serif;
      color: #1b1b1b;
      font-size: 24px;
      line-height: 27px;
      font-weight: 300;
      margin-bottom: 5px;
    }
  }

  &__loader {
    top: 300px !important;
  }

  &__logo-placeholder {
    margin-bottom: 30px;
    position: relative;

    &__square {
      width: 100%;
      height: 100px;
      object-fit: contain;
      border-radius: 6px;
      border: 1px dotted #ccc;
      background: white;

      &--invalid {
        border: 1px dotted #ff5252;
      }
    }
  }

  &__background {
    // background: url(../../../assets/images/certificate-pattern.png) no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 10px;
  }

  &__stamp {
    height: 130px;
    border-radius: 5px;
    object-fit: contain;
    width: 100%;
    margin: auto;
  }

  &__name-input {
    height: 75px;
    input {
      height: 50px;
      background: white;
    }
  }

  &__content {
    z-index: 1;
    position: relative;
    @include mobileView {
      width: 86vw;
    }
  }

  &__logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__input {
    height: 50px;
    position: relative;
    input {
      height: 50px;
      background: white;
    }
  }

  &__file-upload {
    position: absolute;
    top: 24px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;

    &__input {
      margin-top: 5px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      padding-right: 15px;
      padding: 5px;
      box-shadow: 0px 0px 10px 0px rgba(201, 201, 201, 0.5411764705882353);
      width: 110px !important;

      &--move {
        position: absolute;
        top: 77px;
      }
    }
  }

  &__placeholder-wrapper {
    display: flex;
    flex-direction: column;
    width: 48%;
    @include mobileView {
      width: 85vw;
    }

    &--full {
      width: 100%;
    }

    &__additonal-header {
      display: flex;

      & > div {
        &:first-of-type {
          margin-right: 15px;
        }
      }
    }

    &__location-label {
      display: flex;
      justify-content: space-between;
    }
  }

  &__signature-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__signature {
    display: inline-block;
    width: 100%;
    position: relative;

    &__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 27px;
      border: solid 1px #cdcdcd;
      border-radius: 6px;
      padding: 15px;
      display: flex;

      &__signature {
        width: 47%;

        &:first-of-type {
          margin-top: 0px;
        }
      }
    }
  }

  &__switch-wrapper {
    display: flex;
    flex-direction: column;
    margin: 35px 0;
    align-items: flex-start;
  }

  &__additional-address-toggle {
    position: absolute;
    right: 13px;
    transform: scale(0.9);
    z-index: 1;
  }

  &__date-wrapper {
    width: 100%;
    .react-date-picker {
      width: 100%;
      &__wrapper {
        height: 50px;
      }
    }
  }

  &__valid-until-toggle {
    position: absolute;
    right: 0;
    transform: scale(0.9);
    padding-bottom: 3px;
  }

  &__email-input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    &__loader {
      width: 98%;
      position: absolute;
      bottom: -15px;
      left: 2px;

      .linear-activity {
        margin: 0;
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
