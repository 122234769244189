.obs-table {
    &__table-wrapper {
        width: 100%;
        overflow: auto;

        .ui.search {
            min-height: 35px !important;
            height: 35px !important;
        }

        .icon {
            height: 35px !important;
            padding-top: 9px !important;
        }
    }

    &__th {
      height: initial !important;
    }

    &__icon {
      width: 21px;
      height: 21px;
      margin-right: 5px;
      margin-top: 1px;
    }

    &__row {
      &--High {
        background: rgba(255, 102, 102, 0.08) !important;
        border: none;
      }

      &--Low {
        background: rgba(255, 235, 102, 0.08) !important;
        border: none;
      }

      &--Positive {
        background: rgba(255, 102, 102, 0.08) !important;
        border: none;
      }
      
      &--Reactive {
        background: rgba(255, 102, 102, 0.08) !important;
        border: none;
      }
    }

    &__select-text {
      div.text {
        font-size: 15px !important;
        font-weight: bold !important;
      }

      span.text {
        font-weight: 300 !important;
      }

      input.search {
        min-height: 35px !important;
      }
    }
}