.results-table {
    display: flex;
    flex-direction: column;

    @media (orientation:'portrait'){
        padding: 0;
    }

    .ui.multiple.dropdown>.label {
        font-size: 15px !important;
    }

    &__header {
        width: 100%;
        margin-bottom: 10px;
    }

    &__th {
        height: initial !important;
    }

    &__checkbox {
        margin: auto;
    }

    &__custom-dropdown {
        position: relative;
        &__portal {
            display: none;
            position: absolute;
            left: 0;
            top: 50px;
            background: white;
            border-radius: 10px;
            padding: 15px;
            border: solid 1px #2b2b2b;
            box-shadow: 2px 2px 12px 0px #cdcdcd;
            z-index: 9;

            &--open {
                display: initial;
            }
        }
    }

    &__displays {
        background: white;
        padding: 4px 8px;
        border-radius: 6px;
        margin-right: 8px;
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        align-items: center;
        margin-left: 10px;
        border: solid 1px #e1e1e1;
    }

    &__display {
        display: flex;
        align-items: center;

        &__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    }

    &__status-dot {
        height: 8px;
        width: 8px;
        border-radius: 10px;
        margin: auto;
    
        &--green {
          background: #5bc592;
        }
        &--red {
          background: red;
        }
        &--orange {
          background: #ff9b71;
        }
        &--corrected {
            background: #FFD443;
            border: solid 1px #5bc592;
        }
    }
    
    &__table-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;

        @media only screen and (max-width: 600px) {
            width: 100%;
            overflow: auto;
        }

        .ui.search {
            min-height: 35px !important;
            height: 35px !important;
        }

        .icon {
            height: 35px !important;
            padding-top: 9px !important;
        }
    }

    &__search {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }

        &__date-tag {
            position: absolute;
            top: -16px;
        }

        &__section {
            display: flex;
            align-items: flex-end;

            & > div:first-of-type {
                margin-right: 7px;

                @media only screen and (max-width: 600px) {
                    margin-right: 0;
                    width: 100%;
                }
            }

            @media only screen and (max-width: 600px) {
                flex-direction: column;
                align-items: flex-end;
                width: 100%;

                &:first-of-type {
                    margin-bottom: 15px;
                }

                // & > div {
                //     margin-top: 15px;
                // }
            }
        }
    }
}
.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
    position: absolute;
    top: 0;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}