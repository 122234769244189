@import '../../../../../../config';

.obs-table {
  &__row {
    height: 50px;
    min-height: 50px;

    @include mobileView {
      min-height: initial !important;
      height: initial !important;
    }

    td {
      // border: none !important;
      border-color: rgba(34, 36, 38, 0.05) !important;
    }

    &__value-status {
      display: flex;
      flex-direction: row;
      gap: 20px;

      @include mobileView {
        flex-direction: column;
      }
    }

    &__value {
      display: flex;
      flex-direction: row;
      flex-basis: 150px;
      align-items: center;

      @include mobileView {
        flex-basis: initial;
      }

      // &__text {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: center;
      //   & > div {
      //     font-weight: 600;
      //   }
      // }

      // &__icon {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
    }

    &__status {
      display: flex;
      align-items: center;
      flex-basis: 150px;
      justify-content: center;
    }

    &__range {
      width: 400px;

      @include mobileView {
        width: 100%;
      }
    }

    &__line {
      padding-bottom: 0px;
      margin-top: 30px;
      margin-bottom: -5px;
    }
  }

  &__cat-row {
    background: rgba(241, 241, 241, 0.366);
    cursor: pointer;
    min-height: 75px !important;
    height: 75px !important;
    
    @include mobileView {
      min-height: initial !important;
      height: initial !important;
    }

    td {
    border-top: 0.5px solid rgba(34, 36, 38, 0.2) !important;
    border-bottom: 0.5px solid rgba(34, 36, 38, 0.2) !important;
    }
  }
}
