@import '../../../config';

.hvo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 15px;

    @include mobileView {
        justify-content: space-between;
    }

    &__status-wrapper {
        cursor: pointer;
        // border: solid 2px grey;
        margin-right: 20px;
        align-items: center;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        line-height: 16px;
        user-select: none; 
        margin-right: 15px;
        
        @include mobileView {
            margin-right: 0;
            width: 32%;
        }

        &__name {
            margin-top: 5px;
            color: black;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    &__status {
        cursor: pointer;
        height: 48px;
        padding: 3px;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.2);
        border: solid 1px #dfdfdf;
        width: 120px;
        border-radius: 10px;
        align-items: center;
        color: white;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;

        @include mobileView {
            width: 100%;
        }

        &__count {
            margin-bottom: 3px;
            margin-top: 4px;
            font-size: 17px;
        }

        &__icon {
            width: 15px;
            height: 15px;
        }

        &--green {
            background: #01836A;
            border: solid 2px #01836A;
        }
    
        &--greenish {
            background: #00c414;
            border: solid 2px #00c414;
        }
        
        &--orange {
            background: #ffb41f;
            border: solid 2px #ffb41f;
        }

        &--yellow {
            background: #FFA51F;
            border: solid 2px #FFA51F;
        }

        &--red {
            background: #FF6464;
            border: solid 2px #FF6464;

            .hvo__status--selected {
                background: purple;
            }
        }

        &--selected {
            box-shadow: 0px 0px 2px 0px black;
            border: solid 3px white;
            padding: 2px;
        }
       
        &--total {
            background: white;
            color: rgb(89, 89, 89);
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}