@import 'config';

.quetionnaire-document {
    margin: auto;
    max-width: 1200px;
    min-width: 800px;
    position: relative;
    width: 100%;
    margin-bottom: 50px;

    @include mobileView {
        padding: 0;
        max-width: 100%;
        min-width: 100%;
    }
    
        &__public {
            @include mobileView {
                    padding: 15px;
                }
        }
    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 200px;
    }

    &__form-row {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        @include desktopView {
            flex-direction: row;
            margin-bottom: 10px;
        }

        &__select {
            .ui.selection.dropdown {
                height: 40px !important;
                max-height: 40px !important;
                min-height: 40px !important;
            }
        }

        &__field {
            margin-bottom: 10px;


            .input-error-text {
                display: none !important;
            }

            .ui.selection.select-dropdown,
            input textarea {
                // border: solid 1px #1fa97f !important;
            }

            @include mobileView {
                width: 100% !important;
            }

            @include desktopView {
                margin-bottom: 0;

                &:last-of-type {
                    margin-left: 20px;
                }
            }

            // &:last-of-type {
            //   margin-right: 0;
            // }
        }

        &__cleave-invalid {
            border: solid 1px #ff5252 !important;
        }

        &__phone {
            @include desktopView {
                width: 48%;
            }
        }
    }

    &__search {
        // margin-bottom: 4px !important;
        display: flex;
        flex-direction: column;

        &div:first-child {
            margin-bottom: 4px !important;
        }

        .icon.input {
            @include mobileView {
                width: 93% !important;
            }
        }

        &__mb {
            margin-bottom: 4px !important;
        }
    }

    &__main-wrapper {
        margin-top: 20px;
    }

    &__grid {
        border-radius: 10px;
        border: solid 1px rgba(239, 239, 239, 1);
        display: grid;
        gap: 30px 13px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-top: 15px;
        background: white;
        border-radius: 10px;
        padding: 15px;
        padding-bottom: 20px;

        @include mobileView {
            grid-template-columns: repeat(1, 1fr);
        }

        &__row {
            display: flex;
            flex-direction: row;
        }

        &__column {
            display: flex;
            flex-direction: column;
        }
    }

    &__select {
        width: 240px !important;
    }
        &__bullet-list {
            list-style-type: disc;
            margin-left: -20px;
        }
    
        &__textArea-fontsize {
            font-size: 14px;
        }

                &__additonalInfo-input {
                    align-self: flex-end;
                }
        
                &__asterisk {
                    color: red;
                }

}
.medication {
    &__wrapper {
    
            border: solid 1px #efefef;
            border-radius: 8px;
            padding: 15px;
    
            &__sub {
                background-color: #f5f2f2;
    
            }
        }
    
        &__field {
            border-bottom: solid 1px #d3d3d3;
            padding: 5px;
        }
    
        &__delete {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;
        }
    
        &__initial-add {
            border: solid 1px #efefef;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 50px 0;
    
            &:hover {
                cursor: pointer;
            }
&__invalid {
    border: solid 1px #f85757 !important;
}
        }
    
                &__add-wrapper {
                    padding: 15px;
        
                    &__add {
                        &>div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 5px;
                            width: 100% !important;
                        }
                        }
                        }
    &__additionalField {
        &__wrapper {

            margin-top: 15px;
            margin-bottom: 15px;
            padding: 0px 5px 0px 20px;

        }

        &__item {
            display: flex;
            justify-content: space-between;
            background-color: #f5f2f2;
            padding: 5px;
            border: 1px solid #d8d8d88a;
            border-radius: 5px;
            margin-top: 5px;
            margin-bottom: 15px;
        }

        &__remove {
            font-size: 24px;
                font-weight: 400;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
.success-page {
    &__wrapper {
        height: 70vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__main {
        margin-top: 50px;
        padding: 40px;
        border-radius: 8px;
        border: 2px solid #d8d8d88a;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 600px;
            text-align: center;
    }
}