.h-scroller {
  &__arrow {
    height: 100%;
    width: 37px;
    background: rgba(255, 255, 255, 0.471);
    border: solid 1px rgb(211, 211, 211);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: white;
    }

    &--left {
      margin-right: 10px;
      border-radius: 10px 5px 5px 10px;
    }
    &--right {
      margin-left: 10px;
      border-radius: 5px 10px 10px 5px;
    }

    &__img {
      height: 20px;
      width: 20px;
    }
  }
}

.react-horizontal-scrolling-menu--scroll-container {
  gap: 10px;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  &::-webkit-scrollbar {
      display: none;
  }
}