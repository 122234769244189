.covid-vac {
  display: flex;
  flex-direction: column;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  font-size: 18px;
  line-height: 30px;
  z-index: 0;
  width: 21cm;
  height: 29.7cm;
  box-shadow: 0px 0px 2px 0px #9e9e9e;
  border-radius: 10px;
  background: white;
  font-family: sans-serif;
  padding: 8mm 10mm;
  margin-bottom: 5px;

  &__header-row {
    display: flex;
    justify-content: center;
  }

  &__header {
    font-weight: bold;
    font-size: 25px;
    margin-top: 20px;
  }

  &__sub-header {
    background: #e6f8e9;
    color: #1b8b6e;
    width: 120px;
    border-radius: 2px;
    text-align: center;
    font-weight: bold;
    margin: auto;
    margin-top: 10px;
    padding: 2px 5px;
  }

  &__user-wrapper {
    padding: 10px 15px;
    padding-left: 40px;
    margin-top: 27px;
    // background: #f5f7fc;
    border: solid 1px #bdbdbd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    background: #fafafd;

    &__data-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
    }
  }

  &__data-wrapper {
    width: 100%;
  }

  &__qr-code {
    height: 146px;
    width: 146px;
    min-width: 146px;
    border-radius: 1px;

    &__img {
      height: 100%;
      width: 100%;
      border-radius: 1px;
      object-fit: contain;
    }
  }

  &__row {
    margin-top: 13px;
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__data-wrapper {
    &__label {
      font-size: 16px;
      color: #737373;
      font-weight: 300;
      height: 25px;
    }

    &__data {
      font-size: 19px;
    }
  }

  &__vac-content {
    width: 100%;
    // background: #f5f7fc;
    border: solid 1px #bdbdbd;
    border-radius: 4px;
    margin-top: 25px;
    padding: 20px 15px;
    padding-top: 10px;
    display: flex;
    background: #fafafd;

    &__section {
      width: 100%;
    }

    &__additional-info {
      padding: 10px 25px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }

    &__sub-section {
      display: flex;
      background: white;
      border-radius: 1px;
      margin-top: 10px;
      justify-content: space-between;
      border: solid 1px #c9c9c9;
    }
  }

  &__s-data-wrapper {
    margin-left: 25px;
    margin-top: 6px;
    line-height: 27px;

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    &__label {
      font-size: 16px;
      color: #737373;
      height: 24px;
      font-weight: 300;
    }

    &__data {
      font-size: 19px;
    }
  }

  &__dose {
    padding: 10px 0;
    border: none;
    border-left: solid 1px #c9c9c9;
    width: 70px;
    min-width: 70px;
    position: relative;

    &__content {
      transform: rotate(-90deg);
      display: flex;
      position: absolute;
      bottom: 45px;
      left: 8px;
      font-size: 22px;
      margin-left: -11px;
    }

    &__number {
      font-size: 29px;
    }

    &__title {
      margin-right: 10px;

      &--booster {
        position: absolute;
        left: -13px;
        top: 22px;
        font-size: 24px;
      }
    }
  }

  &__info-text {
    margin-top: 20px;
    padding: 0 30px;

    &__header {
      font-weight: bold;
      color: #3e4063;
    }

    &__icon {
      margin-right: 8px;
      margin-top: 1px;
    }

    &__info {
      font-size: 15px;
      margin: 10px 0;
      color: #3e4063;
      line-height: 18px;
      display: flex;
    }

    &__footer-text {
      margin-top: 17px;
      color: #657090;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
