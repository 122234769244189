@import 'config';

.certificate {
  margin: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin: auto;
  position: relative;
  z-index: 0;
  width: 21cm;
  height: 30.7cm;
  box-shadow: 0px 0px 2px 0px #9e9e9e;
  border-radius: 10px !important;
  background: white;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__pdf {
    width: 100%;
    height: 650px;
    margin-top: 40px;

    &__text {
      margin-bottom: 10px;
    }

    iframe {
      height: 100%;
      width: 100%;
      border-radius: 10px;
    }
  }

  &__watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }

  &__footer {
    position: absolute;
    bottom: 20px;
    right: 40px;

    @media only screen and (max-width: 768px) {
      bottom: 15px;
      right: 14px;
    }
  }

  &__content {
    padding: 15mm;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__holder {
      margin: 10px 0;
    }
    &__name {
      margin-top: 30px;
      margin-bottom: 25px;
    }

    &__header-wrapper {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @media only screen and (max-width: 768px) {
      padding: 0;
    }

    &__revoked {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: 100% 20px;

      background: repeating-linear-gradient(
        -55deg,
        #fff0,
        #fff0 10px,
        #dadada2e 10px,
        #dadada2e 20px
      );

      div {
        width: 100%;
        height: 30px;
        background: red;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 16px;
        border-radius: 4px;
      }
    }

    &__logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      &__logo {
        max-width: 40%;
        max-height: 90px;

        @media only screen and (max-width: 768px) {
          max-width: 60%;
          max-height: 50px;
        }
      }
    }

    &__row {
      margin: 15px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__location-row {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 768px) {
        margin-top: 0;
        flex-direction: column;
      }
    }

    &__data-wrapper {
      display: flex;
      flex-direction: column;

      &__label {
        margin-bottom: 10px;
        color: rgb(83, 83, 83);
        font-size: 15px;
        line-height: 17px;
        font-weight: 300;
        text-align: center;
      }

      &__data {
        color: black;
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
        text-align: center;

        &--small {
          width: 80%;

          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      &__important-data {
        background: rgba(248, 248, 251, 0.9);
        border-radius: 10px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 8px;
      }

      &--half {
        width: 50%;
        justify-content: center;
        display: flex;
      }

      &--location {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }

      &__qr-code {
        width: 110px;
        height: 110px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        position: absolute;
        top: 10px;
        right: 20px;

        @media only screen and (max-width: 768px) {
          width: 100px;
          height: 100px;
          margin: auto;
          margin-top: 5px;
        }
      }

      &__qr-code--solo {
        width: 110px;
        height: 110px;
        margin-bottom: 40px;
        border-radius: 10px;
        margin: auto;
        position: absolute;
        top: 10px;
        right: 20px;

        @media only screen and (max-width: 768px) {
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
        }
      }

      &__signature-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        &__signature {
          max-width: 70%;
          height: 80px;
          margin: auto;

          @media only screen and (max-width: 768px) {
            max-width: 95%;
          }
        }

        &__signature-footer {
          width: 70%;
          border-top: solid 1px black;
          text-align: center;

          @media only screen and (max-width: 768px) {
            width: 95%;
          }
        }
      }

      &__location-image {
        max-width: 60%;
        max-height: 70px;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 5px;
        object-fit: contain;

        @media only screen and (max-width: 768px) {
          max-width: 50%;
          max-height: 40px;
        }
      }
    }
  }
}

.content {
  @include mobileView {
    // width: max-content !important;
  }
}
