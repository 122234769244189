@import 'config.scss';

.pat-emergency-card {
    height: 202px;
    width: 327px;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.16);
    background: none;
    border-radius: 20px;

    @include mobileView {
        margin: auto;
        margin-bottom: 30px;
    }

    &__background {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
        border-radius: 20px;
    }
    
    &__details {
        position: relative;
        z-index: 9;
        padding: 43px 25px;
        padding-bottom: 0;
        border-radius: 20px;

        &__name {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &__row {
            display: flex;
            margin-bottom: 5px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}