.dual-check {
  &__label {
    font-family: 'IBM plex sans', sans-serif;
    color: #313131;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 6px;
    font-size: 17px;
  }

  &__checkboxes {
  }

  &__checkbox-label {
    font-size: 15 !important;
    font-weight: 300 !important;
    margin-bottom: 15px;
  }
}
