.vf {
    height: 45px;
    width: fit-content;
    display: flex;
    background: #f1f2f4;
    padding: 10px 20px;
    border-radius: 12px;
    padding: 1px;
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 25px;
    overflow: auto;
    width: fit-content;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border: solid 1px #a5a5ff00;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item-active {
        display: flex;
        align-items: center;
        border-radius: 7px;
        padding: 10px 20px;
        width: fit-content;
        font-size: 15px;
        line-height: 19px;
        font-weight: 500;
        text-transform: capitalize;
        background: #fbfbfb;
        border: solid 1px #a5a5ff;
        color: #3862ff;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        //   color: #5076ff;
        background: none;
        border-radius: 11px;
        padding: 10px 20px;
        width: fit-content;
        text-transform: capitalize;
        white-space: nowrap;
        border: solid 1px #a5a5ff00;
        
        &:hover {
            cursor: pointer;
        }
    }

    &__loader {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }
}