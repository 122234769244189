.sing-pdt {
  display: flex;
  flex-direction: column;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  font-size: 15px;
  line-height: 30px;
  z-index: 0;
  width: 21cm;
  height: 29.7cm;
  box-shadow: 0px 0px 2px 0px #9e9e9e;
  border-radius: 10px;
  background: white;
  font-family: 'IBM plex sans', sans-serif;
  padding: 11mm 20mm;

  &__address-wrapper {
    position: absolute;
    bottom: 30px;
    margin-left: -75px;
    width: 100%;
  }

  &__address {
    padding: 0;
    margin: 0;
    font-size: 14px;
    text-align: center;
    color: #1b1b1b;
    font-style: italic;
  }

  &__header {
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 21px;
    line-height: 30px;
    font-family: 'IBM plex sans', sans-serif;
    font-weight: 600;
    min-height: initial !important;
  }
  &__header-bottom {
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 23px;
    font-size: 21px;
    font-family: 'IBM plex sans', sans-serif;
    font-weight: 600;
  }

  &__stamp {
    object-fit: contain;
    width: 100%;
    max-width: 320px;
    max-height: 90px;
  }

  &__ch-stamp {
    object-fit: contain;
    height: 100px;
    bottom: 245px;
    position: absolute;
    right: 70px;
  }

  &__an-stamp {
    object-fit: contain;
    width: 230px;
    height: 130px;
    border-radius: 5px;
    position: absolute;
    bottom: 350px;
    right: 70px;
  }

  &__logo-wrapper {
    display: block;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &__logo {
      max-height: 100px;
      height: auto;
      object-fit: contain;
      border-radius: 5px;
      max-width: 100%;
    }
  }

  &__row {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-basis: 30%;
    -webkit-box-pack: initial;
    justify-content: initial;
    font-size: 15px;

    &__label {
      font-weight: 600;
      flex-basis: 50%;
      font-family: 'IBM plex sans', sans-serif;
    }

    &__data {
      flex-basis: 50%;
      font-family: 'IBM plex sans', sans-serif;
      line-height: 18px;
      display: flex;
      align-items: center;
    }
  }

  &__info-text {
    text-align: justify;
    font-family: 'IBM plex sans', sans-serif;
    margin-top: 25px;
    text-align: left;

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
      font-family: 'IBM plex sans', sans-serif;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__result {
      font-weight: bold;

      &--big-red {
        font-size: 20px;
        color: red;
        text-transform: uppercase;
      }
    }
  }

  &__bottom-names {
    margin-top: 10px;
    text-align: left;
    margin-bottom: 10px;
    font-family: 'IBM plex sans', sans-serif;

    &--mt-3 {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }

  &__qr-code-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 0;
    padding-top: 0;

    &__text {
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      font-family: 'IBM plex sans', sans-serif;
      margin-top: 2px;
    }

    &__sub-text {
      font-size: 12px;
      line-height: 16px;
      margin-right: 50px;
      font-weight: 500;
      font-weight: 300;
      font-family: 'IBM plex sans', sans-serif;
    }

    &__qr {
      &__img {
        height: 150px;
        width: 150px;
        margin-bottom: -5px;
        border-radius: 2px;
        border: solid 1px rgb(190, 190, 190);

        &--small {
          height: 130px;
          width: 130px;
        }

        &--sample {
          padding: 10px;
        }
      }
    }
  }

  &__qr-codez {
    margin: auto;
  }

  &__qr-code {
    margin: auto;
    border-radius: 2px;
    border: solid 1px rgb(190, 190, 190);
    display: inline-flex;

    &--top {
      position: absolute;
      top: 40px;
      right: 40px;
    }

    &__img {
      height: 170px;
      width: 170px;

      &--small {
        height: 130px;
        width: 130px;
      }

      @media only screen and (max-width: 768px) {
        height: 140px;
        width: 140px;
      }
    }
  }

  &__signature-wrapper {
    margin-top: 10px;
    min-height: 75px;
    display: flex;
    justify-content: space-between;

    &__sig {
      object-fit: contain;
      object-position: left;
      height: 75px;
      width: 200px;
    }
  }

  &__watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
