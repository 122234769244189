@import '../../../../../config';

.p-suc-pg {
    position: relative;
    margin: auto;
    padding: 20px;
    background: #f1f3f5a7;
    padding-top: 40px;
    position: fixed;
    height: 100%;
    width: 100%;

    @include mobileView {
        padding-bottom: 60px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3px;

    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        text-align: center;
        height: 100%;
        max-width: 400px;

        &__text {
            width: 280px;
        }

        &__button {
            margin-top: 40px;
        }
    }
}
