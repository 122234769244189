.r-pdf-um {
  &__step {
    display: flex;
    justify-content: center;
    gap: 5px;

    &__circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #64697bcc;
      &:hover{
        cursor: pointer;
        border: 1px solid #3357d8dd;
      }

      &.active {
        border-radius: 50%;
        background: #3357d8dd;
      }
    }
  }

  &__warning-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__warning {
    display: flex;
    align-items: start;
    margin-bottom: 5px;

    &__icon {
      height: 15px;
      width: 15px;
      margin-top: 4px;
      margin-right: 7px;
    }

    &__text {

    }
  }
  &__content {
    &__toggles {
      display: flex;
      flex-direction: column;
    }

    &__toggle {
      cursor: pointer;
      display: flex;
      margin-bottom: 15px;

      &__text {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__info-wrapper {
    border: solid 1px #5076ff61;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    background: #f0f0f075;

    &__header {
      display: flex;

      &__icon {
        margin-top: 4px;
        margin-right: 5px;
      }
    }
  }

  &__button {
    margin-top: 30px;
  }

  &__pdf-preview {
    display: none;
  }
  &__report-details {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.uploadReport {
  &__dropzone {
      min-height: 145px !important;
      height: fit-content;
      padding: 20px;
      border-style: dashed !important;
      margin-bottom: 10px;
      box-shadow: none !important;
      border-color: rgb(80, 118, 255) !important;
  }
}
