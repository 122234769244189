.smart-rep-c {
    &__content {
        display: flex;

        &__icon {
            height: 35px;
            width: 35px;
            object-fit: contain;
            margin-right: 20px;
        }
        &__text {
            font-weight: 400 !important;
            line-height: 26px !important;
            letter-spacing: 0em !important;
        }
    }

    &__settings {
        border-radius: 15px;
        border: solid 1px rgb(218, 218, 218);
        padding: 10px 15px;
        margin-top: 15px;

        &__row {
            margin: 5px 0
        }
    }

    &__button {
        margin-top: 25px;
    }
}

.smart-rep-wrapper {
    &__content {
        padding: 10px;
        padding-bottom: 5px;
    }
    &__header {
        display: flex;
        align-items: center;
        border-bottom: solid 1px rgba(239, 239, 239, 1);
        padding-bottom: 15px;
        justify-content: space-between;
        margin: auto;
        margin-bottom: 20px;
        width: 100%;

        &--pdf {
            width: 21cm;
        }

        &__section {
            display: flex;
            align-items: center; 
        }
        &__consent__logo {
            height: 2.5rem;
            object-fit: contain;
            margin-right: 7px;
        }
        &__logo {
            height: 3.45rem;
            object-fit: contain;
            margin-right: 7px;
        }
        &__text {
            border-left: solid 1px rgba(128, 128, 128, 1);
            padding-left: 7px;
            display: flex;
            align-items: baseline;
            padding-top: 3px;
            height: 30px;
        }
    }
}