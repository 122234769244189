.result-details {
  &__info {
    border: solid 1px #5076ff;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 20px 0px rgba(201, 201, 201, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    margin-bottom: 50px;

    &__text {
      display: flex;
      align-items: center;

      & > svg {
        margin-right: 15px;
        height: 30px;
        width: 30px;
      }
    }
  }

  &__data {
    margin-top: 25px;
    border-bottom: solid 1px rgb(238, 238, 238);

    &__data-wrapper {
      padding: 0 5px;
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 15px;
      padding-bottom: 25px;
    }

    &:last-of-type {
      border-bottom: none;

      .result-details__data__data-wrapper {
        padding-bottom: 0;
      }
    }
  }

  &__pdf-wrapper {
    width: 95%;
    margin: 20px;
    position: relative;
    max-width: 1400px;

    &__pdf {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }
}
