.risk-fact {
    margin-top: 10px;
    padding: 0 10px;
    padding-bottom: 10px;

    &__status {
        padding: 3px 12px;
        border-radius: 50px;
        background: linear-gradient(145deg, #efefef, #ffffff);
        box-shadow: 5px 5px 10px #efefef,
            -5px -5px 10px #ffffff;
    }

    &__header {
        gap: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        flex-wrap: wrap;

        & > div:first-of-type  {
            margin-right: 5px;
        }
    }

    &__values {
        margin-bottom: 10px;
    }

    &__value {
        margin-bottom: 5px;
    }

    &__icon {
        height: 22px;
        width: 26px;
        margin-right: 10px;
    }
    
    &__stat-icon {
        height: 20px;
        width: 20px;
        margin-right: 5px;
    }

    &__hvl {
        margin-top: 35px;
    }

    &__reason {
        padding: 0 10px;
        font-size: 13px;
        line-height: 20px;

        p:first-child {
            display: none;
        }

        p {
            font-size: 13px;
        }

        ul, ol {
            padding-left: 25px;
        }
    }

    &__suggestion {
        margin-top: 20px;

        &__header {
            display: flex;
        }
    }
}