@import '@draft-js-plugins/static-toolbar/lib/plugin.css';

.rich-text {
  &__wrapper {

    cursor: text;
    // div {
    //   text-align: center;
    // }
  }

  &__toolbar {
    border-radius: 10px;
    &.rdw-editor-toolbar {
      border-radius: 10px;
      min-height: auto !important;
      width: auto !important;
      border: none !important;
    }

    .rdw-dropdown-optionwrapper {
      position: absolute;
      width: 120px;
    }
  }

  .public-DraftEditor-content {
    height: initial !important;
  }

  .DraftEditor-editorContainer {
    background: white;
    border-radius: 10px;
    border: solid 1px #cdcdcd;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-size: 17px;
    color: #1b1b1b;
    font-weight: 200;
    min-height: 50px;
    margin-bottom: 5px !important;
    height: 150px;
    overflow: auto;

    &:disabled {
      background: repeating-linear-gradient(
        45deg,
        #fbfbfb,
        #fbfbfb 10px,
        white 10px,
        white 20px
      );
    }

    ::placeholder {
      color: #1b1b1b;
      opacity: 0.6;
      transition: all 0.3s ease;
      font-weight: 200;
      font-size: 16px;
    }

    &:focus-within {
      outline: none;
      border: solid 1px #5076ff;

      ::placeholder {
        color: #cdcdcd;
      }
    }
  }

  &--viewer {
    .DraftEditor-editorContainer {
      background: none;
      border: none;
      padding: 0;
    }

    .DraftEditor-editorContainer:focus-within {
      border: none !important;
    }
  }
}
