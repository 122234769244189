//     &__header {
//         font-size: 16px;
//         font-weight: 500;
//         margin-bottom: 10px;
//     }

//     &__section {
//         border-top: solid 1px rgba(239, 239, 239, 1);
//         padding-bottom: 20px;
//         padding-top: 20px;

//         &:first-of-type {
//             border-top: none;
//             padding-top: 0;
//         }
//     }

//     &__notes {
//         border: solid 1px #EFEFEF;
//         border-radius: 10px;
//         width: 100%;
//         padding: 15px 13px;

//         &__note-wrapper {
//             padding: 0 5px;
//         }

//         &__note {
//             width: 100%;
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 21px;
//             white-space: pre;
//             word-wrap: break-word;
//         }
//     }

//     &__pat {
//         &__name {
//             font-weight: bold;
//             font-size: 16px;
//             margin-bottom: 10px;
//         }
//         &__details {
//             display: grid;
//             width: 100%;
//             gap: 10px;
//             grid-template-columns: repeat(2, 1fr);
//             flex-wrap: wrap;
//             width: 100%;
//             justify-content: space-between;
//             align-items: center;

//             &__det {
//                 text-transform: capitalize;
//                 &__label {
//                     color:rgba(201, 201, 201, 1);
//                     margin-right: 5px;
//                 }
//             }
//         }
//     }

//     &__cat {
//         &__header {
//             font-weight: 500;
//             line-height: 21px;
//             margin-bottom: 3px;
//             font-size: 16px;
//         }

//         &__text {
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 21px;
//         }
//     }
// }

h2 {
  font-size: 1.25rem;
  font-weight: 400;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

td {
  padding: 4px 0;
}

th {
  text-align: left;
  padding: 0.6rem 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

#pspdfkit-header {
  width: 100%;
  padding: 2rem 3.5rem;
  padding-bottom: 0.65rem;
}

#pspdfkit-footer {
  width: 100%;
  padding: 2.5rem 3.5rem;
  padding-top: 0.65rem;
  display: flex;
  justify-content: right;
  color: rgba(128, 128, 128, 1);
}

.footer {
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
  width: 100%;
}

.footer__riverr__text {
  display: flex;
  align-items: baseline;
  margin-top: 20px;
}

.footer__riverr__name {
  color: #2b2b2b;
  font-size: 1.1rem;
  line-height: 1.2rem;
  font-weight: 500;
}
.footer__riverr__powered {
  font-family: 'IBM plex sans', sans-serif;
  color: #727272;
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
  margin-left: 0.2rem;
}

.footer__name {
  color: black;
}

.footer__gen-date {
  color: black;
}

.footer__gen {
  color: rgba(128, 128, 128, 1);
}

.header__wrapper {
  display: flex;
  align-items: center;
}

.header__logo {
  height: 3.45rem;
  object-fit: contain;
  margin-right: 0.6rem;
}

.smart-rep {
  margin: auto;
  width: 21cm;
  margin: auto;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.05rem;
  color: #000000;
  margin: 0 auto;
  position: relative;
  font-family: 'IBM plex sans', sans-serif !important;

  .rich-text .DraftEditor-editorContainer {
    font-weight: 400 !important;
    font-size: 0.8rem !important;
    line-height: 1.05rem !important;
    min-height: initial;
  }

  .bio-sec {
    padding-top: 1rem;
    margin-bottom: 0.1rem;
    width: 100%;
    // border-top: solid 0.06rem rgba(201, 201, 201, 0.3);
    // border-bottom: solid 0.06rem rgba(201, 201, 201, 0.3);
  }
  .bio-sec__visual-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    break-inside: avoid;
  }
  .bio-sec__visual {
    height: 100%;
    margin-bottom: 0.5rem;
  }
  .bio-sec__legend {
    width: 100%;
    padding: 0.5rem;
    padding-bottom: 0.2rem;
    padding-top: 0.4rem;
    margin-left: 2rem;
  }
  .bio-sec__legend__header {
    font-weight: 600;
    font-size: 0.85rem;
  }
  .bio-sec__legend__row {
    display: flex;
    align-items: center;
    margin: 0.3rem 0;
    font-size: 0.65rem;
  }
  .bio-sec__legend__row__icon {
    margin-right: 4px;
    height: 1rem;
    width: 1rem;
    object-fit: contain;
  }
  .bio-sec__legend__row__range {
    font-weight: 500;
    font-size: 0.7rem;
    margin-left: 0.6rem;
    margin-right: 3px;
  }
  .bio-sec__legend__row__status {
    font-size: 0.72rem;
    border-bottom: solid 2px #5076ff;
    padding: 1px;
    border-radius: 2px;
  }

  padding: 1px;
  border-radius: 3px;

  .bio-sec__legend__row__status--green {
    border-bottom: solid 2px #00c48c;
    padding: 1px;
    border-radius: 2px;
  }
  .bio-sec__legend__row__status--greenish {
    border-bottom: solid 2px #0e8301;
    padding: 1px;
    border-radius: 2px;
  }
  .bio-sec__legend__row__status--greenish2 {
    border-bottom: solid 2px #12b001;
    padding: 1px;
    border-radius: 2px;
  }
  .bio-sec__legend__row__status--yellow {
    border-bottom: solid 2px #ffd443;
    padding: 1px;
    border-radius: 2px;
  }
  .bio-sec__legend__row__status--orange {
    border-bottom: solid 2px #ffb41f;
    padding: 1px;
    border-radius: 2px;
  }
  .bio-sec__legend__row__status--red {
    border-bottom: solid 2px #ff6666;
    padding: 1px;
    border-radius: 2px;
  }
  .bio-sec__legend__row__status--red2 {
    border-bottom: solid 2px #ff3a3a;
    padding: 1px;
    border-radius: 2px;
  }
  .bio-sec__info {
    /* border: solid 0.06rem #efefef; */
    border-radius: 0.6rem;
    padding: 0.55rem;
    padding-top: 0.3rem;
    display: flex;
    break-inside: avoid;
    // margin-top: 0.3rem;
  }
  .bio-sec__info--High {
    // background: #fff3f3;
  }
  .bio-sec__info--Low {
    // background: #fffef3;
  }
  .bio-sec__info__header {
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.1rem;
  }
  .bio-sec__info__sub-header {
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0.1rem;
    margin-top: 0.5rem;
  }
  .bio-sec__info__text {
    font-weight: 400;
  }
  .bio-sec__info__icon {
    height: 1.1rem;
    width: 1.2rem;
    object-fit: contain;
    margin-right: 0.55rem;
    object-fit: contain;
  }

  .mb-3 {
    margin-bottom: 0.6rem;
  }
  .mb-1 {
    margin-bottom: 2.5px;
  }
  .mt-1 {
    margin-top: 2.5px;
  }

  .lr-dn__icon {
    height: 1.1rem;
    width: 1.3rem;
    margin-right: 0.3rem;
  }

  .hvr {
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    border-radius: 0.6rem;
    border: none;
    padding: 0.8rem 0.7rem;
    padding-bottom: 1.4rem;
    border: solid 0.5px rgb(231, 231, 231);
  }
  .hvr__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.6rem;
  }
  .hvr__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100%;
    // border-bottom: solid 0.06rem rgba(201, 201, 201, 0.3);
    border-bottom: none;
    padding-bottom: 0rem;
  }
  .hvr__text-wrapper--no-line {
    min-width: initial;
    max-width: initial;
    width: 100%;
  }
  .hvr__text-wrapper__name-wrapper {
    display: flex;
    align-items: center;
  }
  .hvr__text-wrapper__name {
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-weight: 600;
    margin-right: 3px;
  }
  .hvr__text-wrapper__icon {
    height: 1.1rem;
    width: 1.1rem;
  }
  .hvr__text-wrapper__status {
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 0.4rem;
    padding: 1px 10px;
    border: solid 1px;
    border-radius: 4px;
  }

  .hvr__text-wrapper__status--Normal {
    color: #0e8301;
  }
  .hvr__text-wrapper__status--Low {
    color: #ffcd28;
  }
  .hvr__text-wrapper__status--High {
    color: #ff6666;
  }
  .hvr__text-wrapper__status--green {
    color: #0e8301;
  }
  .hvr__text-wrapper__status--yellow {
    color: #ffcd28;
  }
  .hvr__text-wrapper__status--red {
    color: #ff6666;
  }
  .hvr__text-wrapper__status--greenish {
    color: #0e8301;
  }
  .hvr__text-wrapper__status--greenish2 {
    color: #0e8301;
  }
  .hvr__text-wrapper__status--red2 {
    color: #ff3a3a;
  }

  .hvr__text-wrapper__value {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1rem;
  }

  .hvr__text-wrapper__unit-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: nowrap;
    margin-right: 1rem;
    margin-top: 0.2rem;
    min-width: 70px;
    max-width: 90px;
  }
  .hvr__text-wrapper__unit-wrapper__icon {
    display: flex;
    align-items: center;
  }
  .hvr__text-wrapper__unit {
    color: #32363cd7;
    font-size: 0.65rem;
    line-height: 0.7rem;
    font-weight: 600;
  }
  .hvr__line-wrapper {
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
  }
  .hvr__value {
    position: absolute;
    left: 0;
    bottom: -4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
  }
  .hvr__value__square {
    padding: 2px 5px;
    display: inline-block;
    border-radius: 4px;
    margin-bottom: 0.3rem;
    font-weight: bold;
    background: white;
    color: #32363cd7;
    border: solid 0.06rem #00c48c;
  }
  .hvr__value__square--Normal {
    border-color: #00c48c;
  }
  .hvr__value__square--green {
    border-color: #00c48c;
  }
  .hvr__value__square--Low {
    border-color: #ffd443;
  }
  .hvr__value__square--yellow {
    border-color: #ffd443;
  }
  .hvr__value__square--High {
    border-color: #ff6666;
  }
  .hvr__value__square--red {
    border-color: #ff6666;
  }
  .hvr__value__line {
    position: relative;
    transform: rotate(90deg);
    width: 0.7rem;
    border: solid 0.03rem #00c48c;
  }
  .hvr__value__line--Normal {
    border-color: #00c48c;
  }
  .hvr__value__line--Low {
    border-color: #ffd443;
  }
  .hvr__value__line--High {
    border-color: #ff6666;
  }
  .hvr__value__line--green {
    border-color: #00c48c;
  }
  .hvr__value__line--yellow {
    border-color: #ffd443;
  }
  .hvr__value__line--red {
    border-color: #ff6666;
  }
  .hvr__value__line--greenish {
    border-color: #0e8301;
  }
  .hvr__value__line--greenish2 {
    border-color: #12b001;
  }
  .hvr__value__line--red2 {
    border-color: #ff3a3a;
  }
  .hvr__value__dot {
    position: relative;
    height: 0.6rem;
    width: 0.6rem;
    border: solid 0.08rem #474747;
    border-radius: 0.6rem;
    margin-bottom: 0.15rem;
    z-index: 9;
  }
  .hvr__value__dot--Normal {
    background: #00c48c;
  }
  .hvr__value__dot--Low {
    background: #ffd443;
  }
  .hvr__value__dot--High {
    background: #ff6666;
  }
  .hvr__value__dot--green {
    background: #00c48c;
  }
  .hvr__value__dot--yellow {
    background: #ffd443;
  }
  .hvr__value__dot--red {
    background: #ff6666;
  }
  .hvr__value__dot--greenish {
    background: #0e8301;
  }
  .hvr__value__dot--greenish2 {
    background: #12b001;
  }
  .hvr__value__dot--red2 {
    background: #ff3a3a;
  }
  .hvr__line {
    position: relative;
    width: 100%;
    height: 5.5px;
    max-height: 5.5px;
    min-height: 5.5px;
    display: flex;
    width: 370px;
    min-width: 370px;
    max-width: 370px;
    margin-top: 0.45rem;
  }
  .hvr__line__section-wrapper {
    display: flex;
    width: 100%;
  }
  .hvr__line__section {
    position: relative;
    flex: 1;
  }
  .hvr__line__section:first-of-type {
    border-radius: 0.6rem 0 0 0.6rem;
  }
  .hvr__line__section:last-of-type {
    border-radius: 0 0.6rem 0.6rem 0;
  }
  .hvr__line__section__text {
    position: absolute;
    bottom: -23px;
    right: -1.3rem;
    width: 40px;
    text-align: center;
    font-weight: 400;
    font-size: 0.65rem;
  }
  .hvr__line__section__name {
    position: absolute;
    width: 100%;
    top: 1px;
    text-align: center;
    /* margin-top: 0.5rem; */
    font-size: 0.6rem;
    color: #1b1d20e4;
  }
  .hvr__line__section--Normal {
    background: #00c48c;
    border: solid 0.06rem #00c48c !important;
  }
  .hvr__line__section--Low {
    background: #ffd443;
    border: solid 0.06rem #ffd443 !important;
  }
  .hvr__line__section--High {
    background: #ff6666;
    border: solid 0.06rem #ff6666 !important;
  }
  .hvr__line__section--green {
    background: #00c48c;
    border: solid 0.06rem #00c48c !important;
  }
  .hvr__line__section--yellow {
    background: #ffd443;
    border: solid 0.06rem #ffd443 !important;
  }
  .hvr__line__section--orange {
    background: #ffb41f;
    border: solid 0.06rem #ffb41f !important;
  }
  .hvr__line__section--red {
    background: #ff6666;
    border: solid 0.06rem #ff6666 !important;
  }
  .hvr__line__section--greenish {
    background: #0e8301;
    border: solid 0.06rem #0e8301 !important;
  }
  .hvr__line__section--greenish2 {
    background: #12b001;
    border: solid 0.06rem #12b001 !important;
  }
  .hvr__line__section--red2 {
    background: #ff3a3a;
    border: solid 0.06rem #ff3a3a !important;
  }
  .hvr--High {
    border: solid 0.5px #ff6666;
    padding: 1rem;
    padding-bottom: 1.4rem;
  }
  .hvr--Low {
    border: solid 0.5px #ffd443;
    padding: 1rem;
    padding-bottom: 1.4rem;
  }
  ol {
    padding-left: 15px;
  }
}
.smart-rep__appendix-wrapper {
  margin-top: 180px;
}
.smart-rep__appendix__title-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}
.smart-rep__appendix__header {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.smart-rep__header {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.smart-rep__sub-header {
  font-size: 1.1rem;
  font-weight: 610;
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}
.smart-rep__sub-text {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.smart-rep__section {
  border-top: solid 0.06rem #d6d6d6;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  width: 100%;
}
.smart-rep__section:first-of-type {
  border-top: none;
  padding-top: 0;
}
.smart-rep__vitals {
  padding: 0.3rem 0px;
  padding-bottom: 0;
}
.smart-rep__vitals__section {
  width: 100%;
}
.smart-rep__vitals__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 45px;
  padding: 20px;
  border: 1px solid #efefef;
  border-radius: 10px;
}
.smart-rep__vitals__item {
  display: flex;
  flex-direction: column;
  color: #808080;
}
.smart-rep__vitals__item__details {
  display: flex;
  gap: 7px;
  font-size: 0.8rem;
  padding-bottom: 5px;
  align-items: center;
  height: 50px;
}
.smart-rep__vitals__item--va {
  display: flex;
  flex-direction: column;
  color: #808080;
  height: 100%;
  justify-content: flex-end;
}
.smart-rep__vitals__item__details--va {
  display: flex;
  gap: 7px;
  font-size: 0.8rem;
  padding-bottom: 5px;
  align-items: center;
}
.smart-rep__vitals__item__details__multi-value-wrapper {
  display: flex;
  align-items: center;
}
.smart-rep__vitals__item__details__multi-value {
  display: flex;
  flex-direction: column;
}
.smart-rep__vitals__item__details__value-multi {
  font-weight: bold;
  font-size: 0.95rem;
  color: black;
  display: block;
  width: 25px;
  text-align: right;
}
.smart-rep__vitals__item__details__value__type-multi {
  font-size: 0.75rem;
  font-weight: normal;
  padding: 0 5px;
  color: black;
}
.smart-rep__vitals__item__details__value {
  font-weight: bold;
  font-size: 0.95rem;
  color: black;
  display: block;
}
.smart-rep__vitals__item__details__value__type {
  font-size: 0.75rem;
  font-weight: normal;
  padding: 0 5px;
  color: black;
}
.smart-rep__vitals__item__details__status {
  display: flex;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 10px;
  text-transform: capitalize;
}
.smart-rep__vitals__item__details__status--va {
  display: flex;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 1px 10px;
  border: 1px solid;
  border-radius: 10px;
  text-transform: capitalize;
}
.smart-rep__vitals__item__info {
  border-top: 1px solid #efefef;
  padding-top: 5px;
  display: flex;
  align-items: center;
}
.smart-rep__vitals__item__info__icon {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.3rem;
  object-fit: contain;
}
.smart-rep__vitals__item__info__date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}
.smart-rep__notes {
  /* border: solid 0.06rem #EFEFEF; */
  /* border-radius: 0.6rem; */
  padding: 0.3rem 0px;
  padding-bottom: 0;
}
.smart-rep__notes__note-wrapper {
  padding: 0 0.3rem;
}
.smart-rep__notes__note {
  width: 100%;
  font-weight: 400;
}

.smart-rep__notes__other-note-wrapper {
  padding: 0 0.3rem;
  margin-bottom: 20px;
}
.smart-rep__notes__note-wrapper__header {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.smart-rep__notes__note-wrapper__abnormal {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.smart-rep__notes__note-wrapper__abnormal__label {
  font-size: 0.8rem;
  font-weight: 600;
}
.smart-rep__notes__note-wrapper__abnormal__value--red {
  display: flex;
  justify-content: center;
  width: 40px;
  padding: 5px;
  border-radius: 5px;
  color: #ff6666;
  border: 1px solid #ff6666;
}

.smart-rep__notes__note-wrapper__abnormal__value--black {
  display: flex;
  justify-content: center;
  width: 40px;
  padding: 5px;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
}

.smart-rep__risk-summary {
  padding: 0.3rem 0px;
  padding-bottom: 0;
}

.smart-rep__risk-summary__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 2rem;
  padding: 1rem 0;
}
.smart-rep__risk-summary__wrapper__model {
  flex-basis: 45%;
  flex-grow: 1;
}

.smart-rep__risk-details__wrapper__model {
  padding-bottom: 21px;
}

.smart-rep__risk-details__wrapper__model > .risk-model {
  box-shadow: none;
  max-width: 100%;
  padding: 15px 0 0 0;
}

.smart-rep__pat__name {
  font-weight: bold;
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}
.smart-rep__pat__details {
  display: grid;
  width: 100%;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.smart-rep__pat__details__det {
  text-transform: capitalize;
}
.smart-rep__pat__details__det__label {
  color: #c9c9c9;
  margin-right: 0.3rem;
}
.smart-rep__cat__header {
  font-weight: 600;
  line-height: 1.15rem;
  margin-bottom: 0.3rem;
  font-size: 1.1rem;
}
.smart-rep__cat__text {
  font-weight: 400;
}
.smart-rep__cat__values-sec {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}
.smart-rep__cat__values-sec > div {
  flex: 49%;
  flex-basis: 49%;
}

.s-bio-sec {
  width: 100%;
  border: solid 1px #ececec;
  padding: 15px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.s-bio-sec__display {
  font-size: 1.1rem;
  line-height: 0.95rem;
  font-weight: 600;
}
.s-bio-sec__result {
  font-size: 0.95rem;
  line-height: 0.95rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.s-bio-sec__result__icon {
  height: 17px;
  width: 17px;
  margin-right: 4px;
}
.s-bio-sec__info {
  /* border: solid 0.06rem #efefef;
    */
  border-radius: 0.6rem;
  display: flex;
  break-inside: avoid;
  margin-top: 0.7rem;
}
.s-bio-sec__info--High {
  background: #fff3f3;
}
.s-bio-sec__info--Low {
  background: #fffef3;
}
.s-bio-sec__info__header {
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}
.s-bio-sec__info__sub-header {
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
}
.s-bio-sec__info__text {
  font-weight: 400;
}
.s-bio-sec__info__icon {
  height: 1.1rem;
  width: 1.2rem;
  object-fit: contain;
  margin-right: 0.55rem;
  object-fit: contain;
}
