.accordion-wrapper {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
  margin-bottom: 10px;

  .diagnostic-report__wrapper {
    box-shadow: none;
    padding: 0;
  }
  .prsd__vitals-graphs{
    box-shadow: none;
  }
  .hvl{
    box-shadow: none;
  }

  &__head {
    padding: 18px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;

    &__right {
      display: flex;
      gap: 20px;
    }

    &__caret {
      padding: 0 20px;
      border-radius: 19px;
      border: solid 1px #111;
    }

    &__status {
      text-transform: capitalize;
      padding: 0 10px;
      border-radius: 10px;
      border: solid 1px #111;
      display: flex;
      align-items: center;

      &__dot {
        height: 8px;
        width: 8px;
        border-radius: 8px;
        margin-right: 7px;
        background: #808080;

        &__hide {
          display: none;
        }
        &.completed {
          background: #1aa365;
        }
        &.generated {
          background: #1aa365;
        }
      }
    }
  }

  &__content {
    padding: 0 20px 20px 20px;
  }
}
