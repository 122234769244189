.page-wrapper {
    .ui.active.dropdown, .ui.active.dropdown .menu, .ui.selection.active.dropdown, .ui.selection.active.dropdown .menu  {
        border-color: #5076ff !important;
        border-top-width: 1px !important;
        border-radius: 10px !important;

        &:hover {
            border-color: #5076ff;
        }
    }

    .ui.floating.dropdown>.menu, .menu.transition.visible {
        border-radius: 10px !important;
    }

    .ui.dropdown .menu > .item, .ui.selection.dropdown .menu > .item {
        padding-left: 10px !important;
    }

    .results.transition.visible {
        border-color: #5076ff !important;
        border-top-width: 1px !important;
        border-radius: 10px !important;
        width: 100%;

        &:hover {
            border-color: #5076ff;
        }
    }

    .ui.search>.results .result:hover {
        border-radius: 10px !important;
    }

}

.ui.multiple.search.dropdown>.text {
    margin-left: 0 !important;
}

.ui.multiple.search.dropdown {
    .search {
        padding-left: 0 !important;
    }
}

.ui.search.dropdown .menu {
    @media only screen and (max-width: 767px){
        max-height: 15.5rem !important;
    }
}

.ui.visible.popup {
    border-radius: 10px !important;
    border: solid 1px #e3e3e3;
}