.public-doc-login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;

  &__card {
    width: 370px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 30px;
    padding-top: 36px;
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px rgba(201, 201, 201, 0.5411764705882353);

    @media only screen and (max-width: 768px) {
      width: 95%;
    }

    &__img {
      width: 80px;
      margin-bottom: 10px;
    }

    &__pin {
      background-color: rgb(248, 249, 250);
      border: 1px solid rgb(204, 204, 204);
      border-radius: 0.3rem;
      font-size: 20px;
      margin: 3px;
      height: 48px !important;
      width: 41px !important;
      outline: none;
      text-align: center;
      -webkit-appearance: none;

      @media only screen and (max-width: 768px) {
        margin: 3px;
        height: 45px !important;
        width: 27px !important;
      }

      &:focus {
        border-color: rgb(92, 83, 243);
        outline: none;
        transform: scale(1.05);
      }

      &--invalid {
        border-color: rgb(220, 53, 69);
      }

      // swd-pin-field[completed] & {
      //   border-color: rgb(40, 167, 69);
      //   background-color: rgba(40, 167, 69, 0.25);
      // }
    }

    &__info {
      text-align: center;
      margin: 28px 0;

      &__ex {
        margin-top: 7px;
      }
    }

    &__error-text {
      margin-top: 20px;
    }

    &__button {
      margin-top: 20px;
    }
  }
}
