.ph-certificate {
  width: 21cm;
  height: 29.7cm;
  background: white;
  box-shadow: 0px 0px 2px 0px #e5e5e5;
  border-radius: 10px !important;
  min-height: 100px;
  position: relative;
  // position: absolute;
  z-index: 0;
  top: 0;
  margin-bottom: 50px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
    position: relative;
  }

  &__pdf {
    width: 100%;
    height: 650px;
    margin-top: 40px;

    &__text {
      margin-bottom: 10px;
    }

    iframe {
      height: 100%;
      width: 100%;
      border-radius: 10px;
    }
  }

  &__content {
    padding: 3mm 7mm;
    z-index: 1;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      padding: 25px;
    }

    &__column-data {
      display: flex;
      flex-direction: column;
    }

    &__sig-line {
      width: 350px;
      border-bottom: solid 1px black;
    }

    &__revoked {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: 100% 20px;

      background: repeating-linear-gradient(
        -55deg,
        #fff0,
        #fff0 10px,
        #dadada2e 10px,
        #dadada2e 20px
      );

      div {
        width: 100%;
        height: 30px;
        background: red;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 16px;
        border-radius: 4px;
      }
    }

    &__qr-code {
      width: 90px;
      height: 90px;
      margin: auto;
      border-radius: 10px;

      @media only screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
        margin: auto;
        margin-top: 5px;
      }
    }

    &__header {
      &__text {
        font-size: 18px;
        font-family: 'IBM plex sans', sans-serif !important;
        color: black;
        line-height: 20px;
        font-weight: 300;
        font-weight: 400;
        text-decoration: underline;
      }
    }
    &__info-text {
      font-family: 'IBM plex sans', sans-serif !important;
      color: #1b1b1b;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      text-decoration: none;
    }

    &__header-wrapper {
      width: 100%;
      justify-content: space-between;
      display: flex;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 105px;
      flex-direction: row;
      align-items: center;

      &__header {
        position: relative;
        text-align: center;
        width: 518.55px;

        &__text {
          font-family: 'IBM plex sans', sans-serif !important;
          color: rgb(27, 27, 27);
          font-size: 24px;
          line-height: 27px;
          font-weight: 600;
          width: 100%;
        }
      }
    }

    &__top-line {
      border-top: solid 1px black;
    }

    &__section {
      position: relative;

      &--header {
        border: solid 2px black;
        margin-bottom: 3px;
        text-align: center;
      }

      &__header {
        position: absolute;
        background-color: white;
        padding: 0 5px;
        top: -11px;
        left: 15px;
        height: 15px;
      }
    }

    &__logo {
      width: 90px;
      height: 90px;
      object-fit: contain;

      @media only screen and (max-width: 768px) {
        max-width: 50px;
        max-height: 50px;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: solid 1px black;
      border-bottom: none;

      &--top {
        border-top: none;
      }

      &--mt {
        margin-top: 30px;
      }

      &--mb0 {
        margin-bottom: 0;
      }
    }

    &__location-row {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 768px) {
        margin-top: 0;
        flex-direction: column;
      }
    }

    &__data-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      border-right: solid 1px black;
      padding: 3px 5px;

      &:last-of-type {
        border-right: none;
      }

      &__info-wrapper {
        font-size: 12px;
        line-height: 13px;
        font-weight: 300;
        padding: 3px 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        border: solid 1px black;
        border-bottom: none;

        &--important {
          padding-top: 5px;
          padding-bottom: 5px;
          font-weight: bold;
        }
      }

      &__info-value {
        font-weight: 500;
        padding-left: 20px;
        display: flex;
        align-items: center;

        &--important {
          font-weight: bold;
        }
      }

      &__label {
        color: black;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
      }

      &__data {
        color: black;
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;

        &__signature-wrapper {
          display: flex;
          align-items: flex-end;
        }

        &__signature {
          object-fit: contain;
          max-width: 150px;
          max-height: 35px;
          margin-right: 15px;
        }

        &--small {
          width: 80%;

          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      &--half {
        width: 47%;
      }

      &--location {
        width: 47%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 5px;
    right: 28px;

    div {
      line-height: 10px !important;
      font-size: 10px !important;
    }
  }
}

.small-text {
  font-family: 'IBM plex sans', sans-serif;
  color: rgb(27, 27, 27);
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}

.text-focus {
  font-family: 'IBM plex sans', sans-serif !important;
  color: black;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  font-weight: 400;
  text-transform: capitalize;
}

.text-tiny {
  font-family: 'IBM plex sans', sans-serif !important;
  color: #1b1b1b;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
}

.text-tinniest {
  font-family: 'IBM plex sans', sans-serif !important;
  color: black;
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}

.bold {
  font-weight: 400;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: capitalize;
}

.text-micro {
  font-family: 'IBM plex sans', sans-serif !important;
  color: black;
  font-size: 9px;
  line-height: 12px;
  font-weight: 300;
}
