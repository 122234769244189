.rf-succ-p {
    background: rgba(245, 245, 245, 0.296);
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 100vh;
    width: 100%;

    
    &__info {
        display: inline-block;
        background: white;
        border-radius: 10px;
        border: solid 1px grey;
        padding: 25px;
        width: 600px;
        text-align: center;
        margin: 10px;
        margin-top: 25vh;
    }
}