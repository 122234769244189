.fft-cov {
  display: flex;
  flex-direction: column;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  font-size: 15px;
  line-height: 30px;
  z-index: 0;
  width: 21cm;
  height: 29.7cm;
  box-shadow: 0px 0px 2px 0px #9e9e9e;
  border-radius: 10px !important;
  background: white;
  font-family: 'IBM plex sans', sans-serif;
  padding: 11mm 20mm;

  &__small-bold {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    font-family: 'IBM plex sans', sans-serif !important;
  }

  &__info-text {
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: 300 !important;
    font-family: 'IBM plex sans', sans-serif !important;
  }

  &__signer {
    &__img {
      object-fit: contain;
      object-position: left;
      height: 90px;
      width: 200px;
      border-bottom: solid 1px black;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  }

  &__logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-bottom: 20px;

    &__logo {
      max-width: 40%;
      max-height: 90px;
      object-fit: contain;
    }
  }

  &__qr-code {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    position: absolute;
    top: 10mm;
    right: 11mm;

    @media only screen and (max-width: 768px) {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
  }
}
