@import '../../../../config';

.u-patient-form {
    overflow: auto;
    position: relative;
    margin: auto;
    padding: 0;

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
    
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3px;
    }

    &__patient-photo-wrapper {
        width: 120px;
        display: flex;
        align-items: center;
        button, input {
            width: 150px !important;
            min-width: 150px !important;
        }
    }

    &__info {
        &--mobile {
            position: absolute;
            top: 30px;
            right: 20px;
        }
    }

    &__patient-photo {
        border: solid 1px #dbdbdb;
        background: #f1f1f1;
        border-radius: 100px;
        height: 100px;
        min-height: 100px;
        width: 100px;
        min-width: 100px;
        margin-right: 10px;
    
        &--error {
          border: dashed 1px #ff5252;
        }
    
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 100px;
        }
    }

    &__id-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 25px;
    }

    &__form {
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 20px 45px;
        padding-top: 0;
        padding-bottom: 35px;
        max-width: 1400px;
        margin: auto;

        @include mobileView {
            padding: 0;
        }

        &__grid-wrapper {
            margin-top: 25px;

            &:first-of-type {
                margin-top: 0px;
            }
        }

        &__grid {
            border-radius: 10px;
            border: solid 1px rgba(239, 239, 239, 1);
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(4, 1fr);
            margin-top: 10px;
            background: white;
            border-radius: 10px;
            padding: 15px;
            padding-bottom: 20px;

            @include mobileView {
                grid-template-columns: repeat(1, 1fr);
            }
        }


        &__wrapper {
            border: solid 1px rgba(228, 228, 228, 0.7);
            background: white;
            border-radius: 10px;
            padding: 15px;
            border-radius: 10px;
            margin-top: 10px;
            display: flex;

            @include mobileView {
                align-items: center;
            }
        }

        &__input {
            &--new-line {
                grid-column: 1;
            }
        }
    } 
    &__button {
        @include mobileView {
            width: 100% !important;
        }
    }
}

.patient-share-modal {
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    
    &__qrCode-wrapper {
        width: 172px;
    }

    &__email-wrapper {
        width: 350px;
        border-left: solid 1px #dddddd;
        padding-left: 50px;
        margin-left: 50px;
    }

    &__qrCode {
        width: 172px;
        height: 172px;
        border-radius: 10px;
        border: solid 1px black;
        padding: 10px;
    }

    &__multi-email-input {
        font-family: 'IBM plex sans', sans-serif !important;
        align-self: center !important;
        font-size: 14px !important;
        border: solid 1px #bdbdbd !important;
        border-radius: 10px !important;
        display: inline-flex !important;
        width: 100%;
    
        &.react-multi-email {
          &.focused {
            border-color: #5076ff !important;
          }
        }
    
        input {
          font-weight: 300;
          font-size: 18px !important;
        }
      }
    
      &__multi-email-input-wrapper {
        margin: 10px 0;
        display: inline-block;
      }
}
