.cr-req-f {
    border-radius: 15px;
    background: white;
    padding: 20px;
    width: 550px;
    min-width: 550px;
    height: fit-content;

    &__table-w {}

    &__table, .ui.table {
        border: none !important;

        th, td {
            border: none !important;
        }

        th {
            padding-bottom: 0px;
            height: 15px;
        }
    }

    &__row {
        &__icon {
            cursor: pointer;
        }
    }

    &__a-button {
        &__icon {
            height: 19px !important;
        }
    }

    &__remarks {
        margin-top: 25px;
        margin-bottom: 10px;
    }
}