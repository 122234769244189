@import '../../../../config';

.emer-c-o {
    padding: 0 45px;

    &__terms-wrapper {
        margin-top: 10px;
        background: white;
        border-radius: 10px;
        padding: 15px;
        padding-bottom: 20px;
        border: solid 1px #efefef;
        display: flex;
        align-items: center;

        a {
            color: #4183c4 !important;
        }
    }

    @include mobileView {
        padding: 0;
    }

    &__form {
        margin-top: 30px;
    }

    &__grid {
        gap: 0;
    }

    &__data-wrapper {
        padding: 10px 0;
    
        @include mobileView {
            border-bottom: solid 1px #efefef;

            &:first-of-type {
                padding-top: 0;
            }
            &:last-of-type {
                padding-bottom: 0;
                border-bottom: none;
            }
        }


        &__label {
            margin-bottom: 5px;
        }
    }
}