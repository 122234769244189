.custom-modal-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(33, 33, 33);
  opacity: 0.46;
  z-index: 10;
}

.custom-modal {
  position: relative;
  border-radius: 8px;
  background: white;
  z-index: 11;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 25px 24px;
  // max-width: 350px;
  max-height: 90%;

  &__header {
    line-height: 2rem;
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 5px;
  }

  &__text {
    margin: 20px 0;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0.0071428571em;
    color: rgba(0, 0, 0, 0.6);
  }

  &__button {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
