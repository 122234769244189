.account-success {
    &__wrapper {
      width: 96vw;
      background-color: 'white';
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    &__content {
      background-color: 'white';
      width: 20vw;
      padding: 20px;
      margin-left: 2%;
      margin-top: 15%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      box-shadow: 0px 0px 20px 0px rgba(184, 184, 184, 0.25);
    }
  }