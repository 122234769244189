.pcr-cert {
  &__row {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;

    & > div {
      width: 33%;
    }

    &--between {
      justify-content: space-between;

      & > div {
        width: initial;
        min-width: 33%;
      }
    }

    &--4 {
      & > div {
        width: 25%;
      }
    }
  }

  &__data {
    text-align: center;

    &--long {
      width: 66% !important;
    }
  }

  &__section {
    padding: 10px;
    border: solid 1px #dcdcdc;
    border-radius: 15px;
    position: relative;
    margin: 10px 0;
    display: flex;
    flex-direction: column;

    &__header {
      position: absolute;
      background-color: white;
      padding: 0 5px;
      top: -11px;
      left: 15px;
      height: 15px;
    }
  }
}
