@import '../../../../config';
.bmi-page {
    max-width: 400px;
    
    &__body { 
        padding: 0;
        margin-top: 15px;
        &__list-wrapper {
        }

        &__line-graph-text {
            display: flex;
            flex-direction: column;
            margin-right: 14px;
            justify-content: center;
            white-space:nowrap;
            width: 72px;
            min-width: 72px;
            margin-bottom: 5px;
        }

        &__list {
            position: relative;

            &__loader-wrapper {
                padding: 50px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            &__item {
                margin-top: 10px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            &__filter {
                padding-bottom: 12px;
            }
            &__row {
                // padding: 12px 15px;
                border-bottom: solid 1px #EFEFEF;
                display: flex;
                flex-direction: column;

                // &:first-of-type {
                //     border-top: solid 1px #EFEFEF;
                // }
                &:last-of-type {
                    border-bottom: none;
                }

                &__data {
                    display: flex;

                    &__label {
                        width: 80px;
                    }
                }
            }

            &__load-more {
                margin-top: 20px;
                margin-bottom: 30px;

                @include mobileView {                        
                    margin-bottom: 60px;
                }
            }

            &__icon {
                height: 60px;
                width: 60px;
                background: black;
                border-radius: 40px;
                margin-bottom: 10px;
            }

            &__load {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
            }
        }
    }
}