@import '../../../../config';

.appointment-details {
  box-shadow: 0px 0px 10px 0px rgba(201, 201, 201, 0.5411764705882353);
  background: white;
  padding: 45px 60px;
  max-width: 1200px;
  min-width: 800px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  position: relative;
  border-radius: 10px;

  @include mobileView {
    padding: 25px 15px;
    min-width: 100%;
  }

  &__peme-preview {
    margin-bottom: 0 !important;
  }

  &__patient-photo-wrapper {
    margin: 25px;
    margin-top: 15px;
    width: 250px;
  }

  &__patient-photo {
    border-radius: 10px;
    border: dashed 1px #1b1b1b;
    height: 250px;
    width: 250px;
    margin-bottom: 15px;

    &--error {
      border: dashed 1px #ff5252;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  &__patient-signature {
    border-radius: 10px;
    border: dashed 1px #1b1b1b;
    height: 100px;
    width: 250px;
    margin-bottom: 15px;

    &--error {
      border: dashed 1px #ff5252;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  &__loader-wrapper {
    display: flex;
    justify-content: center;

    .ui.inverted.loader {
      position: absolute;
      left: 70px !important;
    }
  }

  &__cancel-button {
    position: absolute;
    right: 0;
    top: -77px;
  }

  &__modal.ui.fullscreen.modal {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
  }

  &__small {
    .appointment-details__modal-content__input {
      div:first-of-type,
      input:first-of-type {
        width: 285px !important;
      }

      &--wide {
        width: initial;
        max-width: initial;
        min-width: initial;

        div:first-of-type,
        input:first-of-type {
          width: 100% !important;
        }
      }
    }
  }

  &__modal-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    &__free-text {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }

    &__inputs-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
      row-gap: 0px;

      &--info {
        padding: 10px;
        border: solid 1px #cdcdcd;
        border-radius: 10px;
        margin: 10px 0;

        &__loader {
          position: relative;
          height: 90px;
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
    }

    &__input {
      margin: 10px 0;
      flex: 1;
      position: relative;

      &--checkbox {
        border: solid 1px #dfdfdf;
        border-radius: 10px;
        padding: 15px;
      }

      @media only screen and (max-width: 900px) {
        width: 48%;
        max-width: 48%;
        min-width: 48%;
      }

      @include mobileView {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
      }

      .select-document {
        @include mobileView {
          width: 87vw !important;
        }
      }

      &--row {
        display: flex;
        align-items: flex-end;
      }

      &--wide {
        width: initial;
        max-width: initial;
        min-width: initial;

        div,
        input {
          width: 100% !important;
        }
      }
    }

    &__switch {
      margin-top: 15px;
    }

    &__checkbox {
      width: 100%;
      margin: 10px 0;
    }

    &__header {
      font-family: 'IBM plex sans', sans-serif;
      color: #1b1b1b;
      font-size: 22px;
      line-height: 27px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 10px;
      border-bottom: solid 1px #eaeaea;
    }

    &__sub-header {
      margin-top: 10px;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: center;

      &__content {
        padding: 15px 30px;
        border-radius: 10px;

        &:first-of-type {
          margin-right: 20px;
        }
      }
    }

    &__upload {
      width: 300px;
      height: 50px;
      margin: auto;
    }

    &__file-upload {
      width: 300px;
      height: 50px;
      margin: 10px 0;
      margin-right: 15px;
    }

    &__file {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      height: 20px;
      overflow: hidden;
    }

    &__file-name {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 37px;
      overflow: hidden;
      color: #464646 !important;
      font-size: 15px !important;
      line-height: 17px !important;
    }

    &__file-row {
      display: flex;
      align-items: center;
    }

    &__text {
      text-align: center;
      margin-top: 80px;
      margin-bottom: 15px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 25px;
      @include mobileView {
        flex-direction: column;
      }
      button {
        @include mobileView {
          width: 87vw !important;
        }
      }
      button:first-of-type {
        margin-right: 15px;
        @include mobileView {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__checkbox {
    height: 25px !important;
    display: flex !important;
    align-items: center !important;

    div {
      width: 250px;
      font-size: 18px !important;
      margin-bottom: 0 !important;
    }
  }

  &__loader-input {
    position: relative;

    .linear-activity {
      position: absolute;
      width: 279px;
      left: 3px;
      bottom: -3px;
      border-radius: 0 0 10px 10px;
    }

    &__loader-text {
      position: absolute;
      bottom: -16px;
      left: 3px;
      color: #525252 !important;
    }
  }

  &__back {
    position: absolute;
    top: 5px;
    left: 0;
    font-size: 17px;
  }

  &__edit {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__close {
    border: solid 1px #5076ff;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 20px 0px rgba(201, 201, 201, 0.4);
    margin: auto;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    max-width: 1200px;
    min-width: 800px;
    width: 100%;

    &--error {
      border: solid 2px #ff1f1f;
      padding: 20px 50px;
      box-shadow: 0px 0px 20px 0px rgba(128, 24, 24, 0.4);

      & > div {
        margin: 15px;
      }
    }

    &--green {
      border: solid 1px #45cb45d9;
    }

    &__text {
      display: flex;
      align-items: center;

      & > svg {
        margin-right: 5px;
      }
    }

    &__button {
      margin-top: 15px;
    }

    &__table {
      width: 100%;
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &__image {
      height: 150px;
      object-fit: contain;
      margin-right: 20px;
      border-radius: 6px;
    }

    &__titles {
      // &__title {
      //   margin-bottom: 15px;
      // }

      &__subTitle {
        margin-bottom: 5px;
      }
    }
  }

  &__grid {
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: solid 1px #f5f5f5;
    border-bottom: solid 1px #f5f5f5;
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;

    &--first {
      padding-bottom: 0;
      border-bottom: none;
    }

    @media (max-width: 550px) {
      & {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__data {
    &__label {
      margin-bottom: 10px;
      margin-right: 10px;
    }

    &__text-area {
      margin-top: 30px;
    }

    &__data {
      display: flex;
      flex-direction: row;

      &__multi {
        border: solid 1px #cdcdcd;
        border-radius: 6px;
        padding: 10px;
        margin-right: 10px;
      }
    }
  }

  &__instructors {
    margin-top: 30px;
    display: flex;
    flex-direction: row;

    &__label {
      margin-bottom: 15px;
    }

    &__card {
      width: 300px;
      margin-right: 50px;
    }
  }

  &__map {
    width: 100%;
  }

  &__delete-icon {
    height: 22px;
    width: 22px;
    padding-right: 2px;
  }
}

#course-overview-details__map {
  margin-top: 15px;
  border-radius: 6px;
}

.email {
  @include mobileView {
    width: 300px;
  }
}
