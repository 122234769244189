@import '../../../config';

.pat-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    border-radius: 10px;

    @include mobileView {
        flex-direction: column;
        background: white;
        padding: 15px;
        padding-bottom: 0;
        border: 1px solid #EFEFEF;
    }

    &__displays {
        border: solid 1px rgb(207, 207, 207);
        padding: 4px 8px;
        border-radius: 6px;
        margin-right: 8px;
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        align-items: center;
        margin-left: 10px;
        border: solid 1px #e1e1e1;
    }

    &__display {
        display: flex;
        align-items: center;

        &__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    }

    &__res-details-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        &__rows {}
    }

    &__status {
        display: flex;
        align-items: center;

        &__dot {
            height: 8px;
            width: 8px;
            border-radius: 10px;
            margin-right: 6px;
        
            &--green {
            background: #5bc592;
            }
            &--red {
            background: red;
            }
            &--orange {
            background: #ff9b71;
            }
        }
    }

    &__detail {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-right: 10px;

        @include mobileView {
            margin-bottom: 20px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        &__header {
            margin-bottom: 0px;
        }
    }

    &__loader-wrapper {
        min-width: 100%;
        min-height: 100%;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__loader {
            position: relative !important;
            top: initial !important;
            left: initial !important;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__intro {
        max-width: 375px;
        // border: solid 1px #efefef;
        border-right: solid 1px #efefef;
        margin-right: 30px;
        cursor: pointer;
        padding: 15px;
        padding-right: 25px ;
        padding-left: 0;

        @include mobileView {
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0;
            padding: 10px;
        }

        & > div:last-of-type {
            margin-bottom: 2px !important;
        }

        &:hover {
            .pat-details__intro__name {
                color: #5076ff !important;
            }
        }

        &__age {
            display: flex;
            align-items: center;
        }
    }

    &__res-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1;

        @include mobileView {
            justify-content: space-between;
        }
    }
}