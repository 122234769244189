.course-item {
  position: relative;
  margin: 8px 0;
  width: 100%;
  max-width: 1200px;

  &--selectable {
    cursor: pointer;
  }

  &__image {
    position: relative;
    height: 160px;
    min-height: 160px;
    width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }

    & > div {
      padding-left: 10px;
      padding-right: 10px;
      z-index: 1;
      min-height: 45px;
      position: absolute;
      bottom: 10px;
    }

    &__overlay {
      height: 80px;
      width: 100%;
      padding: 0;
      z-index: 0 !important;
      bottom: 0 !important;
      border-radius: 0 0 20px 20px;
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 1) 11%,
        rgba(0, 212, 255, 0) 100%
      );
    }
  }

  &__data {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 10px;
    padding-left: 5px;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: 10px;

    &__top {
      border-bottom: solid 1px #e6e4e4;
      height: 85px;
      display: flex;
      flex-direction: row;
      padding-top: 18px;
      justify-content: space-between;
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      height: 60px;
      justify-content: space-between;
    }

    &__description {
      height: 63px;
      overflow: hidden;

      .text {
        line-height: 20px;
      }
    }

    &__info {
      flex-basis: 0;
      flex: 1 1 0px;

      &--selectable {
        cursor: pointer;
      }

      &--center {
        text-align: center;
      }

      &__multi-wrapper {
        display: flex;
      }

      &__multi {
        padding-right: 5px;
      }

      &__header {
        margin-bottom: 8px;
      }
    }

    &__icon {
      position: absolute;
      right: 35px;
      top: 31px;
    }
  }

  &__content {
    padding: 15px;
    width: 100%;
    flex-direction: row;
    display: flex;

    &__header {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      height: 30px;
      align-items: center;
      margin-top: 5px;

      & > div {
        &:first-of-type {
          // max-width: 430px;
          width: calc(100% - 170px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__dropdown {
      position: absolute;
      right: 30px;
      top: 30px;
    }

    &__dropdown-icon {
      height: 21px;
      width: 23px;
    }
  }

  &__actions-wrapper {
    display: flex;

    &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      & > * {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      &__option {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > svg {
          margin-right: 12px;
        }
      }
    }
  }
  &__flag {
    height: 20px;
    width: 35px;
  }
}
