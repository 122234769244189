.lab-report-pdf {
  &__loader-segment {
    width: 100%;
    height: 100%;
  }

  &__generate-pdf {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__toolbar{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    max-width: 892px;
  }
}

// .react-pdf {
//   &__Document {
//       min-height: 400px;
//       display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;
//   }
//   &__Page {
//     width: fit-content;
//     display: flex;
//     justify-content: center;
//     padding: 1px;
//     border-radius: 5px;
//     border: 1px solid rgba(0, 0, 0, 0.1);
//   }
// }
