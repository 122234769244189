.artm {
  &__header {
    border-radius: 10px !important;
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 10px !important;
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__file {
    text-align: start;
    width: fit-content;
    border: 1px solid #b2b2b2;
    padding: 7px;
    border-radius: 7px;
  }

  &__text-wrapper {
    margin-top: 10px;
  }
  &__button-wrapper {
    margin-top: 10px;
  }
}
