.add-reps {
  padding-top: 10px;
  padding-bottom: 10px;

  &__text-row {
    display: flex;
    margin-bottom: 5px;
  }

  &__button {
    padding-left: 25px;
    padding-right: 25px;
  }

  &__loader {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}
