.lang-s {
    border-radius: 10px !important;
    border: 1px solid rgb(221, 221, 221);
    background: rgba(221, 221, 221, 0.1);
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 60px;

    .ui.dropdown .menu>.item>.image, .ui.dropdown .menu>.item>img, .ui.dropdown>.text>.image, .ui.dropdown>.text>img {
        height: 20px !important;
        width: 20px !important;
        min-width: 20px !important;
        margin: auto !important;
    }
    
    .ui.dropdown>.text, .page-wrapper .ui.dropdown .menu > .item {
        display: flex !important;
        align-items: center;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    
    .ui.active.dropdown, .ui.inline.dropdown>.text {
        height: 30px !important;
        width: 30px !important;
        padding: 0 !important;
    }
        .center.visible.menu.transition {
            margin-left: -14px !important;
        }
}