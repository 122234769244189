.cr-req-o {
    flex: 1;
    &__date-tag {
        position: absolute;
        top: -16px;
    }

    &__table {

    }
}