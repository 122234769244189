.map {
  width: 620px;
  height: 300px;
  border-radius: 2px !important;

  & > div {
    height: 100%;
    width: 100%;
    border-radius: 2px !important;
  }
}
