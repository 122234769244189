.web-cam {
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;

  video {
    border-radius: 10px;
    margin-bottom: 20px;
  }
}
