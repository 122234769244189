.breadCrumbTrail {
    &__wrapper {
        display: flex;
        gap: 5px;
    }

    &__step {
        height: 4px;
        border-radius: 60px;
        flex: 1;
    }

    &__active {
        background: #5076FF;
    }

    &__inactive {
        background: #D9D9D9;
    }
}