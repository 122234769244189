@import '../../../config';

.login {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #184c93;

  &__types-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  &__type-wrapper {
    position: relative;
    cursor: pointer;
    border: solid 1px #dddcdc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 112px;
    width: 90px;

    &:first-of-type {
      margin-right: 20px;
    }

    &--selected {
      background: #5076ff;
      border: solid 1px #5076ff;
    }

    &__icon {
      height: 24px;
      width: 24px;
      margin-top: 8px;
    }

    &__check {
      position: absolute;
      bottom: -10px;
      right: -10px;
    }
  }

  &__bckg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--mini {
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(2px);
      transition: opacity ease-in 1000ms;
    }
  }

  &__card {
    background: white;
    max-width: 405px;
    min-width: 405px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 0px 12px 0px rgba(80, 80, 80, 0.5);
    margin-bottom: 50px;

    @include mobileView {
      height: 100%;
      width: 100%;
      min-width: initial;
      max-width: initial;
      margin-bottom: 0;
      border-radius: 0;
      justify-content: center;
    }

    &__content {
      position: relative;
      padding: 0 30px;
      width: 100%;
      height: 100%;
      margin: auto;
      margin-top: 20px;

      @include mobileView {
        height: initial;
        margin: initial;
        margin-top: 20px;
      }
    }

    &__logo {
      width: 60%;
      object-fit: contain;
      max-height: 100px;
      margin-top: 40px;
      margin-bottom: 20px;

      @include mobileView {
        width: 70%;
      }
    }

    &__header {
      flex-wrap: wrap;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 0.0125em;
      line-height: 30px;
      word-break: break-all;
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
    }

    &__input {
      margin-top: 15px;
    }

    &__button {
      margin-top: 50px;
      width: 100% !important;
    }

    &__forgot-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &__forgot {
      border: none;
      height: 16px;
      margin-top: 10px;
      margin-bottom: 30px;
      width: initial !important;
      padding: 0 !important;

      &__text {
        font-size: 13px;
        font-weight: 400;
      }

      :hover {
        .login__card__forgot__text {
          color: #85b7d9;
        }
      }
    }
  }
}

.login-modal {
  width: 350px;
}
