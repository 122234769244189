.pat-med-c {
    &__form-wrapper {
        &__row {
            margin: 12px 0;

            &:first-of-type {
                margin-top: 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}