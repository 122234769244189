.rho {
    &__assign-butt {
        border-radius: 10px !important;
        width: 100%;
        min-width: 150px;
        max-width: 250px;
        margin-right: 15px !important;
        background: none !important;
        border: solid 1px rgb(209, 209, 209) !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
    
        .hidden.content {
          margin-top: -0.7em !important;
        }
    
        &--has-staff {
          background: none !important;
          border: solid 1px transparent !important;
          opacity: 1 !important;
    
          &:hover {
            background: rgb(241, 241, 241) !important;
          }
        }
    }

    &__search {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }

        &__date-tag {
            position: absolute;
            top: -16px;
        }

        &__section {
            display: flex;

            @media only screen and (max-width: 600px) {
                flex-direction: column;
                align-items: flex-end;
                width: 100%;

                &:first-of-type {
                    margin-bottom: 15px;
                }

                & > div {
                    margin-top: 15px;
                }
            }
        }
    }

    &__custom-dropdown {
        position: relative;
        margin-right: 10px;
        &__portal {
            display: none;
            position: absolute;
            left: 0;
            top: 50px;
            background: white;
            border-radius: 10px;
            padding: 15px;
            border: solid 1px #2b2b2b;
            box-shadow: 2px 2px 12px 0px #cdcdcd;
            z-index: 9;

            &--open {
                display: initial;
            }
        }
    }

    &__table-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    &__dot {
        height: 8px;
        width: 8px;
        border-radius: 10px;
        margin-right: 8px;
        background: #808080;

        &--completed {
            background: rgba(34, 211, 143, 1);
        }
        &--in-progress {
            background: rgba(255, 175, 62, 1);
        }
        &--cancelled {
            background: rgba(255, 102, 102, 1);
        }
    }

    &__session-type {
        margin-right: 10px;
        position: relative;

        &__label {
            position: absolute;
            top: -18px;
        }

        button {
            border-radius: 0 10px 10px 0 !important;
        }

        button:first-of-type {
            border-radius: 10px 0 0 10px !important;
        }
    }

    &__dropdown-icon {
        height: 15px;
        width: 15px;
    }
}

.rhce-w {
    width: 100vw;
    height: 100vh;
    background: rgb(247, 247, 247);
    position: fixed;
    overflow: auto;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}

.rhce {
    margin: auto;
    padding: 50px 30px;
    max-width: 1400px;

    &__loader-w {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 150px;
    }

    &__promotion {
        &__text {
            max-width: 1200px;
            margin: auto;
            padding: 25px 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            border: solid 1px #d2d2d2;
            border-radius: 15px;
            box-shadow:  10px 10px 20px #bebebea5,
                         -10px -10px 20px #ffffff;
        }
    }
}