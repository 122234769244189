.react-date-picker__calendar {
    z-index: 2;
}

.date-picker {
    &__input-error-text {
        position: absolute;
        bottom: -18px;
        left: 0;
        width: 126px;
        user-select: none;
        width: 100%;
    }
}