@import '../../../../config';

.webshop-promo {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 10px 30px;
  padding-bottom: 40px;

  &__head {
    width: 80%;
    margin-bottom: 2rem;
  }
  &__body {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    margin-bottom: 20px;

    @include mobileView {
      flex-direction: column;
    }

    &__column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-basis: 100%;
      position: relative;
    }

    &__card {
      //default
      background-image: linear-gradient(25deg, #d3e7ff, #f2f8fe 92%);
      border: 1px solid #dcebf9;
      border-radius: 1.25rem;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      gap: 20px;
      flex-basis: 100%;
      position: relative;
      overflow: hidden;

      //customizations
      &.automated-workflow {
        flex-direction: column-reverse;
      }
      &.apis {
        justify-content: unset;
        gap: unset;
      }
      &.seo {
        justify-content: unset;
        gap: unset;
      }
      &.referral {
        justify-content: unset;
        gap: unset;
      }

      &__content {
        //default
        padding: 2rem;

        //customizations
        &.apis {
          max-width: 320px;
        }

        &.seo {
          max-width: 320px;
        }
      }

      &__images-container {
        //default
        position: relative;
        display: flex;
        justify-content: flex-end;
        height: 100%;

        //customizations
        &.referral {
          justify-content: unset;
          width: 100%;
        }
      }
      &__image {
        //default
        max-width: 100%;
        &.primary {
          z-index: 2;
        }
        &.secondary {
          z-index: 1;
          mix-blend-mode: multiply;
          position: absolute;
        }

        //customizations
        &.appointment-types {
          &.primary {
            z-index: 2;
            max-width: 100%;
          }
          &.secondary {
            z-index: 1;
            mix-blend-mode: multiply;
            position: absolute;
          }
        }

        &.automated-workflow {
          &.primary {
            z-index: 2;
            margin-top: 80px;
            margin-bottom: 40px;
          }
          &.secondary {
            z-index: 1;
            mix-blend-mode: multiply;
            position: absolute;
          }
        }

        &.multiple-locations {
          &.primary {
            z-index: 2;
            max-width: 88%;
            margin-top: 120px;
            margin-bottom: 40px;
          }
          &.secondary {
            z-index: 1;
            mix-blend-mode: multiply;
            position: absolute;
            bottom: 0px;
          }
        }

        &.apis {
          &.primary {
            z-index: 2;
            max-width: 60%;
            bottom: 0;
            right: 0;
          }
          &.secondary {
            z-index: 1;
            mix-blend-mode: multiply;
            position: absolute;
            bottom: 0px;
          }
        }

        &.seo {
          &.primary {
            z-index: 2;
            max-width: 60%;
            bottom: 0;
            right: 0;
          }
          &.secondary {
            z-index: 1;
            mix-blend-mode: multiply;
            position: absolute;
            bottom: 0px;
          }
        }

        &.referral {
          &.primary {
            z-index: 2;
            height: fit-content;
            position: absolute;
            width: 100%;
            bottom: 138px;
          }
          &.secondary {
            z-index: 1;
            mix-blend-mode: multiply;
            margin-left: 20px;
            position: relative;
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    gap: 3rem;
    @include mobileView {
      justify-content: flex-start;
    }
    &__item {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      @include mobileView {
        justify-content: flex-start;
      }
    }
  }
}
