.lrtf {
    gap: 8px;
    height: 45px;
    width: fit-content;
    display: flex;
    background: #f1f2f4;
    border: solid 1px #f6f7f8;
    border-radius: 10px;
    padding: 1px;
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 25px;
    overflow: auto;
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }


    &__item-active {
        display: flex;
        align-items: center;
        border-radius: 7px;
        padding: 10px 20px;
        width: fit-content;
        font-size: 15px;
        line-height: 19px;
        font-weight: 500;
        text-transform: capitalize;
        background: #fbfbfb;
        border: solid 1px #a5a5ff;
        color: #3862ff;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        //   color: #5076ff;
        background: none;
        border-radius: 11px;
        padding: 16px 24px;
        width: fit-content;
        text-transform: capitalize;
        white-space: nowrap;
        
        &:hover {
            cursor: pointer;
        }

        &__counter {
            margin-left: 7px;
            padding: 1px 4px;
            border-radius: 5px;
            color: black;
            font-weight: 500;
            border: solid #bdbdbd 1px;
            background: #fafafa;
        }
    }

    &__loader {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }
&__scroll-arrow {
    position: absolute;
    top: 32%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    background: #fbf9f9;
    border: 1px solid #e7e6e6;
    padding: 10px;
    border-radius: 50%;
    user-select: none;
}

&__arrow-left {
    left: 0;
}

&__arrow-right {
    right: 0;
}

&__wrapper {
    position: relative;
}
}