.Toastify {
  width: 50%;
}

.Toastify__toast-container {
  min-width: 320px;
  width: initial;
  border-radius: 10px !important;
  width: 50%;
  max-width: 600px;
  min-width: 350px;
}

.Toastify__close-button {
  opacity: 1;
  margin: auto;
}

.Toastify__toast {
  width: 100%;
  border-radius: 10px !important;
}

.Toastify__toast-body {
  padding-right: 15px;
}
