/* Container for the custom menu */
.custom-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: #f1f1f1;
    // padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    height: 50px;
    border-radius: 5px;
}


.menu-item {
    padding: 10px 20px;
    // margin-right: 10px;
    cursor: pointer;
    font-weight: 500;
    color: #333;
    border-bottom: 1px solid transparent;

    transition: border-bottom 0.3s ease;
}

/* Hover effect for menu items */
.menu-item:hover {
    color: #007bff;
    border-bottom: 1px solid #007bff;
    /* Underline on hover */
}

/* Active state styling */
.menu-item.active {
    color: #007bff;
    border-bottom: none !important;
    background-color: white;
    height: inherit;
    display: flex;
    align-items: center;
    border: 1px solid #d4d4d5;
    border-radius: .28571429rem .28571429rem 0 0 !important;
}

.custom-menu.active {
    border-bottom: none !important;
    /* Remove border-bottom for active menu */
}

.custom-menu.active .menu-item.active {
    border-bottom: none !important;
    /* Remove border-bottom from the active menu item */
}

/* Handle overflow behavior with scrollbar */
.custom-menu::-webkit-scrollbar {
    height: 0px;
}

.custom-menu:has(> .menu-item:last-child)::-webkit-scrollbar {
    height: 2px;
    /* Show thin scrollbar only when needed */
}

.custom-menu::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.custom-menu::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Responsive menu items */
@media (max-width: 768px) {
    .menu-item {
        padding: 8px 16px;
        font-size: 14px;
    }
}