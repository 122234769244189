.image-preview {
  border-radius: 4px;
  border: 1px solid #bdbdbd;

  &__modal {
    margin: auto;
    padding: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    &-content {
      margin: auto;
      padding: 1%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      text-align: center;
      background-color: #000000dd;
    }
  }

  &__slide {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__image {
    width: 50%;
    height: 100%;
    margin: auto;

    div {
      height: 100%;
    }
  }

  &__close {
    color: white;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 40px;
    font-weight: bold;
    opacity: 0.2;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
